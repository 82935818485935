import {createAction, props} from '@ngrx/store';

enum TrendingStock {
  NEXT_PAGE = '[TrendingStock] Next Page',
  NEXT_PAGE_SUCCESS = '[TrendingStock] Next Page Success',
  NEXT_PAGE_FAILURE = '[TrendingStock] Next Page Failure',
  PREV_PAGE = '[TrendingStock] Prev Page',
}

export const nextPageTrendingStockAction = createAction(
  TrendingStock.NEXT_PAGE,
  props<{ offset: number, isCanLoad: boolean }>()
);

export const nextPageTrendingStockSuccessAction = createAction(
  TrendingStock.NEXT_PAGE_SUCCESS,
);

export const nextPageTrendingStockFailureAction = createAction(
  TrendingStock.NEXT_PAGE_FAILURE,
);

export const prevTrendingStockAction = createAction(
  TrendingStock.PREV_PAGE,
  props<{ offset: number, isCanLoad: boolean }>()
);
