import {Injectable} from '@angular/core';

import {combineLatest, fromEvent, Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';

import {select, Store} from '@ngrx/store';

import {ProfileApiService} from '@shared/services/profile/api/profile-api.service';
import {ProfileForComponent} from '@shared/models/profile/profile-for-component.interface';
import {UserService} from '@shared/services/user/user.service';
import {AvatarSize} from '@shared/enums/user/avatar-size.enum';
import {EnvironmentService} from '@core/services/enviroment.service';
import {
  getAccountHolder,
  getAccountHolderFailure,
  getAccountHolderSuccess,
  updateProfileIdForOtherUserByTypeBrokerage,
  updateProfileInfoSuccess
} from '@store/actions/profile/profile.actions';
import {
  accountHolderSelector,
  profileActiveTabSelector,
  profileInfoSelector
} from '@store/selectors/profile/profile.selectors';
import {ProfileNav} from '../../../modules/profile/shares/enums/profile-nav.enum';
import {UserInfo} from '@shared/models/user/user.info.interface';
import {userInfoSelector} from '@store/selectors/user.selector';
import {ProfileViewModel} from '@shared/interfaces/profile/profile-view-model.interface';
import {countriesSelector} from '@store/selectors/countries/countries.selector';
import {Country} from '@shared/models/countries/country.interface';
import {ProfileAccountHolder} from '@shared/models/profile/profile-account-holder.interface';
import {AccountHolderInfoForServer} from '@shared/models/profile/account-holder-info-for-server.interface';

@Injectable({providedIn: 'root'})
export class ProfileService {

  constructor(private profileApiService: ProfileApiService,
              private userService: UserService,
              private environmentService: EnvironmentService,
              private store: Store) {
  }

  getProfileInfo(userId: number): Observable<ProfileForComponent> {
    return this.profileApiService.getProfileInfo(userId).pipe(
      map(profile => {
        const avatarUrl = this.getAvatarUrl(profile.id, profile.hasAvatar, AvatarSize.big);
        const backgroundUrl = this.environmentService.getUserBackground(profile.id);

        const data = {
          ...profile,
          avatarUrl,
          backgroundUrl
        };

        this.store.dispatch(updateProfileInfoSuccess({profileInfo: data}));
        this.store.dispatch(updateProfileIdForOtherUserByTypeBrokerage({
          profileId: +profile.id
        }));

        return data;
      }),
      catchError(err => of(err))
    );

  }

  getAvatarUrl(userId: number, hasAvatar: boolean, size: AvatarSize): string {
    if (hasAvatar) {
      return this.environmentService.getAvatarUrl(userId, size);
    }

    return this.environmentService.getIconUrl('header/avatar@1x.svg');
  }

  getEmptyAvatar(): string {
    return this.environmentService.getIconUrl('header/avatar@1x.svg');
  }

  getDefaultBackgroundImage(): string {
    return '/assets/icons/profile/default/background/default_cover_image@3x.jpg';
  }

  getViewModel(): Observable<ProfileViewModel> {
    const profileInfo$: Observable<ProfileForComponent> = this.store.pipe(select(profileInfoSelector));
    const activeTab$: Observable<ProfileNav> = this.store.pipe(select(profileActiveTabSelector));
    const userInfo$: Observable<UserInfo> = this.store.pipe(select(userInfoSelector));
    const countries$: Observable<Country[]> = this.store.pipe(select(countriesSelector));
    const accountHolder$: Observable<ProfileAccountHolder> = this.store.pipe(select(accountHolderSelector));

    return combineLatest([
      profileInfo$,
      activeTab$,
      userInfo$,
      countries$,
      accountHolder$
    ]).pipe(
      filter(([
                profileInfo,
                activeTab,
                userInfo,
                countries,
                accountHolder
              ]) => Boolean(profileInfo) && Boolean(userInfo) && Boolean(accountHolder) && Boolean(countries)),
      map(([
             profileInfo,
             activeTab,
             userInfo,
             countries,
             accountHolder
           ]) => ({profileInfo, activeTab, userInfo, countries, accountHolder})
      )
    );
  }

  getAccountHolder(userId: number): Observable<ProfileAccountHolder | null> {
    return this.profileApiService.getAccountHolderInfo(userId).pipe(
      tap(() => this.store.dispatch(getAccountHolder())),
      map(accountHolder => {
        this.store.dispatch(getAccountHolderSuccess({accountHolder}));

        return accountHolder;
      }),
      catchError(err => {
        this.store.dispatch(getAccountHolderFailure({err}));

        return of(null);
      })
    );
  }

  updateSettingsAccountHolder(info: AccountHolderInfoForServer): Observable<ProfileAccountHolder> {
    return this.profileApiService.sendAccountHolderInfo(info);
  }

  updateAvatarForAccountHolder(avatar: string | File | Blob): Observable<any> {
    return this.profileApiService.updateAvatarForAccountHolder(avatar);
  }

  updateBackgroundImageForAccountHolder(background: string | File | Blob): Observable<any> {
    return this.profileApiService.updateBackgroundImageForAccountHolder(background);
  }

}
