import {Injectable} from '@angular/core';

import {BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';

import {select, Store} from '@ngrx/store';

import {FeedApiService, UrlMeta} from '@shared/services/feed/api/feed.api.service';

import {
  loadMultipleFeedEntries,
  loadMultipleFeedEntriesSuccess,
  loadSingleFeedEntries,
  loadSingleFeedEntriesSuccess,
  removeFeeds
} from '@store/actions/feed/feed.actions';
import {FeedPost} from '@shared/models/feed/feed-post.interface';
import {TypeForLoadFeed} from '@shared/enums/feed/request/type-for-load-feed.enum';
import {requestsParamsFeedSelector} from '@store/selectors/feed/feed.selector';
import {FeedHelper} from '@shared/helpers/feed/feed.helper';
import {NewPost} from '@shared/models/feed/create-post/create-post.interface';
import {CreateFeedSuccess} from '@shared/models/feed/create-feed-response.interface';
import {FeedsComment} from '@shared/models/feed/feeds-comments-response.interface';
import {LikeResponse} from '@shared/models/news/api/like.response.interface';
import {FeedImageData} from '../../../modules/posts/posts-block/share/interfaces/feed-image-response.interface';
import {selectUserIdSelector} from '@store/selectors/profile/profile.selectors';

@Injectable({providedIn: 'root'})
export class FeedService {
  private singleFeedIdForHome$ = new BehaviorSubject<number | null>(null);

  constructor(private feedApiService: FeedApiService,
              private store: Store) {
  }

  getFeedMetadata(): Observable<any> {
    return this.feedApiService.getFeedMetadata();
  }

  getMultipleFeedEntries(newFeedResponse?: CreateFeedSuccess): Observable<FeedPost[]> {
    const requestsParamsFeedSelector$ = this.store.pipe(select(requestsParamsFeedSelector));
    const singlePost$ = newFeedResponse ? this.feedApiService.getSingleFeed(newFeedResponse.eid) : of(null);
    const userId$ = this.store.pipe(select(selectUserIdSelector));

    // @ts-ignore
    return combineLatest([requestsParamsFeedSelector$, this.singleFeedIdForHome$, userId$]).pipe(
      filter(([requestsParams, singleFeedForHome]) => Boolean(requestsParams)),
      tap(() => this.store.dispatch(loadMultipleFeedEntries())),
      switchMap(([requestsParams, singleFeedForHome, userId]) => {
        if (singleFeedForHome) {
          return this.getSingleFeedForHome(singleFeedForHome);
        }

        return forkJoin([this.feedApiService.getMultipleFeedEntries(requestsParams, userId), singlePost$])
          .pipe(
            map(([data, singlePost]) => {
              const newFeeds = FeedHelper.setMotivationMessagesForOrder(data);

              if (Boolean(singlePost)) {
                const newSinglePost = FeedHelper.setMotivationMessagesForOrderSinglePost(singlePost.data);
                this.store.dispatch(loadMultipleFeedEntriesSuccess({multipleFeedEntries: [...newFeeds]}));

                return [newSinglePost, ...data];
              } else {
                this.store.dispatch(loadMultipleFeedEntriesSuccess({multipleFeedEntries: [...newFeeds]}));

                return data;
              }
            })
          );
      }),

      catchError(err => EMPTY)
    );

  }

  getSingleFeedEntries(): Observable<any> {
    this.store.dispatch(loadSingleFeedEntries());

    return this.feedApiService.getSingleFeedEntries().pipe(
      map(data => {
        this.store.dispatch(loadSingleFeedEntriesSuccess({singleFeedEntries: data}));

        return data;
      }),
      catchError(err => throwError(err))
    );
  }

  getParamsForFeed(type: TypeForLoadFeed) {
    switch (type) {
      case TypeForLoadFeed.stock:
        return {};
    }
  }

  removeFeed(feedId: number): Observable<any> {
    return this.feedApiService.deleteFeed(feedId);
  }

  createFeed(newFeedData: NewPost): Observable<FeedPost[]> {
    if (newFeedData) {
      const urlRegex = /(https?:\/\/[^ ]*)/;
      const input = newFeedData.data.content;

      if (!newFeedData.data.content.search(/^http[s]?:\/\//) && newFeedData.file) {
        const url = input.match(urlRegex)[1];

        return forkJoin([
          this.createUrlPost(url)
            .pipe(catchError(e => of({}))),
          this.uploadFeedImage(newFeedData.file)
        ]).pipe(
          switchMap(([urlMeta, response]) => {
              const newFee = {
                ...newFeedData.data,
                uniqueFilename: response.ident
              }
              return this.feedApiService.createPost(newFee)
                .pipe(
                  switchMap(response => {
                    this.store.dispatch(removeFeeds());

                    return this.getMultipleFeedEntries(response);
                  }),
                  map(response => response),
                  catchError(err => throwError(err))
                )
            }
          )
        )
      }

      if (!newFeedData.data.content.search(/^http[s]?:\/\//)) {
        const url = input.match(urlRegex)[1];

        return this.createUrlPost(url)
          .pipe(catchError(e => of({})))
          .pipe(
            switchMap((response: UrlMeta) => {
                const newFee = {
                  ...newFeedData.data
                }
                return this.feedApiService.createPost(newFee)
                  .pipe(
                    switchMap(response => {
                      this.store.dispatch(removeFeeds());

                      return this.getMultipleFeedEntries(response);
                    }),
                    map(response => response),
                    catchError(err => throwError(err))
                  )
              }
            )
          )
      }

      if (newFeedData.file) {
        return this.uploadFeedImage(newFeedData.file)
          .pipe(
            switchMap((response: FeedImageData) => {
                const newFee = {
                  ...newFeedData.data,
                  uniqueFilename: response.ident
                }
                return this.feedApiService.createPost(newFee)
                  .pipe(
                    switchMap(response => {
                      this.store.dispatch(removeFeeds());

                      return this.getMultipleFeedEntries(response);
                    }),
                    map(response => response),
                    catchError(err => throwError(err))
                  )
              }
            )
          )
      }

      return this.feedApiService.createPost(newFeedData.data)
        .pipe(
          switchMap(response => {
            this.store.dispatch(removeFeeds());

            return this.getMultipleFeedEntries(response);
          }),
          map(response => response),
          catchError(err => throwError(err))
        )

    }
  }

  createComment(feedId: number, content: string): Observable<any> {
    if (content) {
      return this.feedApiService.createCommentForFeed(feedId, content).pipe(
        switchMap(response => {
          return of({});
        }),
        map(response => response),
        catchError(err => throwError(err))
      );
    }
  }

  getFeedComments(feedId: number, pageOffset = 0, pageLimit = 10): Observable<FeedsComment[]> {
    return this.feedApiService.getCommentsForFeed(feedId, pageOffset, pageLimit);
  }

  likeComment(commentId: number): Observable<LikeResponse> {
    return this.feedApiService.likeComment(commentId);
  }

  getSingleFeedForHome(feedId: number): Observable<FeedPost[]> {
    return this.feedApiService.getSingleFeed(feedId)
      .pipe(
        map(response => {
          this.store.dispatch(removeFeeds());
          this.store.dispatch(loadMultipleFeedEntriesSuccess({multipleFeedEntries: [response['data']]}));

          return [response];
        })
      );
  }

  setSingleFeedIdForHome(feedId: number | null): void {
    this.singleFeedIdForHome$.next(feedId);
  }

  uploadFeedImage(image: string | File | Blob): Observable<FeedImageData> {
    return this.feedApiService.uploadFeedImage(image);
  }

  createUrlPost(url: string): Observable<UrlMeta> {
    return this.feedApiService.createUrlPost(url);
  }
}
