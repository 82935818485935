import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';

import {AppComponent} from './app.component';
import {CoreConfigModule} from '@core/core.config.module';

import {JwtAppModule} from '@core/jwt/jwt.module';
import {AuthGuard} from '@core/guards/auth.guard';
import {LoginGuard} from '@core/guards/login.guard';
import {StoreAppModule} from '@store/store.module';
import {NgbModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {HeaderModule} from './modules/header/header.module';
import {DirectivesModule} from '@shared/directives/directives.module';
import {AppRoutingModule} from './app-routing.module';
import {AuthModule} from '@auth/auth.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreConfigModule,
    StoreAppModule,
    JwtAppModule,
    CommonModule,
    ToastrModule.forRoot({closeButton: true, onActivateTick: true}),
    NgbModule,
    NgbTypeaheadModule,
    HeaderModule,
    DirectivesModule,
    AuthModule
  ],
  providers: [
    AuthGuard,
    LoginGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
