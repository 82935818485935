import {Action, createReducer, on} from '@ngrx/store';

import {initialProfileState, ProfileState} from '@store/state/profile/profile.state';
import {
  acceptRequestToFriendSuccess,
  getAccountHolderSuccess,
  profileDestroy,
  rejectRequestToFriendSuccess, resetUserIdAction,
  sendRequestToAddFriendSuccess,
  sendRequestToFollowingSuccess,
  sendRequestToUnfollowingSuccess,
  sendRequestToUnfriendSuccess, updateConnectionsTabAction, updateProfileIdForOtherUserByTypeBrokerage,
  updateProfileInfoSuccess,
  updateProfilesPortfolioType,
  updateSelectedPortfolio,
  updateSettingsAccountHolderSuccess,
  updateTabAction, userIdAction
} from '@store/actions/profile/profile.actions';
import {userLogout} from '@store/actions/user.actions';

const reducer = createReducer(
  initialProfileState,
  on(updateTabAction, (state, {newTab}) => {
    return {
      ...state,
      activeTab: newTab
    };
  }),
  on(updateConnectionsTabAction, (state, {newTab}) => {
    return {
      ...state,
      connectionsNav: newTab
    };
  }),
  on(updateProfileInfoSuccess, (state, {profileInfo}) => {
    return {
      ...state,
      profileInfo: {...profileInfo}
    };
  }),
  on(updateProfilesPortfolioType, (state, {portfolioType}) => {
    return {
      ...state,
      portfolioType
    };
  }),
  on(updateSelectedPortfolio, (state, {portfolio}) => {
    return {
      ...state,
      selectedPortfolio: {...portfolio}
    };
  }),
  on(userIdAction, (state, {userId}) => {
    return {
      ...state,
      userId: userId
    };
  }),
  on(resetUserIdAction, (state) => {
    return {
      ...state,
      userId: null
    };
  }),
  on(userLogout, state => {
    return {
      ...initialProfileState
    };
  }),
  on(getAccountHolderSuccess, updateSettingsAccountHolderSuccess, (state, {accountHolder}) => {
    return {
      ...state,
      accountHolder: {...accountHolder}
    };
  }),
  on(sendRequestToAddFriendSuccess, (state, {profileId, friendRequestId}) => {
    return {
      ...state,
      profileInfo: {
        ...state.profileInfo,
        hasPendingFriendRequest: true
      }
    };
  }),
  on(acceptRequestToFriendSuccess, (state, {profileId}) => {
    return {
      ...state,
      profileInfo: {
        ...state.profileInfo,
        hasPendingFriendRequest: false,
        isFriends: true,
        friendRequestId: 0
      }
    };
  }),
  on(rejectRequestToFriendSuccess, sendRequestToUnfriendSuccess, (state, {profileId}) => {
    return {
      ...state,
      profileInfo: {
        ...state.profileInfo,
        hasPendingFriendRequest: false,
        isFriends: false,
        friendRequestId: 0
      }
    };
  }),
  on(sendRequestToFollowingSuccess, (state, {profileId}) => {
    return {
      ...state,
      profileInfo: {
        ...state.profileInfo,
        isFollowing: true
      }
    };
  }),
  on(sendRequestToUnfollowingSuccess, (state, {profileId}) => {
    return {
      ...state,
      profileInfo: {
        ...state.profileInfo,
        isFollowing: false
      }
    };
  }),
  on(updateProfileIdForOtherUserByTypeBrokerage, (state, {profileId}) => {
    return {
      ...state,
      profileIdForOtherUserByTypeBrokerage: profileId
    };
  }),
  on(profileDestroy, state => {
    return {
      ...initialProfileState
    };
  })
);

export function profileReducer(state: ProfileState, action: Action) {
  return reducer(state, action);
}
