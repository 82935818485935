import {Action, createReducer, on} from '@ngrx/store';

import {ChartState, initialChartState} from '../../state/chart/chart.state';
import {
  getPortfolioChartDataSuccess,
  getStockChartDataSuccess,
  setPortfolioChartAvailablePeriodsSuccess,
  updateSelectPortfolioChartPeriodSuccess,
  updateSelectStockChartPeriodSuccess
} from '../../actions/chart/chart.actions';
import {userLogout} from '@store/actions/user.actions';

const reducer = createReducer(
  initialChartState,
  on(getPortfolioChartDataSuccess, (state: ChartState, {chartData}) => {
    return {
      ...state,
      portfolioChartData: {
        ...state.portfolioChartData,
        ...chartData
      }
    };
  }),
  on(setPortfolioChartAvailablePeriodsSuccess, (state: ChartState, {chartPeriods}) => {
    return {
      ...state,
      portfolioChartData: {
        ...state.portfolioChartData,
        chartPeriods: [
          ...chartPeriods
        ]
      }
    };
  }),
  on(getStockChartDataSuccess, (state: ChartState, {stockChartData}) => {
    return {
      ...state,
      stockChartData
    };
  }),
  on(updateSelectStockChartPeriodSuccess, (state: ChartState, {activePeriod}) => {
    return {
      ...state,
      stockChartData: {
        ...state.stockChartData,
        activePeriod
      }
    };
  }),
  on(updateSelectPortfolioChartPeriodSuccess, (state: ChartState, {activePeriod}) => {
    return {
      ...state,
      portfolioChartData: {
        ...state.portfolioChartData,
        activePeriod
      }
    };
  }),
  on(userLogout, state => {
    return {
      ...initialChartState
    };
  })
);

export function chartReducer(state: ChartState, action: Action) {
  return reducer(state, action);
}
