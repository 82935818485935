import { Action, createReducer, on } from '@ngrx/store';

import {
  loadDiscoveredGroupsSuccess,
  loadFeaturedGroupsSuccess,
  loadGroupsForCurrentUserSuccess,
  updatePageDiscoverGroupsSuccess,
  updatePageForCurrentUserGroupsSuccess,
  resetData,
} from '@store/actions/groups/groups.actions';
import {
  GroupsState,
  initialGroupsState,
} from '@store/state/groups/groups.state';

const reducer = createReducer(
  initialGroupsState,
  on(updatePageDiscoverGroupsSuccess, (state, { pages }) => {
    return {
      ...state,
      discoverGroupsPage: {
        pages: { ...pages },
      },
    };
  }),
  on(updatePageForCurrentUserGroupsSuccess, (state, { pages }) => {
    return {
      ...state,
      yourGroupsPage: {
        pages: { ...pages },
      },
    };
  }),
  on(loadDiscoveredGroupsSuccess, (state, { groups }) => {
    return {
      ...state,
      discoverGroups: state.discoverGroups
        ? [...state.discoverGroups, ...groups]
        : [...groups],
    };
  }),
  on(loadFeaturedGroupsSuccess, (state, { groups }) => {
    return {
      ...state,
      featuredGroups: [...groups],
    };
  }),
  on(loadGroupsForCurrentUserSuccess, (state, { groups }) => {
    return {
      ...state,
      yourGroups: [...groups],
    };
  }),
  on(resetData, (state) => {
    return {
      ...state,
      discoverGroups: null,
    };
  })
);

export function groupsReducer(state: GroupsState, action: Action) {
  return reducer(state, action);
}
