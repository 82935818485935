import {UserDetails} from '@shared/models/search/user-details.interface';
import {TagSymbols} from '@shared/enums/tag/tag-symbols.enum';
import {SearchStock} from '@shared/models/search/search-stock.interface';

export interface TagState {
  user: UserDetails | null;
  users: UserDetails[] | null;
  stock: SearchStock | null;
  stocks: SearchStock[] | null;
  symbol: TagSymbols | null;
  inputsContent: string | null;
}

export const initialTagState: TagState = {
  user: null,
  users: null,
  stock: null,
  stocks: null,
  symbol: null,
  inputsContent: null
};
