import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

import {Observable} from 'rxjs';

import {EnvironmentService} from '@core/services/enviroment.service';
import {NewsService} from '@shared/services/news/news.service';
import {avatarSizes} from '@core/config/avatar.config';
import {NewsWidget} from '@shared/models/news/news.widget.interface';
import {LikeNews} from '@shared/models/news/api/like.response.interface';
import {News} from '@shared/models/news/api/news.response.interface';
import {select, Store} from '@ngrx/store';
import {newsSelector} from '@store/selectors/portfolio/widgest/news.selector';
import {LikeType} from '@shared/enums/like/like-type.enum';

@Component({
  selector: 'app-related-news-widget',
  templateUrl: './related-news-widget.component.html',
  styleUrls: ['./related-news-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedNewsWidgetComponent implements OnInit {
  news$: Observable<NewsWidget> = this.store.pipe(select(newsSelector));
  loadNews$: Observable<News[]> = this.newsService.getNews();
  like$: Observable<LikeNews>;
  lastElement = 5;

  constructor(private environmentService: EnvironmentService,
              private newsService: NewsService,
              private store: Store) {
  }

  ngOnInit(): void {
    // console.log('news init');
  }

  getIconUrl(name: string) {
    return this.environmentService.getIconUrl('related-news-widget/' + name);
  }

  getAvatarUrl(hasAvatar: boolean, userId: number): string {
    if (hasAvatar) {
      return this.environmentService.getAvatarUrl(userId, avatarSizes.normal);
    }

    return this.getIconUrl('avatar@1x.svg');
  }

  error(error) {
    error.target.src = this.environmentService.getIconUrl('empty/stock.svg');
    error.target.classList.add('no-icon');
  }

  errorIcon(error) {
    // console.log('error => ', error);
    error.target.classList.add('no-icon');
  }

  trackByFn(index, news: News) {
    return news.id;
  }

  openNews(url: string) {
    window.open(url, '_blank');
  }

  showMore() {
  }

  like(news: News, index: number) {
    const likeAction = {id: news.id, type: LikeType.news, index};

    this.like$ = this.newsService.like(likeAction);
  }

}
