import {createAction, props} from '@ngrx/store';

import {OrderDialogueSuccess} from '@shared/models/order-dialogue/order-dialogue-success.interface';
import {OrderTab} from '../../../modules/symbol/components/order-dialog/shared/enums/order.tab.enum';
import {OrdersDialogueType} from '../../../modules/symbol/components/order-dialog/shared/enums/orders-dialogue.type.enum';
import {OrderDialogueStep} from '../../../modules/symbol/components/order-dialog/shared/enums/order-dialogue-buy.step.enum';
import {OrderDialogueForm} from '../../../modules/symbol/components/order-dialog/shared/interfaces/order-dialogue-buy-form.interface';
import {OrderDialogResponse} from '@shared/interfaces/order-dialog/order-dialog-response.interface';

enum OrderDialogueActions {
  SelectOrderType = '[ORDER-DIALOGUE] Select order type dialogue',
  SelectOrderTab = '[ORDER-DIALOGUE] Select order tab dialogue',
  LoadAvailableBuyingPower = '[ORDER-DIALOGUE] Load available buying power',
  LoadAvailableBuyingPowerSuccess = '[ORDER-DIALOGUE] Load available buying power success',
  UpdateOrderDialogueFormStep = '[ORDER-DIALOGUE] Update order dialogue form step',
  UpdateOrderDialogueForm = '[ORDER-DIALOGUE] Update order dialogue form',
  LoadOrderDialogueInitialData = '[ORDER-DIALOGUE] Load initial dialogue data',
  OrderConfirmSuccess = '[ORDER-DIALOGUE API] Order confirm success'
}

export const selectOrderDialogueTab = createAction(
  OrderDialogueActions.SelectOrderTab,
  props<{ orderTab: OrderTab }>()
);

export const selectOrderDialogueType = createAction(
  OrderDialogueActions.SelectOrderType,
  props<{ orderType: OrdersDialogueType }>()
);

export const loadAvailableBuyingPower = createAction(
  OrderDialogueActions.LoadAvailableBuyingPower
);

export const loadAvailableBuyingPowerSuccess = createAction(
  OrderDialogueActions.LoadAvailableBuyingPowerSuccess,
  props<{ availableBuyingPower: any }>()
);

export const updateOrderDialogueFormStep = createAction(
  OrderDialogueActions.UpdateOrderDialogueFormStep,
  props<{ step: OrderDialogueStep }>()
);

export const updateOrderDialogueForm = createAction(
  OrderDialogueActions.UpdateOrderDialogueForm,
  props<{ form: OrderDialogueForm }>()
);

export const orderConfirmSuccess = createAction(
  OrderDialogueActions.OrderConfirmSuccess,
  props<{ response: OrderDialogResponse }>()
);

export const loadOrderDialogueInitialData = createAction(
  OrderDialogueActions.LoadOrderDialogueInitialData
);
