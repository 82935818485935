import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
  selector: 'app-tooltip-copy',
  templateUrl: './tooltip-copy.component.html',
  styleUrls: ['./tooltip-copy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipCopyComponent implements OnInit {
  @Input() isStock = false;

  constructor() { }

  ngOnInit(): void {
  }

}
