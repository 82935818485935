import {createFeatureSelector, createSelector} from '@ngrx/store';

import {FeedState} from '@store/state/feed/feed.state';

const feedFeatureSelector = createFeatureSelector<FeedState>('feed');

export const multipleFeedEntriesSelector = createSelector(
  feedFeatureSelector,
  (state) => state.multipleFeedEntries
);

export const singleFeedEntriesSelector = createSelector(
  feedFeatureSelector,
  (state) => state.singleFeedEntries
);

export const requestsParamsFeedSelector = createSelector(
  feedFeatureSelector,
  (state ) => state.requestsParams
);

export const getImageForFeed = createSelector(
  feedFeatureSelector,
  (state) => state.feedImageData
);
