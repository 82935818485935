import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';

import {Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map} from 'rxjs/operators';

import {
  addSongToWatchListAction,
  addSongToWatchListFailureAction,
  addSongToWatchListSuccessAction,
  removeSongFromWatchListAction,
  removeSongToWatchListFailureAction,
  removeSongToWatchListSuccessAction
} from '@store/actions/watchlist/watchlist.actions';
import {WatchlistApiService} from '@shared/services/watchlist/api/watchlist.api.service';
import {NotificationService} from '@core/services/notification.service';

@Injectable()
export class WatchlistEffects {

  constructor(private store: Store,
              private actions: Actions,
              private watchlistApiService: WatchlistApiService,
              private notificationService: NotificationService) {
  }

  addSongToWatchList$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(addSongToWatchListAction),
      exhaustMap(action => this.watchlistApiService.addToWatchList(action.ticker)
        .pipe(
          map( data => {
            this.notificationService.showSuccess('Song added to your Watchlist');
            return addSongToWatchListSuccessAction();
          }),
          catchError(e => {
            this.notificationService.showError('Song added to your Watchlist is Failure');
            return of(addSongToWatchListFailureAction())
          })
        ))
    )
  )

  removeSongToWatchList$: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(removeSongFromWatchListAction),
      exhaustMap(action => this.watchlistApiService.removeSongFromWatchlist(action.song)
        .pipe(
          map( data => {
            return removeSongToWatchListSuccessAction({songId: action.song.stockId});
          }),
          catchError(e => {
            return of(removeSongToWatchListFailureAction())
          })
        ))
    )
  )
}
