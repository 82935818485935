import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';

import {
  acceptRequestToFriend,
  acceptRequestToFriendFailure,
  acceptRequestToFriendSuccess,
  rejectRequestToFriend,
  rejectRequestToFriendFailure,
  rejectRequestToFriendSuccess,
  sendRequestToAddFriend,
  sendRequestToAddFriendFailure,
  sendRequestToAddFriendSuccess,
  sendRequestToFollowing,
  sendRequestToFollowingFailure,
  sendRequestToFollowingSuccess,
  sendRequestToUnfollowing,
  sendRequestToUnfollowingFailure,
  sendRequestToUnfollowingSuccess,
  sendRequestToUnfriend,
  sendRequestToUnfriendFailure,
  sendRequestToUnfriendSuccess,
  updateSettingsAccountHolder,
  updateSettingsAccountHolderFailure,
  updateSettingsAccountHolderSuccess,
  uploadAvatar,
  uploadAvatarFailure,
  uploadAvatarSuccess,
  uploadBackgroundImage,
  uploadBackgroundImageFailure,
  uploadBackgroundImageSuccess
} from '@store/actions/profile/profile.actions';
import {ProfileService} from '@shared/services/profile/profile.service';
import {UserApiService} from '@shared/services/user/api/user.api.service';
import {
  setUserNotificationRead,
  setUserNotificationReadError,
  setUserNotificationReadSuccess
} from '@store/actions/user.actions';
import {AvatarSize} from '@shared/enums/user/avatar-size.enum';
import {Store} from '@ngrx/store';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class ProfileEffects {

  constructor(private actions$: Actions,
              private modalService: NgbModal,
              private profileService: ProfileService,
              private userApiService: UserApiService,
              private store: Store,
              private toastr: ToastrService) {
  }

  updateProfile$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSettingsAccountHolder),
      exhaustMap(action =>
        this.profileService.updateSettingsAccountHolder(action.settings).pipe(
          map(response => {
            this.modalService.dismissAll('close edit profile');

            return updateSettingsAccountHolderSuccess({accountHolder: response});

            // return {fileForAvatar: action.fileForAvatar, fileForBackground: action.fileBackgroundImage};
          }),
          // mergeMap(({fileForAvatar, fileForBackground}) => [
          //   uploadAvatar({file: fileForAvatar}),
          //   uploadBackgroundImage({file: fileForBackground})
          // ]),
          catchError(err => of(updateSettingsAccountHolderFailure({err})))
        )
      )
    )
  );

  uploadAvatar$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadAvatar),
      exhaustMap((action) =>
        this.profileService.updateAvatarForAccountHolder(action.file).pipe(
          map(response => {
            this.store.dispatch(uploadAvatarSuccess({
              avatarUrl: ''
            }));

            this.toastr.success('Avatar image is updated');
            return uploadAvatarSuccess({
              avatarUrl: this.profileService.getAvatarUrl(action.userInfo.id, action.userInfo.hasAvatar, AvatarSize.large)
            });
          }),
          catchError(err => {
            this.toastr.error('Avatar image is failure');

            return of(uploadAvatarFailure({err}));
          })
        )
      )
    )
  );

  uploadBackground$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadBackgroundImage),
      exhaustMap(action =>
        this.profileService.updateBackgroundImageForAccountHolder(action.file).pipe(
          map(response => {
            this.toastr.success('Background image is updated');
            return uploadBackgroundImageSuccess();
          }),
          catchError(err => {
            this.toastr.error('Background image is failure');

            return of(uploadBackgroundImageFailure({err}));
          })
        )
      )
    )
  );

  addFriend$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(sendRequestToAddFriend),
      exhaustMap(action =>
        this.userApiService.sendRequestToFriend(action.userId).pipe(
          map(response => {
            return sendRequestToAddFriendSuccess({profileId: action.userId, friendRequestId: action.friendRequestId});
          }),
          catchError(err => of(sendRequestToAddFriendFailure({err})))
        )
      )
    ));

  acceptRequestToFriend$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptRequestToFriend),
      exhaustMap(action =>
        this.userApiService.acceptRequestToFriend(action.userId).pipe(
          map(response => {
            return acceptRequestToFriendSuccess({profileId: action.userId});
          }),
          catchError(err => of(acceptRequestToFriendFailure({err})))
        )
      )
    ));

  rejectRequestToFriend$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectRequestToFriend),
      exhaustMap(action =>
        this.userApiService.rejectRequestToFriend(action.userId).pipe(
          map(response => {
            return rejectRequestToFriendSuccess({profileId: action.userId});
          }),
          catchError(err => of(rejectRequestToFriendFailure({err})))
        )
      )
    ));

  sendRequestToUnfriend$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(sendRequestToUnfriend),
      exhaustMap(action =>
        this.userApiService.sendRequestToUnfriend(action.userId).pipe(
          map(response => {
            return sendRequestToUnfriendSuccess({profileId: action.userId});
          }),
          catchError(err => of(sendRequestToUnfriendFailure({err})))
        )
      )
    ));

  sendRequestToFollowing$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(sendRequestToFollowing),
      exhaustMap(action =>
        this.userApiService.sendRequestToFollow(action.userId).pipe(
          map(response => {
            return sendRequestToFollowingSuccess({profileId: action.userId});
          }),
          catchError(err => of(sendRequestToFollowingFailure({err})))
        )
      )
    ));

  sendRequestToUnfollowing$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(sendRequestToUnfollowing),
      exhaustMap(action =>
        this.userApiService.deleteRequestToUnfriend(action.userId).pipe(
          map(response => {
            return sendRequestToUnfollowingSuccess({profileId: action.userId});
          }),
          catchError(err => of(sendRequestToUnfollowingFailure({err})))
        )
      )
    ));

  setUserNotificationRead$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(setUserNotificationRead),
      exhaustMap(action =>
        this.userApiService.setUserNotificationRead(action.notificationId).pipe(
          map(response => {
            return setUserNotificationReadSuccess({notificationId: action.notificationId});
          }),
          catchError(err => of(setUserNotificationReadError({err})))
        )
      )
    ));
}
