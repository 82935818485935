import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { EnvironmentService } from '@core/services/enviroment.service';
import { UserNotification } from '@shared/models/user/user.notification.interface';
import { UserNotificationList } from '@shared/models/user/user.notification.list.interface';

@Component({
  selector: 'app-user-notifications-list',
  templateUrl: './user-notifications-list.component.html',
  styleUrls: ['./user-notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotificationsListComponent {
  @Input() userNotification: UserNotification;
  @Input() userNotificationList: UserNotificationList;

  constructor(private environmentService: EnvironmentService) {}

  getIconUrl(name: string) {
    return this.environmentService.getIconUrl('header/' + name);
  }
}
