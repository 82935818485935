import {createAction, props} from '@ngrx/store';

export enum PositionActions {
  SelectPositionForDetails = '[POSITION-WIDGET] Select position for details',
}

export const selectPositionForDetails = createAction(
  PositionActions.SelectPositionForDetails,
  props<{ ticker: string }>()
);

export const resetPositionListData = createAction('Reset position list');
