import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appTextCopy]'
})
export class TextCopyDirective {
  constructor() {
  }

  @Output('copied')
  public copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('click') copyText() {
    const textArea = document.createElement('textarea');

    textArea.style.position = 'fixed';
    textArea.style.top = '-999px';
    textArea.style.left = '-999px';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    textArea.value = window.location.href;
    document.body.appendChild(textArea);

    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      this.copied.emit(msg);
      // console.log(msg);
    } catch (err) {
      // console.log('unable to copy');
    }

    document.body.removeChild(textArea);
  }
}
