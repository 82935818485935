import {createAction, props} from '@ngrx/store';

import {UserDetails} from '@shared/models/search/user-details.interface';
import {StockNews} from '@shared/models/news/stock-news.interface';
import {AddUserForType} from '@shared/enums/search/add-user-for-type.enum';
import {CustomUrlWithUrl} from '../../../modules/symbol/components/order-dialog/shared/interfaces/custom-url-with-url.interface';

export enum MotivationActions {
  AddUserToFollowing = '[Motivation] Add user to following',
  RemoveUserFromFollowing = '[Motivation] Remove user from following',
  AddNews = '[Motivation] Add news',
  RemoveNews = '[Motivation] Remove news',
  SaveNewsSuccess = '[Motivation Stock News API] Save stock news success',
  UpdateNewsOffset = '[Motivation] update news offset',
  HideNewsSearch = '[Motivation] Hide news search',
  ShowNewsSearch = '[Motivation] Show news search',
  AddCustomLink = '[Motivation] Add custom Link',
  RemoveCustomLink = '[Motivation] Remove custom Link',
  CloseMotivation = '[Motivation] Close motivation',
  ShowMotivation = '[Motivation] Show motivation',
  SaveTypeForRequestFindUserSuccess = '[Motivation] save type for request find user success',
}

export const saveTypeForRequestFindUserSuccess = createAction(
  MotivationActions.SaveTypeForRequestFindUserSuccess,
  props<{ typeForRequest: AddUserForType | null }>()
);

export const addUserToFollowing = createAction(
  MotivationActions.AddUserToFollowing,
  props<{ user: UserDetails }>()
);

export const removeUserFromFollowing = createAction(
  MotivationActions.RemoveUserFromFollowing,
  props<{ user: UserDetails }>()
);

export const addNews = createAction(
  MotivationActions.AddNews,
  props<{ news: StockNews }>()
);

export const addCustomLink = createAction(
  MotivationActions.AddCustomLink,
  props<{ link: CustomUrlWithUrl }>()
);

export const removeCustomLink = createAction(
  MotivationActions.RemoveCustomLink,
  props<{ link: CustomUrlWithUrl }>()
);

export const removeNews = createAction(
  MotivationActions.RemoveNews,
  props<{ news: StockNews }>()
);

export const saveNewsSuccess = createAction(
  MotivationActions.SaveNewsSuccess,
  props<{ news: StockNews[] }>()
);

export const updateNewsOffset = createAction(
  MotivationActions.UpdateNewsOffset,
  props<{ offset: number }>()
);

export const hideNewsSearchAction = createAction(
  MotivationActions.HideNewsSearch
);

export const showNewsSearchAction = createAction(
  MotivationActions.ShowNewsSearch
);

export const closeMotivation = createAction(
  MotivationActions.CloseMotivation
);

export const showMotivation = createAction(
  MotivationActions.ShowMotivation
);
