import {NgModule} from '@angular/core';

import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreRouterConnectingModule} from '@ngrx/router-store';

import {EffectsModule} from '@ngrx/effects';

import {environment} from '@env/environment';
import {appReducers} from './reducers/app.reducer';
import {CustomSerializer} from '@core/helpers/custom-route-serializer';
import {appEffects} from '@store/effects/app.effects';

@NgModule({
  imports: [
    StoreModule.forRoot(appReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([...appEffects]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer
    }),
    !environment.production ? StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}) : []
  ]
})
export class StoreAppModule {
}
