import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {routes} from '@auth/auth-routing';

import {AuthComponent} from '@auth/components/auth.component';
import {RegistrationComponent} from '@auth/components/registration/registration.component';
import {LoginComponent} from '@auth/components/login/login.component';
import {ForgotPasswordComponent} from '@auth/components/forgot-password/forgot-password.component';
import {AlmostThereComponent} from '@auth/components/almost-there/almost-there.component';
import {LogoutComponent} from '@auth/components/logout/logout.component';
import {AuthApiService} from '@auth/services/auth.api.service';
import {AuthenticationService} from '@auth/services/authentication.service';
import {AlmostThereGuard} from '@auth/shared/guards/almostThere.guard';
import {ShareModule} from '@shared/share.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ShareModule
  ],
  declarations: [
    AuthComponent,
    RegistrationComponent,
    LoginComponent,
    ForgotPasswordComponent,
    AlmostThereComponent,
    LogoutComponent,
  ],
  providers: [
    AuthApiService,
    AuthenticationService,
    AlmostThereGuard
  ],
  exports: [
    LoginComponent,
    RegistrationComponent
  ]
})
export class AuthModule {
}
