import {createAction, props} from '@ngrx/store';

import {PositionForTicker} from '@shared/models/positions/position-for-ticker.interface';

export enum TickerPositionActions {
  LoadTickerPosition = '[TICKER-POSITION] Load position ticker',
  LoadPositionSuccess = '[TICKER-POSITION] Load position success',
}

export const loadTickerPositionSuccess = createAction(
  TickerPositionActions.LoadPositionSuccess,
  props<{ position: PositionForTicker }>()
);

export const loadTickerPosition = createAction(
  TickerPositionActions.LoadTickerPosition,
);
