import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '@env/environment';
import {Loser} from '../../../interfaces/losers/losers.interface';

@Injectable({providedIn: 'root'})
export class LosersApiService {
  private readonly BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  loadLosersFromApi(offset: number = 0): Observable<Loser[]> {
    return this.http
      .get(this.BASE_URL + `/api/v2/stock/losers/all/list?offset=${offset}&limit=${7}`)
      .pipe(map((response: any) => response.body.stock.losers.all.length ? response.body.stock.losers.all : []));
  }
}
