import {Injectable} from '@angular/core';

import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {LosersApiService} from './api/losers-api.service';
import {Loser, LosersResponse} from '../../interfaces/losers/losers.interface';

@Injectable({providedIn: 'root'})
export class LosersService {
  private losers$ = new BehaviorSubject<LosersResponse | null>(null);

  constructor(private losersApiService: LosersApiService) {
  }

  loadLosersFromApi(offset: number = 0): Observable<Loser[]> {
    return this.losersApiService.loadLosersFromApi(offset)
      .pipe(
        map(response => {
          const oldLosers = this.losers$.getValue();

          if (Boolean(oldLosers)) {
            const newGainers: LosersResponse = {
              ...oldLosers,
              losers: [...oldLosers.losers, ...response],
              pagination: {
                count: response.length,
                current_page: oldLosers.pagination.current_page + 1,
                per_page: 0,
                total_pages: response.length > 0 ? response.length / 7 : 0
              }
            };

            this.setLosers(newGainers);
          } else {
            const newGainers: LosersResponse = {
              losers: [...response],
              pagination: {
                count: response.length,
                current_page: 0,
                per_page: 0,
                total_pages: response.length > 0 ? response.length / 7 : 0
              }
            };
            this.setLosers(newGainers);
          }

          return response;
        }),
        catchError(e => {
          this.setLosers(null);

          return EMPTY;
        })
      );
  }

  getLosers(): Observable<LosersResponse> {
    return this.losers$.asObservable();
  }

  clearLosers(): void {
    this.setLosers(null);
  }

  setLosers(data: any) {
    this.losers$.next(data);
  }
}
