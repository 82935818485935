import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
} from '@angular/core';

import { UserNotificationList } from '@shared/models/user/user.notification.list.interface';
import { UserNotification } from '@shared/models/user/user.notification.interface';
import { EnvironmentService } from '@core/services/enviroment.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-notifications-dropdown',
  templateUrl: './user-notifications-dropdown.component.html',
  styleUrls: ['./user-notifications-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotificationsDropdownComponent implements OnInit {
  @Input() userNotificationList: UserNotificationList;
  @Input() userNotification: UserNotification;

  @ViewChild(NgbDropdown) public dropdown: NgbDropdown;

  constructor(private environmentService: EnvironmentService) {}

  ngOnInit(): void {}

  getIconUrl(name: string) {
    return this.environmentService.getIconUrl('header/' + name);
  }
}
