<app-popup-wrapper [bodyName]="'login'"
                   [addClass]="'login'"
                   (dismiss$)="dismissModal()"
>
  <ng-container slot="body">

    <ng-template *ngIf="login$ | async"></ng-template>

    <h2 class="modal__heading">Sign in to Soundroy</h2>

    <div class="modal__wrapper">
      <form (ngSubmit)="onSubmitHandler()" [formGroup]="signInForm">
        <div class="form-field">
          <div class="input input--username" [ngClass]="{'input--error': addFieldErrorClass('userName')}">
            <div class="input__prepend input__prepend--user"></div>

            <input
              type="text"
              class="input__element"
              placeholder="User Name"
              name="userName"
              formControlName="userName"
              readonly
              onfocus="if (this.hasAttribute('readonly')) {
                        this.removeAttribute('readonly');
                        this.blur();
                        this.focus();
                    }"
            />
          </div>

          <div *ngIf="showFieldErrorText('userName')" class="form-field__error form-field__error--show">
            <p class="form-field__error-text">
              {{ getFieldErrorText("userName", "Invalid User Name") }}
            </p>
          </div>
        </div>

        <div class="form-field">
          <div class="input input--password" [ngClass]="{'input--error': addFieldErrorClass('password')}">
            <div class="input__prepend input__prepend--password"></div>

            <input
              class="input__element"
              placeholder="Password"
              name="password"
              [type]="isShowPassword ? 'text' : 'password'"
              formControlName="password"
            />

            <span
              class="input__append input__append--password"
              [ngClass]="{ 'input__append--password-show': !isShowPassword }"
              (click)="togglePasswordVisibility()"
            >
        </span>

          </div>

          <div *ngIf="showFieldErrorText('password')" class="form-field__error form-field__error--show">
            <p class="form-field__error-text"></p>
            <p class="form-field__error-text form-field__error-text--backend">
              {{ getFieldErrorText("password", "Invalid password") }}
            </p>
          </div>
        </div>

        <button [disabled]="signInForm.invalid"
                type="submit"
                class="btn btn--full-width mb-3"
                [ngClass]="{'btn--inactive': signInForm.invalid, 'btn--active': signInForm.valid}"
        >
          Log In
        </button>

        <div (click)="forgotPassword()">
          <a class="modal__text modal__text--link modal__text--sign-up modal__text--forgot">
            Forgot your password?
          </a>
        </div>
      </form>

    </div>

    <p class="modal__text modal__text--footer" (click)="signUp()">
      New to Soundroy?
      <a class="modal__text modal__text--link modal__text--sign-up">Sign Up Now</a>
    </p>
  </ng-container>
</app-popup-wrapper>

<app-forgot-password (openLogin$)="openModal()"></app-forgot-password>
