import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Profile} from '@shared/models/profile/profile.interface';
import {ProfileResponse} from '@shared/models/profile/api/profile-response.interface';
import {ProfileAccountHolderInfoResponse} from '@shared/models/profile/api/profile-account-holder-info-response.model';
import {ProfileAccountHolder} from '@shared/models/profile/profile-account-holder.interface';
import {AccountHolderInfoForServer} from '@shared/models/profile/account-holder-info-for-server.interface';

@Injectable({providedIn: 'root'})
export class ProfileApiService {

  constructor(private http: HttpClient) {
  }

  /**
   *
   * response:
   * id - user id
   * username - username
   * isFriends": false //false means that this user and logged in user are not friends
   * hasPendingFriendRequest": true //true means that logged in user has sent friend request to this user
   * isFollowing": false //false means that logged in user is not following this user
   * isFollowed": false //false means that this user is not following logged in user
   */

  getProfileInfo(userId: number): Observable<Profile> {
    return this.http.get(`${environment.apiUrl}/api/v3/user/profile/info.json?userId=${userId}`).pipe(
      map((data: ProfileResponse) => data.data)
    );
  }

  getAccountHolderInfo(userId: number): Observable<ProfileAccountHolder> {
    return this.http.get(`${environment.apiUrl}/api/v2/user/setting/account-holder/get?userId=${userId}`).pipe(
      map((data: ProfileAccountHolderInfoResponse) => data.body.settings.accountHolder)
    );
  }

  sendAccountHolderInfo(info: AccountHolderInfoForServer): Observable<ProfileAccountHolder> {
    return this.http.post(`${environment.apiUrl}/api/v2/user/setting/account-holder/set`, {...info}).pipe(
      map((data: ProfileAccountHolderInfoResponse) => data.body.settings.accountHolder)
    );
  }

  updateAvatarForAccountHolder(avatar: string | File | Blob): Observable<any> {
    const formData = new FormData();

    formData.append('image', avatar);
    formData.append('type', 'avatar');

    const httpOptions = {
      headers: new HttpHeaders().delete('Content-Type')
    };

    return this.http.post(`${environment.apiUrl}/api/v2/user/setting/profile-image/set`,
      formData,
      httpOptions
      ).pipe(
      map(data => data)
    );
  }

  updateBackgroundImageForAccountHolder(background: string | File | Blob): Observable<any> {
    const formData = new FormData();

    formData.append('image', background);
    formData.append('type', 'cover');

    const httpOptions = {
      headers: new HttpHeaders().delete('Content-Type')
    };

    return this.http.post(`${environment.apiUrl}/api/v2/user/setting/profile-mobile-cover-image/set`,
      formData,
      httpOptions
    ).pipe(
      map(data => data)
    );
  }

}
