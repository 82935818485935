<div [ngSwitch]="notification.ident"
     (click)="markNotificationAsRead()"
     [ngClass]="{notificationUnread: !notification?.isRead}">

  <!--  NewFriendRequest -->
  <ng-template [ngSwitchCase]="ident.NewFriendRequest">
    <div
      class="notification"
    >
      <div class="notification-image" (click)="goToProfile(notification?.from?.user?.id)">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description" (click)="goToProfile(notification?.from?.user?.id)">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p>wants to add you as a friend</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
      <div class="notification-actions">
        <button type="button"
                class="confirm"
                aria-label="Confirm"
                (click)="confirmFriendRequest()"
                *ngIf="!notification.isRead">Confirm</button>
        <button type="button"
                class="delete"
                aria-label="Delete"
                (click)="deleteFriendRequest()"
                *ngIf="!notification.isRead">Delete</button>
      </div>
    </div>
  </ng-template>

  <!--  AcceptedFriendRequest -->
  <ng-template [ngSwitchCase]="ident.AcceptedFriendRequest">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p>You are now friends with</p>
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!--  NewFollower -->
  <ng-template [ngSwitchCase]="ident.NewFollower">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p>is now following you!</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- NewMentionWallpostText -->
  <ng-template [ngSwitchCase]="ident.NewMentionWallpostText">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p class="font-weight-bold">mentioned you in the post.</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- NewCommentCommunityOrder -->
  <ng-template [ngSwitchCase]="ident.NewCommentCommunityOrder">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p class="font-weight-bold">commented on your order.</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- NewBullWallpost -->
  <ng-template [ngSwitchCase]="ident.NewBullWallpost">
    <div
      class="notification"
      (click)="goToHome(notification?.feedId)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p class="font-weight-bold"> liked your post.</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- NewWallpostText -->
  <ng-template [ngSwitchCase]="ident.NewWallpostText">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p class="font-weight-bold"> made a post on your wall.</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- NewWallpostLink -->
  <ng-template [ngSwitchCase]="ident.NewWallpostLink">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p class="font-weight-bold">posted a link on your wall.</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- NewWallpostImage -->
  <ng-template [ngSwitchCase]="ident.NewWallpostImage">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p class="font-weight-bold">posted an image on your wall.</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- NewWallpostVideo -->
  <ng-template [ngSwitchCase]="ident.NewWallpostVideo">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p class="font-weight-bold">posted an video on your wall.</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- NewBullCommunityOrder -->
  <ng-template [ngSwitchCase]="ident.NewBullCommunityOrder">
    <div
      class="notification"
      (click)="goToHome(notification?.feedId)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p class="font-weight-bold"> liked your order.</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- GroupPost -->
  <ng-template [ngSwitchCase]="ident.GroupPost">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p class="font-weight-bold"> posted in group.</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- GroupInvite -->
  <ng-template [ngSwitchCase]="ident.GroupInvite">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p class="font-weight-bold">invited you to the group.</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- CommunityUserAlert -->
  <ng-template [ngSwitchCase]="ident.CommunityUserAlert">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">{{ notification.from?.user?.name }}</p>
          <p class="font-weight-bold"> just bought /sold</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!--  StockPriceAlert -->
  <ng-template [ngSwitchCase]="ident.StockPriceAlert">
    <div class="notification" (click)="goToStock(notification.stockSymbol)">
      <div class="notification-image">
        <picture>
          <img
            class="company-logo"
            [src]="getLogorUrl(notification.stockSymbol)"
            alt="logo"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p>
            Your
            <span class="font-weight-bold">{{ notification.stockSymbol }}</span>
          </p>
          <p>price alert just triggered.</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!--  StockValueAlert -->
  <ng-template [ngSwitchCase]="ident.StockValueAlert">
    <div class="notification" (click)="goToStock(notification.stockSymbol)">
      <div class="notification-image">
        <picture>
          <img
            class="company-logo"
            [src]="getLogorUrl(notification.stockSymbol)"
            alt="logo"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p>
            Your
            <span class="font-weight-bold">{{ notification.stockSymbol }}</span>
          </p>
          <p>value alert just triggered.</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- CommunityPortfolioSync -->
  <ng-template [ngSwitchCase]="ident.CommunityPortfolioSync">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">Your portfolio was synced</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- CommunityPortfolioUnSync -->
  <ng-template [ngSwitchCase]="ident.CommunityPortfolioUnSync">
    <div
      class="notification"
      (click)="goToProfile(notification?.from?.user?.id)"
    >
      <div class="notification-image">
        <picture>
          <img
            class="user-avatar"
            [src]="
              getAvatarUrl(
                notification.from?.user?.hasAvatar,
                notification.from?.user?.id
              )
            "
            alt="avatar"
            (error)="userIconError($event)"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p class="font-weight-bold">Your portfolio was unsynced</p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!--  CommunityOrderCancelled -->
  <ng-template [ngSwitchCase]="ident.CommunityOrderCancelled">
    <div class="notification">
      <div class="notification-image">
        <picture>
          <img
            class="notification-ico"
            src="/assets/icons/notifications/buy_green.svg"
            alt="notification"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p>You order</p>
          <p>
            <span class="font-weight-bold">{{
              notification.stock?.ticker | appReplaceSongTitle
              }}</span>
            {{ notification.quantity }} was canceled.
          </p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

  <!--  CommunityOrderPlaced -->
  <ng-template [ngSwitchCase]="ident.CommunityOrderPlaced">
    <div class="notification">
      <div class="notification-image">
        <picture>
          <img
            class="notification-ico"
            src="/assets/icons/notifications/buy_green.svg"
            alt="notification"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p>You order</p>
          <p>
            <span class="font-weight-bold">{{
              notification.stock?.ticker | appReplaceSongTitle
              }}</span>
            {{ notification.quantity }} was placed.
          </p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

<!--  CommunityOrderExecuted -->
  <ng-template [ngSwitchCase]="ident.CommunityOrderExecuted">
    <div class="notification">
      <div class="notification-image">
        <picture>
          <img
            class="notification-ico"
            src="/assets/icons/notifications/buy_green.svg"
            alt="notification"
          />
        </picture>
      </div>
      <div class="notification-description">
        <div class="text">
          <p>You sucessfully bought</p>
          <p>
            <span class="font-weight-bold">{{
              notification.stock?.ticker | appReplaceSongTitle
            }}</span>
            {{ notification.quantity }} shares.
          </p>
        </div>
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </div>
    </div>
  </ng-template>

</div>

<ng-template #text>
  <div class="text">{{ notification.fallbackContent }}</div>
</ng-template>

<ng-template #date>
  <div class="date">{{ notification.createdAt | date: "MMM d" }}</div>
</ng-template>
