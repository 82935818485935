import {Action, createReducer, on} from '@ngrx/store';
import {initialOrderDialogueState, OrderDialogueState} from '../../state/order-dialogue/order-dialogue.state';
import {
  loadAvailableBuyingPowerSuccess,
  loadOrderDialogueInitialData,
  orderConfirmSuccess,
  selectOrderDialogueTab,
  selectOrderDialogueType,
  updateOrderDialogueForm,
  updateOrderDialogueFormStep
} from '../../actions/order-dialogue/order-dialogue.actions';
import {OrderDialogueStep} from '../../../modules/symbol/components/order-dialog/shared/enums/order-dialogue-buy.step.enum';
import {userLogout} from '@store/actions/user.actions';

const reducer = createReducer(
  initialOrderDialogueState,
  on(selectOrderDialogueTab, (state, {orderTab}) => {
    return {
      ...state,
      activeTab: orderTab
    };
  }),
  on(selectOrderDialogueType, (state, {orderType}) => {
    return {
      ...state,
      orderType,
      step: OrderDialogueStep.INITIAL
    };
  }),
  on(loadAvailableBuyingPowerSuccess, (state, {availableBuyingPower}) => {
    return {
      ...state,
      availableBuyingPower
    };
  }),
  on(updateOrderDialogueFormStep, (state, {step}) => {
    return {
      ...state,
      step
    };
  }),
  on(updateOrderDialogueForm, (state, {form}) => {
    return {
      ...state,
      buyForm: {...form}
    };
  }),
  on(orderConfirmSuccess, (state, {response}) => {
    return {
      ...state,
      confirmOrder: {...response}
    };
  }),
  on(loadOrderDialogueInitialData, (state) => {
    return initialOrderDialogueState ? {...initialOrderDialogueState} : null;
  }),
  on(userLogout, state => {
    return {
      ...initialOrderDialogueState
    };
  })
);

export function orderDialogueReducer(state: OrderDialogueState, action: Action) {
  return reducer(state, action);
}
