import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {GuardHelper} from '@core/helpers/guard.helper';
import {TokenService} from '@core/jwt/services/token.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private tokenService: TokenService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    const token = this.tokenService.currentTokenValue();

    return GuardHelper.isToken(token) || this.router.createUrlTree(['/'])
  }
}
