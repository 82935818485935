import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {environment} from '@env/environment';
import {
  TrendingInvestor,
  TrendingInvestorsResponse
} from '../../../interfaces/trending-investor/trending-investor.interface';

@Injectable({providedIn: 'root'})
export class TrendingInvestorsApiService {
  private readonly BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getTrendingInvestors(offset: number = 0): Observable<TrendingInvestor[]> {
    return this.http
      .get(this.BASE_URL + `/api/v3/trending-user/list.json?offset=${offset}&limit=${5}`)
      .pipe(map((response: TrendingInvestorsResponse) => response.data.length ? response.data : []));
  }

  followRequest(userId: number, potentialUsersId: number): Observable<any> {
    const body = {
      userId: userId,
      usersIds: [potentialUsersId]
    }

    return this.http.post(this.BASE_URL + `/api/v2/user/follow-request/send`, body)
  }
}
