import { UserDetails } from "@shared/models/search/user-details.interface";
import { SearchStock } from "@shared/models/search/search-stock.interface";

export interface SearchState {
  stocks: SearchStock[] | null;
  users: UserDetails[] | null;
  cachedStocks: SearchStock[];
  cachedUsers: UserDetails[];
}

export const initialSearchState: SearchState = {
  stocks: null,
  users: null,
  cachedStocks: [],
  cachedUsers: [],
};
