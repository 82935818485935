  <form [formGroup]="searchForm"
        [ngClass]="{ expanded: expanded }"
        appClickOutside
        (clickOutside)="hideSearchForm()">
    <input
      #typeaheadHttp
      type="text"
      class="form-control"
      placeholder="Search"
      formControlName="searchField"
      (focus)="onFocus(true)"
      (blur)="onblur(false)"
      [class.is-invalid]="searchFailed"
      autocomplete="off"
    />

    <!--    TABS SECTION    -->
    <div
      class="tabs-wrapper"
      [ngClass]="{ visible: expanded, transparent: !expanded }"
    >
      <button
        #stocksTab
        class="tab"
        [ngClass]="{ active: activeType == searchDialogueType.STOCKS }"
        (click)="onChangeTab(searchDialogueType.STOCKS)"
        (focus)="onFocus(true)"
        (blur)="onFocus(false)"
      >
        Songs
      </button>

      <button
        #usersTab
        class="tab"
        [ngClass]="{ active: activeType == searchDialogueType.USERS }"
        (click)="onChangeTab(searchDialogueType.USERS)"
        (focus)="onFocus(true)"
        (blur)="onFocus(false)"
      >
        Users
      </button>
    </div>

    <div
      class="results-wrapper"
      [ngClass]="{ visible: expanded, transparent: !expanded }"
    >
      <!--   STOCK SECTION    -->
      <ng-container *ngIf="activeType == searchDialogueType.STOCKS">
        <ng-container
          *ngIf="
            !searchedStocks?.length &&
              (cachedSearchStocks$ | async)?.length &&
              cachedSearchStocks$ | async as cachedStocks
          "
          [ngTemplateOutlet]="stockItems"
          [ngTemplateOutletContext]="{
            stocks: cachedStocks,
            displayDeleteButton: true
          }"
        >
        </ng-container>

        <ng-container
          *ngIf="searchedStocks?.length"
          [ngTemplateOutlet]="stockItems"
          [ngTemplateOutletContext]="{
            stocks: searchedStocks,
            displayDeleteButton: false
          }"
        >
        </ng-container>

        <ng-container *ngIf="!(cachedSearchStocks$ | async)?.length && !searchedStocks?.length">
          <div class="empty-state stocks">
            <span aria-hidden="true">
              <picture>
                <img [src]="'assets/icons/empty/stock.svg'" alt="stock-icon" />
              </picture>
            </span>
            <span class="title">Find Some Popular Songs</span>
          </div>
        </ng-container>
      </ng-container>

      <!--   USERS SECTION    -->
      <ng-container *ngIf="activeType == searchDialogueType.USERS">
        <ng-container
          *ngIf="
            !searchedUsers?.length &&
              (cachedSearchUsers$ | async)?.length &&
              cachedSearchUsers$ | async as cachedUsers
          "
          [ngTemplateOutlet]="userItems"
          [ngTemplateOutletContext]="{
            users: cachedUsers,
            displayDeleteButton: true
          }"
        >
        </ng-container>

        <ng-container
          *ngIf="searchedUsers?.length"
          [ngTemplateOutlet]="userItems"
          [ngTemplateOutletContext]="{
            users: searchedUsers,
            displayDeleteButton: false
          }"
        >
        </ng-container>

        <ng-container
          *ngIf="!(cachedSearchUsers$ | async)?.length && !searchedUsers?.length"
        >
          <div class="empty-state users">
            <span aria-hidden="true">
              <picture>
                <img
                  [src]="'assets/icons/user/user_avatar_grey.svg'"
                  alt="user-icon"
                />
              </picture>
            </span>
            <span class="title">Find Some Great Traders</span>
          </div>
        </ng-container>
      </ng-container>

      <!-- TEMPLATES  -->
      <ng-template
        #stockItems
        let-stocks="stocks"
        let-displayDeleteButton="displayDeleteButton"
      >
        <div class="cache-header" *ngIf="displayDeleteButton">
          <span>Recent Searches</span>
          <button
            type="button"
            (click)="deleteAllCachedStocks()"
            (focus)="onFocus(true)"
            (blur)="onFocus(false)"
          >
            Clear All
          </button>
        </div>

        <div
          class="found found-stock"
          *ngFor="let stock of stocks"
          (click)="goToStock(stock)"
        >
          <picture>
            <img
              class="stock-logo"
              [src]="getIconForSong(stock.ticker).url"
              alt="logo"
              (error)="stockIconError($event)"
            />
          </picture>

          <div class="item-description">
            <div class="main-description">{{ stock.ticker | appReplaceSongTitle }}</div>
            <div class="additional-description">{{ stock.companyName }}</div>
          </div>

          <button
            *ngIf="displayDeleteButton"
            type="button"
            class="close"
            aria-label="Close"
            (click)="deleteCachedStock(stock)"
            (focus)="onFocus(true)"
            (blur)="onFocus(false)"
          >
            <span aria-hidden="true">
              <picture>
                <img [src]="'assets/icons/orders/grey_cancel.svg'" alt="cancel" />
              </picture>
            </span>
          </button>
        </div>
      </ng-template>

      <ng-template
        #userItems
        let-users="users"
        let-displayDeleteButton="displayDeleteButton"
      >
        <div class="cache-header" *ngIf="displayDeleteButton">
          <span>Recent Searches</span>
          <button
            type="button"
            (click)="deleteAllCachedUsers()"
            (focus)="onFocus(true)"
            (blur)="onFocus(false)"
          >
            Clear All
          </button>
        </div>

        <div
          class="found found-user"
          *ngFor="let user of users"
          (click)="goToProfile(user)"
        >
          <picture>
            <img
              class="user-avatar"
              [src]="getAvatarUrl(user.has_avatar, user.id)"
              alt="avatar"
              (error)="userIconError($event)"
            />
          </picture>

          <div class="item-description">
            <div class="main-description">
              {{ user.name }}

              <picture *ngIf="user.is_verified">
                <img
                  [src]="'assets/icons/profile/ico_medal.svg'"
                  alt="user-medal"
                />
              </picture>
            </div>
            <div class="additional-description">
              {{ user.city }} <span *ngIf="user.city && user.country">,</span>
              {{ user.country }}
            </div>
          </div>

          <button
            *ngIf="displayDeleteButton"
            type="button"
            class="close"
            aria-label="Close"
            (click)="deleteCachedUser(user)"
            (focus)="onFocus(true)"
            (blur)="onFocus(false)"
          >
            <span aria-hidden="true">
              <picture>
                <img [src]="'assets/icons/orders/grey_cancel.svg'" alt="cancel" />
              </picture>
            </span>
          </button>
        </div>
      </ng-template>
    </div>
  </form>

  <ng-container *ngIf="expandedStatus$ | async as data"><div>{{data}}</div></ng-container>
