import {createAction, props} from '@ngrx/store';

import {News} from '@shared/models/news/api/news.response.interface';

export enum NewsActions {
  LoadNews = '[RELATED-NEWS-WIDGET] Load news',
  LoadNewsSuccess = '[RELATED-NEWS-WIDGET] Load news success',
  LoadNewsFailure = '[RELATED-NEWS-WIDGET] Load news failure',
  UpdateNewsCurrentPageSuccess = '[RELATED-NEWS-WIDGET] Update news current page success',
  UpdateNews = '[RELATED-NEWS-WIDGET] Update news',
  UpdateLikeNews = '[RELATED-NEWS-WIDGET] Update like news'
}

export const loadNews = createAction(
  NewsActions.LoadNews
);

export const loadNewsSuccess = createAction(
  NewsActions.LoadNewsSuccess,
  props<{ news: News[] }>()
);

export const loadNewsFailure = createAction(
  NewsActions.LoadNewsFailure
);

export const updateNews = createAction(
  NewsActions.UpdateNews,
  props<{ id: number, likesCount: number }>()
);

export const updateLikeNews = createAction(
  NewsActions.UpdateLikeNews,
  props<{ id: number }>()
);
