import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  OnDestroy,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {EMPTY, Observable, of} from 'rxjs';

import {catchError, tap} from 'rxjs/operators';

import {AuthenticationService} from '@auth/services/authentication.service';
import {FormsErrorService} from '@auth/services/forms-error.service';
import {Authentication} from '@auth/shared/models/login.model';
import {validateCredentials} from '@auth/validators/badCredentialsvalidator';
import {PopupWrapperComponent} from '@shared/components/popup-wrapper/popup-wrapper/popup-wrapper.component';
import {ForgotPasswordComponent} from '@auth/components/forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  signInForm: FormGroup;
  isShowPassword = false;
  login$: Observable<any>;
  @ViewChild(PopupWrapperComponent, {static: false})
  popupWrapperComponent: PopupWrapperComponent;

  @ViewChild(ForgotPasswordComponent, {static: false})
  forgotPasswordComponent: ForgotPasswordComponent;
  @Output() openSignUp$ = new EventEmitter();

  constructor(private authService: AuthenticationService,
              private fb: FormBuilder,
              private formErrorService: FormsErrorService,
              private changeDetector: ChangeDetectorRef,
              private router: Router) {
  }

  ngOnInit() {
    this.initSignInForm();
  }

  dismissModal() {
    this.signInForm.reset();
  }

  ngOnDestroy(): void {
  }

  openModal() {
    this.popupWrapperComponent.openCentered();
  }

  close() {
    this.popupWrapperComponent.close();
  }

  forgotPassword() {
    this.close();
    this.forgotPasswordComponent.openModal();
  }

  signUp() {
    this.close();
    this.openSignUp$.emit();
  }

  togglePasswordVisibility() {
    this.isShowPassword = !this.isShowPassword;
  }

  ngAfterViewInit() {
    // tslint:disable-next-line:forin
    for (const controlsKey in this.signInForm.controls) {
      this.signInForm.controls[controlsKey].markAsTouched();
    }
  }

  onSubmitHandler() {
    const {userName, password} = this.signInForm.value;
    const user = new Authentication(userName, password);

    if (userName.length && password.length) {
      this.login$ = this.authService.login(user, this.popupWrapperComponent)
        .pipe(
          // tap(() => this.close()),
          catchError(error => {
            if (error && error.hasOwnProperty('message')) {
              this.setErrors(error);
            }

            return of(EMPTY);
          })
        );
    }
  }

  setErrors(error: any) {
    const {message} = error;
    const passwordErrorsControl = this.signInForm.get('password');
    const userNameErrorsControl = this.signInForm.get('userName');

    passwordErrorsControl.setErrors({
      backendError: {
        message
      }
    });

    userNameErrorsControl.setErrors({
      backendError: {
        message
      }
    });

    this.changeDetector.detectChanges();
  }

  getFieldErrorText(field: string, errorText: string): string {
    return this.formErrorService.getFieldErrorText(this.signInForm, field, errorText);
  }

  showFieldErrorText(field: string): boolean {
    return this.formErrorService.showFieldErrorText(this.signInForm, field);
  }

  addFieldErrorClass(field: string): boolean {
    return this.formErrorService.addFieldErrorClass(this.signInForm, field);
  }

  changeUserNameInput() {
  }

  private initSignInForm() {
    this.signInForm = this.fb.group(
      {
        userName: new FormControl('', {
          validators: [Validators.required]
        }),
        password: new FormControl('', {
          validators: [Validators.required, Validators.minLength(6)]
        })
      },
      {validators: validateCredentials}
    );
  }

}
