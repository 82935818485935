import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {Router} from '@angular/router';

import {EnvironmentService} from '@core/services/enviroment.service';
import {UserInfo} from '@shared/models/user/user.info.interface';
import {InviteData} from '@shared/models/user/user.invite.interface';

import {InviteFriendsComponent} from './../invite-friends/invite-friends.component';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit, OnChanges {
  @ViewChild(InviteFriendsComponent, {static: false})
  inviteFriendsComponent: InviteFriendsComponent;

  @Input() userInfo: UserInfo;
  @Input() inviteData: InviteData;
  @Output() logout$: EventEmitter<any> = new EventEmitter<any>();
  @Output() goToProfile$: EventEmitter<number> = new EventEmitter<number>();
  avatarUrl: string;

  constructor(private environmentService: EnvironmentService,
              private router: Router,
              private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userInfo && changes.userInfo.currentValue) {
      this.avatarUrl = changes.userInfo.currentValue.avatarUrl + '?' + Date.now();
    }
  }

  getIconUrl(name: string) {
    return this.environmentService.getIconUrl('header/' + name);
  }

  logout() {
    this.logout$.emit();
    this.router.navigate(['/auth/login']);
  }

  error(error) {
    error.target.src = this.getIconUrl('avatar@1x.svg');
    error.target.classList.add('no-icon');
  }

  goToProfile(userId: number) {
    this.goToProfile$.emit(userId);
  }

  openInviteFriends() {
    this.inviteFriendsComponent.openCentered();
  }
}
