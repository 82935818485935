export class TokenHelper {

  static getTokenFromLocalStorage(): string | null {
    return localStorage.getItem('access_token');
  }

  static saveTokenInLocalStorage(token: string): void {
    localStorage.setItem('access_token', token);
  }

  static removeTokenFromLocalStorage(): void {
    localStorage.removeItem('access_token');
  }
}
