import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderPage} from '@shared/models/orders/orders.widget.interface';
import {Pages} from '@shared/interfaces/page/page.interface';

@Component({
  selector: 'app-card-with-scroll',
  templateUrl: './card-with-scroll.component.html',
  styleUrls: ['./card-with-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardWithScrollComponent implements OnInit {
  @Input() title: string;
  @Input() isScrollPage: boolean;
  @Input() infiniteScrollThrottle: number;
  @Input() infiniteScrollUpDistance: number;
  @Input() infiniteScrollDistance: number;
  @Input() ordersPage: OrderPage | Pages;
  @Input() parentClass: string;

  @Output() scroll$ = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  onScroll(ordersPage: OrderPage) {
    this.scroll$.emit(ordersPage.current);
  }

}
