import {createAction, props} from '@ngrx/store';

export const nextPageListAction = createAction(
  '[List] Next Page',
  props<{ pagination: any }>()
);

export const nextPageListSuccessAction = createAction(
  '[List] Next Page success'
);

export const nextPageListFailureAction = createAction(
  '[List] Next Page failure'
);
