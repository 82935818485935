import {createAction, props} from '@ngrx/store';

import {ChartConvertData, ChartDataInterface} from '@shared/models/chart/chart.interface';
import {StockChartDataComponent} from '@shared/models/chart/stock/stock-chart-data-component.interface';

enum ChartActions {
  GetChartData = '[CHART] Get chart data',
  GetPortfolioChartDataSuccess = '[CHART] Get chart data success',
  SetPortfolioChartAvailablePeriodsSuccess = '[CHART] Set chart available periods success',
  SetPortfolioChartDataForTheEntirePeriodSuccess = '[CHART] Set chart data for the entire period success',
  GetStockChartDataSuccess = '[CHART] Get stock chart data success',
  UpdateSelectStockChartPeriodSuccess = '[CHART] Update select stock chart period success',
  UpdateSelectPortfolioChartPeriodSuccess = '[CHART] Update select portfolio chart period success',
}

export const getPortfolioChartDataSuccess = createAction(
  ChartActions.GetPortfolioChartDataSuccess,
  props<{ chartData: ChartConvertData }>()
);

export const setPortfolioChartDataForTheEntirePeriodSuccess = createAction(
  ChartActions.SetPortfolioChartDataForTheEntirePeriodSuccess,
  props<{ chartData: ChartConvertData }>()
);

export const setPortfolioChartAvailablePeriodsSuccess = createAction(
  ChartActions.SetPortfolioChartAvailablePeriodsSuccess,
  props<{ chartPeriods: ChartDataInterface[] }>()
);

export const getStockChartDataSuccess = createAction(
  ChartActions.GetStockChartDataSuccess,
  props<{ stockChartData: StockChartDataComponent }>()
);

export const updateSelectStockChartPeriodSuccess = createAction(
  ChartActions.UpdateSelectStockChartPeriodSuccess,
  props<{activePeriod: string, ticker: string}>()
);

export const updateSelectPortfolioChartPeriodSuccess = createAction(
  ChartActions.UpdateSelectPortfolioChartPeriodSuccess,
  props<{activePeriod: string}>()
);
