import {ActivityStockWithUrl} from '@shared/models/watchlist/watchlist.widget.interface';
import {Pages} from '@shared/interfaces/page/page.interface';

export interface WatchlistState {
  pages: Pages;
  watchlist: ActivityStockWithUrl[];
}

export const initialWatchlistState: WatchlistState = {
  pages: {
    current: 0,
    prev: null
  },
  watchlist: []
};
