import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from '@env/environment';

import {News, NewsResponse} from '@shared/models/news/api/news.response.interface';
import {LikeNews, LikeResponse} from '@shared/models/news/api/like.response.interface';
import {FeedTypes} from '@shared/enums/home/feed.types.enum';
import {newsConfig} from '@shared/config/news/news.config';
import {StockNewsResponse} from '@shared/models/news/api/stock-news.response.interface';
import {StockNews} from '@shared/models/news/stock-news.interface';

@Injectable({providedIn: 'root'})
export class NewsApiService {
  private readonly BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getNews(): Observable<News[]> {
    return this.http.get(this.BASE_URL + `/api/v3/community/feed.json?type=${FeedTypes.NEWS}` +
      `&watchlist=1` +
      `&positions=1` +
      `&limit=${newsConfig.limit}`
    )
      .pipe(
        map((response: NewsResponse) => response.data),
      );
  }

  likeNews(newsId: number): Observable<LikeNews> {
    const options = {
      opinion: 'like'
    };

    return this.http.put(this.BASE_URL + `/api/v3/feed/${newsId}/opinion.json`, options)
      .pipe(
        map((response: LikeResponse) => response.data),
      );
  }

  getStockNew(ticker: string, offset: number): Observable<StockNews[]> {
    const options = {
      offset,
      limit: 10,
      ticker
    };

    return this.http.post(this.BASE_URL + `/api/v2/stock/news`, options).pipe(
      map((response: StockNewsResponse) => response.body.news)
    );
  }

}
