import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {Store} from '@ngrx/store';

import {TrendingStocksService} from '../../../modules/discover/shares/services/trending-stocks/trending-stocks.service';
import {
  nextPageTrendingStockAction, nextPageTrendingStockFailureAction,
  nextPageTrendingStockSuccessAction
} from '@store/actions/trending-stocks/trending-stocks.actions';

@Injectable()
export class TrendingStocksEffect {
  constructor(private actions: Actions,
              private trendingStocksService: TrendingStocksService,
              private store: Store) {
  }

  loadTrendingStocksNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(nextPageTrendingStockAction),
      switchMap(({offset, isCanLoad}) => {
        if (!isCanLoad) {
          this.store.dispatch(nextPageTrendingStockFailureAction());
          return EMPTY;
        }

        return this.trendingStocksService.loadStockTrendingFromApi(offset + 1)
          .pipe(
            map(data => nextPageTrendingStockSuccessAction()),
            catchError(err => {
              this.store.dispatch(nextPageTrendingStockFailureAction());
              return EMPTY;
            })
          );
      })
    )
  );

}
