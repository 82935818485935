import {createSelector} from '@ngrx/store';

import {featurePortfolioSelector} from '../portfolio.feature.selector';

export const portfolioWidgetSelector = createSelector(
  featurePortfolioSelector,
  (state) => state.portfolioWidget
);

export const portfolioIdWidgetSelector = createSelector(
  featurePortfolioSelector,
  (state) => !!state.portfolioWidget?.values ? state.portfolioWidget.values : null
);
