import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {AuthGuard} from '@core/guards/auth.guard';
import {routerOptions} from './landing/shared/config/router-options';
import {LandingService} from '@shared/resolvers/landing.resolve';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    resolve: {
      employees: LandingService
    }
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'discover',
    loadChildren: () => import('./modules/discover/discover.module').then(m => m.DiscoverModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'portfolio',
    loadChildren: () => import('./modules/portfolio/portfolio.module').then(m => m.PortfolioModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'symbol/:ticker',
    loadChildren: () => import('./modules/symbol/symbol.module').then(m => m.SymbolModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register/confirm/:token', // Define a parameter ":token"
    loadChildren: () => import('./landing/pages/landing/register-confirm/register-confirm.module').then(m => m.RegisterConfirmModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules,
      ...routerOptions
    }
  )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
