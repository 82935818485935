import {createAction, props} from '@ngrx/store';

import {ProfileNav} from '../../../modules/profile/shares/enums/profile-nav.enum';
import {ProfileForComponent} from '@shared/models/profile/profile-for-component.interface';
import {ProfilePortfolioType} from '../../../modules/profile/shares/enums/profile-portfolio-type.enum';
import {PortfolioForProfile} from '@shared/models/portfolio/portfolio-for-profile.interfce';
import {ProfileAccountHolder} from '@shared/models/profile/profile-account-holder.interface';
import {AccountHolderInfoForServer} from '@shared/models/profile/account-holder-info-for-server.interface';
import {ConnectionsNav} from '../../../modules/profile/profile-body/components/enums/connections-nav.enum';
import {UserInfo} from '@shared/models/user/user.info.interface';
import {FeedImageData} from '../../../modules/posts/posts-block/share/interfaces/feed-image-response.interface';

enum ProfileActions {
  UpdateTab = '[Profile] Update tab',
  UpdateProfileInfoSuccess = '[Profile] Update Profile Info Success',
  UpdateProfilePortfolioType = '[Profile] Update profiles portfolio type',
  UpdateSelectedPortfolio = '[Profile] Update Selected Portfolio',
  SaveProfileData = '[Profile-API] Save profile data',
  SaveProfileDataSuccess = '[Profile-API] Save profile data success',
  SaveProfileDataFailure = '[Profile-API] Save profile data failure',
  UploadAvatar = '[Profile-API] Upload avatar',
  UploadAvatarSuccess = '[Profile-API] Upload avatar success',
  UploadAvatarFailure = '[Profile-API] Upload avatar failure',
  UploadBackgroundImage = '[Profile-API] Upload background image',
  UploadBackgroundImageSuccess = '[Profile-API] Upload background image success',
  UploadBackgroundImageFailure = '[Profile-API] Upload background image failure',
  GetAccountHolder = '[Profile-Account-Holder-API] Get account holder',
  GetAccountHolderSuccess = '[Profile-Account-Holder-API] Get account holder success',
  GetAccountHolderFailure = '[Profile-Account-Holder-API] Get account holder failure',
  UpdateSettingsAccountHolder = '[Profile-Account-Holder-API] Update settings account holder',
  UpdateSettingsAccountHolderSuccess = '[Profile-Account-Holder-API] Update settings account holder success',
  UpdateSettingsAccountHolderFailure = '[Profile-Account-Holder-API] Update settings account holder failure',
  SendRequestToAddFriend = '[Profile-API] send request to add friend',
  SendRequestToAddFriendSuccess = '[Profile-API] send request to add friend success',
  SendRequestToAddFriendFailure = '[Profile-API] send request to add friend failure',
  AcceptRequestToFriend = '[Profile-API] accept request to friend',
  AcceptRequestToFriendSuccess = '[Profile-API] accept request to friend success',
  AcceptRequestToFriendFailure = '[Profile-API] accept request to friend failure',
  RejectRequestToFriend = '[Profile-API] Reject request to friend',
  RejectRequestToFriendSuccess = '[Profile-API] request to friend success',
  RejectRequestToFriendFailure = '[Profile-API] request to friend failure',
  SendRequestToUnfriend = '[Profile-API] send Request To Unfriend',
  SendRequestToUnfriendSuccess = '[Profile-API] send Request To Unfriend success',
  SendRequestToUnfriendFailure = '[Profile-API] send Request To Unfriend failure',
  SendRequestToFollowing = '[Profile-API] Send Request To Following',
  SendRequestToFollowingSuccess = '[Profile-API] Send Request To Following success',
  SendRequestToFollowingFailure = '[Profile-API] Send Request To Following failure',
  SendRequestToUnfollowing = '[Profile-API] Send Request To Unfollowing',
  SendRequestToUnfollowingSuccess = '[Profile-API] Send Request To Unfollowing success',
  SendRequestToUnfollowingFailure = '[Profile-API] Send Request To Unfollowing failure',
  ProfileDestroy = '[Profile] profile destroy',
  UpdateProfileIdForOtherUserByTypeBrokerage = '[Profile] Update Profile id for other user by type of Brokerage'
}

export const updateTabAction = createAction(
  ProfileActions.UpdateTab,
  props<{newTab: ProfileNav}>()
);

export const updateConnectionsTabAction = createAction(
  '[Connection-tab]: Update tab',
  props<{newTab: ConnectionsNav}>()
);

export const updateProfileInfoSuccess = createAction(
  ProfileActions.UpdateProfileInfoSuccess,
  props<{profileInfo: ProfileForComponent}>()
);

export const updateProfilesPortfolioType = createAction(
  ProfileActions.UpdateProfilePortfolioType,
  props<{portfolioType: ProfilePortfolioType}>()
);

export const updateSelectedPortfolio = createAction(
  ProfileActions.UpdateSelectedPortfolio,
  props<{portfolio: PortfolioForProfile}>()
);

export const saveProfileData = createAction(
  ProfileActions.SaveProfileData,
  props<{profileData: any}>()
);

export const saveProfileDataSuccess = createAction(
  ProfileActions.SaveProfileDataSuccess
);

export const saveProfileDataFailure = createAction(
  ProfileActions.SaveProfileDataFailure
);

/**
 * Upload Avatar
 */

export const uploadAvatar = createAction(
  ProfileActions.UploadAvatar,
  props<{file: string | File | Blob, userInfo: UserInfo}>()
);

export const uploadAvatarSuccess = createAction(
  ProfileActions.UploadAvatarSuccess,
  props<{avatarUrl: string}>()
);

export const uploadAvatarFailure = createAction(
  ProfileActions.UploadAvatarFailure,
  props<{err: any}>()
);

/**
 * Upload Background image
 */

export const uploadBackgroundImage = createAction(
  ProfileActions.UploadBackgroundImage,
  props<{file: string | File | Blob}>()
);

export const uploadBackgroundImageSuccess = createAction(
  ProfileActions.UploadBackgroundImageSuccess
);

export const uploadBackgroundImageFailure = createAction(
  ProfileActions.UploadBackgroundImageFailure,
  props<{err: any}>()
);

/**
 * Upload feed image
 */

export const uploadFeedImage = createAction(
  'Upload feed image',
  props<{file: string | File | Blob}>()
);

export const uploadFeedImageSuccess = createAction(
  'Upload feed image is success',
  props<{feedResponse: FeedImageData}>()
);

export const uploadFeedImageFailure = createAction(
  'Upload feed image is failure',
  props<{err: any}>()
);

/**
 * Account holder
 */
export const getAccountHolder = createAction(
  ProfileActions.GetAccountHolder
);

export const getAccountHolderFailure = createAction(
  ProfileActions.GetAccountHolderFailure,
  props<{err: any}>()
);

export const getAccountHolderSuccess = createAction(
  ProfileActions.GetAccountHolderSuccess,
  props<{accountHolder: ProfileAccountHolder}>()
);

/**
 * Update Settings Account Holder (Profile edit modal)
 */
export const updateSettingsAccountHolder = createAction(
  ProfileActions.UpdateSettingsAccountHolder,
  props<{settings: AccountHolderInfoForServer, fileForAvatar: File, fileBackgroundImage: File}>()
);

export const updateSettingsAccountHolderSuccess = createAction(
  ProfileActions.UpdateSettingsAccountHolderSuccess,
  props<{accountHolder: ProfileAccountHolder}>()
);

export const updateSettingsAccountHolderFailure = createAction(
  ProfileActions.UpdateSettingsAccountHolderFailure,
  props<{err: any}>()
);

/**
 * send Request To Add Friend
 */
export const sendRequestToAddFriend = createAction(
  ProfileActions.SendRequestToAddFriend,
  props<{userId: number, friendRequestId: number}>()
);

export const sendRequestToAddFriendSuccess = createAction(
  ProfileActions.SendRequestToAddFriendSuccess,
  props<{profileId: number, friendRequestId: number}>()
);

export const sendRequestToAddFriendFailure = createAction(
  ProfileActions.SendRequestToAddFriendFailure,
  props<{err: any}>()
);

/**
 * accept Request To Friend
 */
export const acceptRequestToFriend = createAction(
  ProfileActions.AcceptRequestToFriend,
  props<{userId: number}>()
);

export const acceptRequestToFriendSuccess = createAction(
  ProfileActions.AcceptRequestToFriendSuccess,
  props<{profileId: number}>()
);

export const acceptRequestToFriendFailure = createAction(
  ProfileActions.AcceptRequestToFriendFailure,
  props<{err: any}>()
);

/**
 * reject Request To Friend
 */
export const rejectRequestToFriend = createAction(
  ProfileActions.RejectRequestToFriend,
  props<{userId: number}>()
);

export const rejectRequestToFriendSuccess = createAction(
  ProfileActions.RejectRequestToFriendSuccess,
  props<{profileId: number}>()
);

export const rejectRequestToFriendFailure = createAction(
  ProfileActions.RejectRequestToFriendFailure,
  props<{err: any}>()
);

/**
 * send Request To Unfriend
 */
export const sendRequestToUnfriend = createAction(
  ProfileActions.SendRequestToUnfriend,
  props<{userId: number}>()
);

export const sendRequestToUnfriendSuccess = createAction(
  ProfileActions.SendRequestToUnfriendSuccess,
  props<{profileId: number}>()
);

export const sendRequestToUnfriendFailure = createAction(
  ProfileActions.SendRequestToUnfriendFailure,
  props<{err: any}>()
);

/**
 * sendRequestToFollowing
 */
export const sendRequestToFollowing = createAction(
  ProfileActions.SendRequestToFollowing,
  props<{userId: number}>()
);

export const sendRequestToFollowingSuccess = createAction(
  ProfileActions.SendRequestToFollowingSuccess,
  props<{profileId: number}>()
);

export const sendRequestToFollowingFailure = createAction(
  ProfileActions.SendRequestToFollowingFailure,
  props<{err: any}>()
);

/**
 * send Request To Unfollowing
 */
export const sendRequestToUnfollowing = createAction(
  ProfileActions.SendRequestToUnfollowing,
  props<{userId: number}>()
);

export const sendRequestToUnfollowingSuccess = createAction(
  ProfileActions.SendRequestToUnfollowingSuccess,
  props<{profileId: number}>()
);

export const sendRequestToUnfollowingFailure = createAction(
  ProfileActions.SendRequestToUnfollowingFailure,
  props<{err: any}>()
);

export const profileDestroy = createAction(
  ProfileActions.ProfileDestroy
);

/**
 * Update Profile Id For Other User By Type Brokerage
 */
export const updateProfileIdForOtherUserByTypeBrokerage = createAction(
  ProfileActions.UpdateProfileIdForOtherUserByTypeBrokerage,
  props<{profileId: number | null}>()
)

export const userIdAction = createAction(
  '[Profile]: change user id',
  props<{userId: number | null}>()
)

export const resetUserIdAction = createAction(
  '[Profile]: reset user id',
)
