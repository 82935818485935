import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input, ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';

import { avatarSizes } from '@core/config/avatar.config';
import { EnvironmentService } from '@core/services/enviroment.service';

import { UserNotificationItem } from '@shared/models/user/user.notification.item.interface';
import { Ident } from '@shared/enums/user/user.notification.ident.enum';
import { Store } from '@ngrx/store';
import { setUserNotificationRead } from '@store/actions/user.actions';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {acceptRequestToFriend, rejectRequestToFriend} from "@store/actions/profile/profile.actions";
import {FeedService} from '@shared/services/feed/feed.service';

@Component({
  selector: 'app-user-notification-item',
  templateUrl: './user-notification-item.component.html',
  styleUrls: ['./user-notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotificationItemComponent implements OnInit {
  @Input() notification: UserNotificationItem;
  @Input() dropdown: NgbDropdown;

  ident = Ident;
  displayActions: boolean = true;

  constructor(
    private router: Router,
    private environmentService: EnvironmentService,
    private store: Store,
    private feedService: FeedService
  ) {}

  ngOnInit(): void {}

  markNotificationAsRead(): void {
    this.store.dispatch(setUserNotificationRead({notificationId: this.notification.id}));
  }

  goToProfile(userId: number) {
    this.router.navigateByUrl('profile/' + userId);
    this.dropdown.close();
  }

  goToStock(stockSymbol: string) {
    this.router.navigateByUrl('symbol/' + stockSymbol);
    this.dropdown.close();
  }

  goToHome(feedId: number) {
    this.router.navigateByUrl('home/');
    this.feedService.setSingleFeedIdForHome(feedId);

    this.dropdown.close();
  }

  getAvatarUrl(hasAvatar: boolean, userId: number): string {
    if (hasAvatar) {
      return this.environmentService.getAvatarUrl(userId, avatarSizes.middle);
    }

    return this.getIconUrl('user', 'user_avatar_small.svg');
  }

  getLogorUrl(stockSymbol: string): string {
    return this.environmentService.getStockImage(stockSymbol).url;
  }

  getIconUrl(prefix: string, name: string) {
    return this.environmentService.getIconUrl(`${prefix}/${name}`);
  }

  userIconError(error) {
    error.target.src = this.getIconUrl('user', 'user_avatar_small.svg');
    error.target.classList.add('no-icon');
  }

  stockIconError(error) {
    error.target.src = this.getIconUrl('empty', 'stock.svg');
    error.target.classList.add('no-icon');
  }

  confirmFriendRequest(): void {
    this.store.dispatch(acceptRequestToFriend({userId: this.notification.friendRequestId}));
  }

  deleteFriendRequest(): void {
    this.store.dispatch(rejectRequestToFriend({userId: this.notification.friendRequestId}));
  }
}
