import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import {NgbModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';

import {ButtonComponent} from '@shared/components/button/button.component';
import {CardWithScrollComponent} from './components/card-with-scroll/card-with-scroll.component';
import {CardComponent} from './components/card/card.component';
import {DropMenuComponent} from './components/drop-menu/drop-menu.component';
import {PopupWrapperComponent} from './components/popup-wrapper/popup-wrapper/popup-wrapper.component';
import {SearchUsersListComponent} from '@shared/components/search-users-list/search-users-list.component';
import { TooltipCopyComponent } from './components/tooltip-copy/tooltip-copy.component';
import {RelatedNewsWidgetComponent} from '@shared/components/related-news-widget/related-news-widget.component';
import {PipesModule} from '@shared/pipes/pipes.module';
import { SearchStocksListComponent } from './components/search-stocks-list/search-stocks-list.component';
import {ActionsMenuComponent} from '@shared/components/actions-menu/actions-menu.component';
import {ReplaceSongTitlePipe} from '@shared/pipes/replace-song-title.pipe';

@NgModule({
  imports: [
    CommonModule,
    InfiniteScrollModule,
    NgbModule,
    NgbTypeaheadModule,
    PipesModule,
  ],
  declarations: [
    ButtonComponent,
    CardWithScrollComponent,
    CardComponent,
    DropMenuComponent,
    PopupWrapperComponent,
    SearchUsersListComponent,
    TooltipCopyComponent,
    RelatedNewsWidgetComponent,
    SearchStocksListComponent,
    ActionsMenuComponent,
    ReplaceSongTitlePipe
  ],
  exports: [
    ButtonComponent,
    CardWithScrollComponent,
    CardComponent,
    DropMenuComponent,
    PopupWrapperComponent,
    SearchUsersListComponent,
    TooltipCopyComponent,
    RelatedNewsWidgetComponent,
    SearchStocksListComponent,
    ActionsMenuComponent,
    ReplaceSongTitlePipe
  ]
})
export class ShareModule {

}
