<div class="header">
  <div class="logo-search-wrapper">
    <div class="logo-wrapper">
      <a class="image-link" [routerLink]="'.'" routerLink="">
        <picture>
          <img class="logo" [src]="'assets/icons/landing/logo/logo.svg'" alt="logo" />
        </picture>
      </a>
    </div>

    <app-search [isDisabled]="false"></app-search>
  </div>

  <div class="navigate-wrapper">
    <ul class="navigate-list" *ngIf="activeRouter$ | async as activeRouter">
      <li
        class="navigate-item"
        (click)="isDisable('/home') ? stopNavigate($event) : goToRoute('/home')"
      >
        <a
          class="navigate-link"
          [class.is-active]="isActiveLink('/home', activeRouter)"
          [class.disabled]="isDisable('/home')"
        >
          <picture>
            <img
              class="site-header__home"
              [src]="
                isActiveLink('/home', activeRouter)
                  ? getIconUrl('home/home_green.svg')
                  : getIconUrl('home/home_grey.svg')
              "
              alt="home"
            />
          </picture>
        </a>
      </li>

      <li class="navigate-item" (click)="goToRoute('/discover')">
        <a
          class="navigate-link"
          [class.is-active]="isActiveLink('/discover', activeRouter)"
        >
          <picture>
            <img
              class="site-header__discover"
              [src]="
                isActiveLink('/discover', activeRouter)
                  ? getIconUrl('discover/green_discover@1x.svg')
                  : getIconUrl('discover/grey_discover@1x.svg')
              "
              alt="discover"
            />
          </picture>
        </a>
      </li>

      <li
        class="navigate-item"
        (click)="
          isDisable('/portfolio')
            ? stopNavigate($event)
            : goToRoute('/portfolio')
        "
      >
        <a
          class="navigate-link"
          [class.is-active]="isActiveLink('/portfolio', activeRouter)"
          [class.disabled]="isDisable('/portfolio')"
        >
          <picture>
            <img
              class="site-header__portfolio"
              [src]="
                isActiveLink('/portfolio', activeRouter)
                  ? getIconUrl('portfolio/green_portfolio@1x.svg')
                  : getIconUrl('portfolio/grey_portfolio@1x.svg')
              "
              alt="portfolio"
            />
          </picture>
        </a>
      </li>
    </ul>
  </div>

  <div class="user-info-wrapper">
    <ng-container>
      <app-user-notifications-list
        [userNotification]="userNotification$ | async"
        [userNotificationList]="userNotificationList$ | async"
        class="user-notification"
      >
        <a
          class="navigate-link f-c-333 registration"
          [routerLink]="'/auth/registration'"
        >
          Sign up
        </a>
      </app-user-notifications-list>

      <app-user-info
        [userInfo]="userInfo$ | async"
        [inviteData]="inviteData$ | async"
        (logout$)="logout()"
        (goToProfile$)="goToProfile($event)"
        [class.justify-content-center]="!(userInfo$ | async)"
        class="user-info"
      >
        <a class="navigate-link f-c-333 login" [routerLink]="'/auth/login'">
          Login
        </a>
      </app-user-info>

    </ng-container>
  </div>
</div>

<ng-container *ngIf="loadUserNotificationFromApi$ | async"></ng-container>
<ng-container *ngIf="loadUserNotificationListFromApi$ | async"></ng-container>
<ng-container *ngIf="loadUserData$ | async"></ng-container>
