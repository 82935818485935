import * as moment from 'moment';

import {Time} from 'lightweight-charts';

import {StockChartDataApi} from '@shared/models/chart/stock/stock-chart-data-api.interface';
import {StockChartData} from '@shared/models/chart/stock/stock-chart-data.interface';
import {StockChartDataComponent} from '@shared/models/chart/stock/stock-chart-data-component.interface';
import {StockChartPeriods} from '@shared/components/chart/shared/enums/stock-chart-periods.enum';
import {PortfolioChartPeriods} from '@shared/components/chart/shared/enums/portfolio-chart.periods.enum';

export class StockChartHelper {

  static getChartData(chartDataApi: StockChartDataApi[],
                      activeLoadedPeriod: StockChartPeriods | PortfolioChartPeriods): StockChartDataComponent {
    const chartData: StockChartData[] = [];

    chartDataApi.forEach(el => {
      const time = +el.timestamp as Time;

      chartData.push({time, value: +el.value});
    });

    return {
      data: chartData,
      activePeriod: activeLoadedPeriod
    };
  }

  static getTimestamp(activePeriod: StockChartPeriods): number {
    switch (activePeriod) {
      case StockChartPeriods.MAX:
        return 0;
      case StockChartPeriods.DAY:
        return moment().subtract(1, 'days').unix();
      case StockChartPeriods.WEEK:
        return moment().subtract(7, 'days').unix();
      case StockChartPeriods.MONTH:
        return moment().subtract(1, 'months').unix();
      case StockChartPeriods.THREE_MONTH:
        return moment().subtract(3, 'months').unix();
      case StockChartPeriods.YEAR:
        return moment().subtract(1, 'years').unix();
    }
  }

}
