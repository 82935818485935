import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormsErrorService {
  getFieldErrorText(form: FormGroup, field: string, errorText: string): string {
    const formField = this._getFormField(form, field);

    return formField.hasError('backendError') &&
    formField.getError('backendError').message ? formField.getError('backendError')?.message : errorText;
  }

  showFieldErrorText(form: FormGroup, field: string): boolean {
    const formField = this._getFormField(form, field);

    return formField.invalid && this._dirtyField(formField);
  }

  addFieldErrorClass(form: FormGroup, field: string): boolean {
    const formField = this._getFormField(form, field);

    return (
      (formField.invalid || formField.hasError('backendError')) && this._dirtyField(formField)
    );
  }

  private _dirtyField(formField: AbstractControl) {
    return formField.dirty || formField.touched;
  }

  private _getFormField(form: FormGroup, field: string) {
    return form.get(field);
  }
}
