import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '@env/environment';
import {FeedPost} from '@shared/models/feed/feed-post.interface';
import {RequestParamsFeed} from '@shared/models/feed/request/request-params-feed.interface';
import {FeedRequestForHome} from '@shared/models/feed/request/feed-request-for-home.interface';
import {CreatePost} from '@shared/models/feed/create-post/create-post.interface';
import {CreateFeedResponse, CreateFeedSuccess} from '@shared/models/feed/create-feed-response.interface';
import {FeedsComment, FeedsCommentsResponse} from '@shared/models/feed/feeds-comments-response.interface';
import {LikeResponse} from '@shared/models/news/api/like.response.interface';
import {
  FeedImageData,
  FeedImageResponseInterface
} from '../../../../modules/posts/posts-block/share/interfaces/feed-image-response.interface';

export interface UrlMeta {
  header: {
    success: boolean;
    status: number;
    function: string;
    version: number;
    time: number;
  },
  body: {
    url: string;
    title: string;
    description: string;
    thumb: string;
  }
}

@Injectable({providedIn: 'root'})
export class FeedApiService {
  private readonly BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getFeedMetadata(): Observable<any> {
    return this.http.get(this.BASE_URL + `/api/v3/feed/link/meta.json `).pipe(
      map(data => data)
    );
  }

  getMultipleFeedEntries(params: RequestParamsFeed | FeedRequestForHome, userId: number | null): Observable<FeedPost[]> {
    let query = Object.keys(params).map(key => `${key}=` + params[key] + '').join('&');

    if (userId) {
      query += '&userId=' + userId;
    }

    return this.http.get(this.BASE_URL + `/api/v4/community/feed.json?` + query).pipe(
      map((response: { data: FeedPost[] }) => response.data)
    );
  }

  getCommentsForFeed(feedId: number, pageOffset = 0, pageLimit = 10): Observable<FeedsComment[]> {
    return this.http
      .get(this.BASE_URL + `/api/v3/feed/${feedId}/comments.json?page[offset]=${pageOffset}&page[limit]=${pageLimit}`)
      .pipe(
        map((response: FeedsCommentsResponse) => response.data)
      )
  }

  createCommentForFeed(feedId: number, content: string): Observable<number> {
    return this.http
      .post(this.BASE_URL + `/api/v3/feed/${feedId}/comments.json?`, {content})
      .pipe(
        map((response: { data: { eid: number } }) => response.data.eid)
      )
  }

  getCommentForFeedsComment(feedId: number, commentId: number): Observable<FeedsComment[]> {
    return this.http
      .get(this.BASE_URL + `/api/v3/feed/${feedId}/comments/${commentId}.json`)
      .pipe(
        map((response: FeedsCommentsResponse) => response.data)
      )
  }

  likeComment(commentId: number): Observable<LikeResponse> {
    const options = {
      opinion: 'like'
    };

    return this.http.put(this.BASE_URL + `/api/v3/feed/comments/${commentId}/opinion.json`, options)
      .pipe(
        map((response: LikeResponse) => response),
      );
  }

  getSingleFeed(feedId: number): Observable<FeedPost> {
    let params = new HttpParams();
    params.append('feedId', feedId.toString());

    return this.http.get(this.BASE_URL + `/api/v3/community/single/feed.json?feedId=${feedId}`)
      .pipe(map((response: FeedPost) => response))
  }

  getSingleFeedEntries(): Observable<any> {
    return this.http.get(this.BASE_URL + `/api/v4/community/single/feed.json`);
  }

  deleteFeed(feedId: number): Observable<any> {
    const httpParams = new HttpParams().set('id', `${feedId}`);
    const options = {params: httpParams};

    return this.http.delete(this.BASE_URL + `/api/v3/feed.json`, options);
  }

  editFeed(feedId: number): Observable<any> {
    const httpParams = new HttpParams().set('id', `${feedId}`);
    const options = {params: httpParams};

    return this.http.put(this.BASE_URL + `/api/v3/feed.json`, options);
  }

  createPost(data: CreatePost): Observable<CreateFeedSuccess> {
    return this.http.post(this.BASE_URL + `/api/v3/feed.json`, data).pipe(
      map((response: CreateFeedResponse) => response.data)
    );
  }

  uploadFeedImage(image: string | File | Blob): Observable<FeedImageData> {
    const formData = new FormData();

    formData.append('image', image);
    formData.append('type', 'cover');

    const httpOptions = {
      headers: new HttpHeaders().delete('Content-Type')
    };

    return this.http.post(`${environment.apiUrl}/api/v3/feed/image.json`,
      formData,
      httpOptions
    ).pipe(
      map((response: FeedImageResponseInterface) => response.data)
    );
  }

  createUrlPost(url: string): Observable<UrlMeta> {
    return this.http.post(this.BASE_URL + `/api/v2/feed/action/url-meta/get`, {url}).pipe(
      map((response: UrlMeta) => response)
    );
  }
}
