<div class="row">
  <div class="col text-right">
    <div ngbDropdown class="d-inline-block drop-down-content" [placement]="['bottom-right', 'top-right']">
      <div class="dropdown" [attr.id]="dropDownId" ngbDropdownToggle>
        <div class="point-wrapper" *ngIf="isShowPointers">
          <div class="point"></div>
          <div class="point"></div>
          <div class="point"></div>
        </div>

        <div *ngIf="isShowText" [ngStyle]="stylesForText">{{text}}</div>
      </div>

      <div ngbDropdownMenu [attr.aria-labelledby]="dropDownId" [className]="dropDownId">
        <div class="content-wrapper">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
