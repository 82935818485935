import {Action, createReducer, on} from '@ngrx/store';

import {initialPortfolioState, PortfolioState} from '../../state/portfolio/portfolio.state';
import {
  getPortfolioValuesSuccess,
  loadPositionDataSuccess,
  updatePortfoliosListSuccess,
  updatePositionDataSuccess
} from '../../actions/portfolio/portfolio.actions';
import {userLogout} from '../../actions/user.actions';
import {loadNewsSuccess, updateLikeNews, updateNews} from '../../actions/news/news.actions';
import {resetPositionListData, selectPositionForDetails} from '../../actions/position/position.actions';

const reducer = createReducer(
  initialPortfolioState,
  on(getPortfolioValuesSuccess, (state, {values}) => {
    return {
      ...state,
      portfolioWidget: {
        ...state.portfolioWidget,
        values: {
          ...values
        }
      }
    };
  }),


  on(loadPositionDataSuccess, (state, {positions}) => {
    return {
      ...state,
      positionsWidget: {
        ...state.positionsWidget,
        positions: [
          ...positions
        ]
      }
    };
  }),
  on(updatePositionDataSuccess, (state, {positions}) => {
    return {
      ...state,
      positionsWidget: {
        ...state.positionsWidget,
        positions: [
          ...positions
        ]
      }
    };
  }),
  on(userLogout, (state) => {
    return {
      ...initialPortfolioState
    };
  }),
  on(loadNewsSuccess, (state, {news}) => {
    return {
      ...state,
      relatedNewsWidget: {
        ...state.relatedNewsWidget,
        news: [...news]
      }
    };
  }),
  on(updateNews, (state, {id, likesCount}) => {
    return {
      ...state,
      relatedNewsWidget: {
        ...state.relatedNewsWidget,
        news: state.relatedNewsWidget.news.map(oldNews => {
          if (oldNews.id === id) {
            return {
              ...oldNews,
              isLikedByFetcher: !oldNews.isLikedByFetcher,
              likesCount
            }
          }

          return oldNews;
        })
      }
    };
  }),
  on(updateLikeNews, (state, {id}) => {
    return {
      ...state,
      relatedNewsWidget: {
        ...state.relatedNewsWidget,
        news: state.relatedNewsWidget.news.map(oldNews => {
          if (oldNews.id === id) {
            return {
              ...oldNews,
              isLikedByFetcher: !oldNews.isLikedByFetcher
            }
          }

          return oldNews;
        })
      }
    };
  }),
  on(selectPositionForDetails, (state, {ticker}) => {
    return {
      ...state,
      positionsWidget: {
        ...state.positionsWidget,
        selectTicker: ticker
      }
    };
  }),
  on(resetPositionListData, (state) => {
    return {
      ...state,
      positionsWidget: null
    };
  }),
  on(updatePortfoliosListSuccess, (state, {portfoliosList}) => {
    return {
      ...state,
      portfoliosList
    };
  }),
  on(userLogout, state => {
    return {
      ...initialPortfolioState
    };
  })
);

export function portfolioReducer(state: PortfolioState, action: Action) {
  return reducer(state, action);
}
