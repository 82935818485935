import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';

import {Observable, of} from 'rxjs';

import {TokenService} from '@core/jwt/services/token.service';
import {GuardHelper} from '@core/helpers/guard.helper';

@Injectable({providedIn: 'root'})
export class LandingService implements Resolve<any> {
  constructor(private tokenService: TokenService,
              private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const token = this.tokenService.currentTokenValue();

    if (GuardHelper.isToken(token)) {
      this.router.navigateByUrl('/home');
    }

    return of([]);
  }

}
