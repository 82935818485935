import {createFeatureSelector, createSelector} from '@ngrx/store';

import {ChartState} from '../../state/chart/chart.state';

const featureChartSelector = createFeatureSelector<ChartState>('chart');

export const chartDataSelector = createSelector(
  featureChartSelector,
  (state) => (state.portfolioChartData?.data && state.portfolioChartData?.lineChartLabels) ?
    {
      chartData: state.portfolioChartData.data,
      lineChartLabels: state.portfolioChartData.lineChartLabels
    }
    : null
);

export const chartPeriodsWithActiveSelector = createSelector(
  featureChartSelector,
  (state) => (state.portfolioChartData?.chartPeriods && state.portfolioChartData?.activePeriod) ?
    {
      chartPeriods: state.portfolioChartData.chartPeriods,
      activePeriod: state.portfolioChartData.activePeriod
    }
    : null
);

export const stockChartDataSelector = createSelector(
  featureChartSelector,
  (state) => (state.stockChartData?.data && state.stockChartData?.activePeriod) ?
    {
      data: state.stockChartData.data,
      activePeriod: state.stockChartData.activePeriod
    } :
    null
);

export const stockActiveChartSelector = createSelector(
  featureChartSelector,
  (state) => state.stockChartData?.activePeriod
);

export const portfolioActiveChartPeriodSelector = createSelector(
  featureChartSelector,
  (state) => state.portfolioChartData?.activePeriod
);
