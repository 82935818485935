import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '@env/environment';
import {Gainer} from '../../../interfaces/gainers/gainers.interface';

@Injectable({providedIn: 'root'})
export class GainersApiService {
  private readonly BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  loadGainersFromApi(offset: number = 0): Observable<Gainer[]> {
    return this.http
      .get(this.BASE_URL + `/api/v2/stock/gainers/all/list?offset=${offset}&limit=${7}`)
      .pipe(map((response: any) => response.body.stock.gainers.all.length ? response.body.stock.gainers.all : []));
  }
}
