import {NgModule} from '@angular/core';

import {ArraySortPipe} from '@shared/pipes/array-sort.pipe';
import {FilterPipe} from '@shared/pipes/filter.pipe';
import {NegativeNumberPipe} from '@shared/pipes/negative-number.pipe';
import {NumberRoundUpPipe} from '@shared/pipes/number-round-up.pipe';
import {NumberWithoutRoundPipe} from '@shared/pipes/number-without-round.pipe';
import {TimeAgoExtPipe} from '@shared/pipes/time-ago-ext.pipe';
import {TimeZonePipe} from '@shared/pipes/time-zone.pipe';
import {TruncatePipe} from '@shared/pipes/truncate.pipe';

@NgModule({
  declarations: [
    ArraySortPipe,
    FilterPipe,
    NegativeNumberPipe,
    NumberRoundUpPipe,
    NumberWithoutRoundPipe,
    TimeAgoExtPipe,
    TimeZonePipe,
    TruncatePipe
  ],
  exports: [
    ArraySortPipe,
    FilterPipe,
    NegativeNumberPipe,
    NumberRoundUpPipe,
    NumberWithoutRoundPipe,
    TimeAgoExtPipe,
    TimeZonePipe,
    TruncatePipe
  ]
})
export class PipesModule {}
