import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

import {environment} from '@env/environment';

import {SearchUserResponseFromApi} from '@shared/models/search/api/search-user.response.interface';
import {SearchStockResponse} from '@shared/models/search/api/search-stock.response.interface';
import {SearchStock} from '@shared/models/search/search-stock.interface';
import {UserDetails} from '@shared/models/search/user-details.interface';
import {searchUserConfig} from '../../../../modules/symbol/components/order-dialog/shared/configs/search-user.config';

@Injectable({providedIn: 'root'})
export class SearchApiService {
  private readonly BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  searchUsers(
    query: string,
    offset: number,
    limit: number
  ): Observable<UserDetails[]> {
    if (query === '') {
      return of([]);
    }

    return this.http
      .get(
        this.BASE_URL +
        `/api/v3/user/search/list.json?` +
        `query=${query}&` +
        `offset=${offset}&` +
        `limit=${limit ? limit : searchUserConfig.limit}`
      )
      .pipe(map(({data}: SearchUserResponseFromApi) => data.users));
  }

  searchStock(
    query: string,
    offset: number,
    limit: number
  ): Observable<SearchStock[]> {
    if (query === '') {
      return of([]);
    }

    return this.http
      .get(
        this.BASE_URL +
        `/api/v3/public/stock/search.json?` +
        `searchQuery=${query}&` +
        `offset=${offset}&` +
        `limit=${limit ? limit : searchUserConfig.limit}`
      )
      .pipe(map(({data}: SearchStockResponse) => data));
  }
}
