import {ErrorHandler, InjectionToken, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {environment} from '@env/environment';

import {GlobalErrorHandler} from '@core/services/global-error-handler.service';
import {ServerErrorInterceptor} from '@core/interceptors/server-error.interceptor';
import {AuthInterceptor} from '@core/interceptors/auth.interceptor';

export const ASSETS_PREFIX = new InjectionToken('assetsPrefix');

@NgModule({
  providers: [
    {
      provide: ASSETS_PREFIX,
      useValue: environment.assetsPrefix
    },
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ]
})
export class CoreConfigModule {
}
