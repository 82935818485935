import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserState } from '../state/user.state';

const userFeatureSelector = createFeatureSelector<UserState>('userStore');

export const userInfoSelector = createSelector(
  userFeatureSelector,
  (state: UserState) => state.userInfo
);

export const userInviteDataSelector = createSelector(
  userFeatureSelector,
  (state: UserState) => state.inviteData
);

export const userFriendsSelector = createSelector(
  userFeatureSelector,
  (state: UserState) => state.userFriends
);

export const userNotificationSelector = createSelector(
  userFeatureSelector,
  (state: UserState) => state.userNotification
);

export const userNotificationListSelector = createSelector(
  userFeatureSelector,
  (state: UserState) => state.userNotificationList
);

export const almostThereSelector = createSelector(
  userFeatureSelector,
  (state: UserState) => state.almostThere
);
