import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';

import { untilDestroyed } from 'ngx-take-until-destroy';
import {Observable, of, iif, BehaviorSubject} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  map,
  mergeMap,
  shareReplay,
  filter, delay,
} from 'rxjs/operators';

import {
  cachedSearchStocksSelector,
  cachedSearchUsersSelector,
} from '@store/selectors/search/search.selectors';
import {
  cacheStock,
  cacheUser,
  deleteCachedStock,
  deleteCachedUser,
  deleteAllCachedStocks,
  deleteAllCachedUsers,
} from '@store/actions/search/search.actions';

import { avatarSizes } from '@core/config/avatar.config';
import { SearchService } from '@shared/services/search/search.service';
import { EnvironmentService } from '@core/services/enviroment.service';
import { UserDetails } from '@shared/models/search/user-details.interface';
import { SearchStock } from '@shared/models/search/search-stock.interface';
import { SearchDialogueType } from 'src/app/modules/symbol/components/order-dialog/shared/enums/search-dialogue.type.enum';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() isDisabled = false;

  @ViewChild('stocksTab') stocksTab: ElementRef;
  @ViewChild('usersTab') usersTab: ElementRef;
  @ViewChild('typeaheadHttp') typeaheadHttp: ElementRef;

  cachedSearchUsers$: Observable<UserDetails[]> = this.store.pipe(
    select(cachedSearchUsersSelector),
    shareReplay()
  );
  cachedSearchStocks$: Observable<SearchStock[]> = this.store.pipe(
    select(cachedSearchStocksSelector),
    shareReplay()
  );

  searchLimit = 7;

  searching = false;
  searchFailed = false;
  expanded = false;
  isFromSearchBtn = false;

  activeType: SearchDialogueType = SearchDialogueType.STOCKS;

  searchedUsers: UserDetails[];
  searchedStocks: SearchStock[];

  searchForm: FormGroup;
  searchDialogueType = SearchDialogueType;
  expandedStatus$: Observable<boolean>;

  constructor(
    private searchService: SearchService,
    private fb: FormBuilder,
    private environmentService: EnvironmentService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.initForm();
    this.expandedStatus$ = this.searchService.getExpandedStatus()
      .pipe(
        delay(1000),
        tap(value => {
          if (value) {
            this.typeaheadHttp.nativeElement.focus();
          }
        })
      );
  }

  onFocus(expanded: boolean) {
    this.expanded = expanded;
  }

  onblur(expanded: boolean) {
  }

  onChangeTab(activeType: SearchDialogueType) {
    this.activeType = activeType;
  }

  goToProfile(user: UserDetails) {
    this.store.dispatch(cacheUser({ user }));
    this.router.navigateByUrl('profile/' + user.id);
    this.searchForm.setValue({ searchField: null });
    this.searchedUsers = null;
    this.hideSearchInfo();
  }

  goToStock(stock: SearchStock) {
    this.store.dispatch(cacheStock({ stock }));
    this.router.navigateByUrl('symbol/' + stock.ticker);
    this.searchedStocks = null;
    this.searchForm.setValue({ searchField: null });
    this.hideSearchInfo();
  }

  deleteCachedStock(stock: SearchStock) {
    this.stocksTab.nativeElement.focus();
    this.store.dispatch(deleteCachedStock({ stock }));
  }

  deleteCachedUser(user: UserDetails) {
    this.usersTab.nativeElement.focus();
    this.store.dispatch(deleteCachedUser({ user }));
  }

  deleteAllCachedStocks() {
    this.stocksTab.nativeElement.focus();
    this.store.dispatch(deleteAllCachedStocks());
  }

  deleteAllCachedUsers() {
    this.usersTab.nativeElement.focus();
    this.store.dispatch(deleteAllCachedUsers());
  }

  getIconUrl(prefix: string, name: string) {
    return this.environmentService.getIconUrl(`${prefix}/${name}`);
  }

  getAvatarUrl(hasAvatar: boolean, userId: number): string {
    if (hasAvatar) {
      return this.environmentService.getAvatarUrl(userId, avatarSizes.normal);
    }

    return this.getIconUrl('user', 'user_avatar_small.svg');
  }

  userIconError(error) {
    error.target.src = this.getIconUrl('user', 'user_avatar_small.svg');
    error.target.classList.add('no-icon');
  }

  stockIconError(error) {
    error.target.src = this.getIconUrl('empty', 'stock.svg');
    error.target.classList.add('no-icon');
  }

  hideSearchForm() {
    this.expanded = false;
  }

  getIconForSong(name: string) {
    return this.environmentService.getStockImage(name);
  }

  private createForm() {
    this.searchForm = this.fb.group({
      searchField: [{ value: null, disabled: this.isDisabled }],
    });
  }

  private hideSearchInfo() {
    this.expanded = false;
  }

  private initForm() {
    this.searchForm
      .get('searchField')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((text) => text),
        mergeMap((text) =>
          iif(
            () => this.activeType == SearchDialogueType.STOCKS,
            this.searchService.searchStock(text, 0, this.searchLimit).pipe(
              tap(() => (this.searchFailed = false)),
              catchError(() => {
                this.searchFailed = true;
                return of([]);
              })
            ),
            this.searchService.findUsers(text, null, 0, this.searchLimit).pipe(
              tap(() => (this.searchFailed = false)),
              catchError(() => {
                this.searchFailed = true;
                return of([]);
              })
            )
          )
        ),
        untilDestroyed(this)
      )
      .subscribe((value: any[]) => {
        switch (this.activeType) {
          case SearchDialogueType.STOCKS:
            this.searchedStocks = value;
            break;

          case SearchDialogueType.USERS:
            this.searchedUsers = value;
            break;

          default:
            break;
        }
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {}
}
