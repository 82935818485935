import {Action, createReducer, on} from '@ngrx/store';

import {initialMotivationState, MotivationState} from '../../state/motivation/motivation.state';
import {
  addCustomLink,
  addNews,
  addUserToFollowing,
  closeMotivation,
  hideNewsSearchAction,
  removeCustomLink,
  removeNews,
  removeUserFromFollowing,
  saveNewsSuccess,
  saveTypeForRequestFindUserSuccess,
  showMotivation,
  showNewsSearchAction,
  updateNewsOffset
} from '../../actions/motivation/motivation.actions';
import {UserDetails} from '@shared/models/search/user-details.interface';
import {StockNews} from '@shared/models/news/stock-news.interface';
import {CustomUrlWithUrl} from '../../../modules/symbol/components/order-dialog/shared/interfaces/custom-url-with-url.interface';
import {userLogout} from '@store/actions/user.actions';

const reducer = createReducer(
  initialMotivationState,
  on(addUserToFollowing, (state: MotivationState, {user}) => {
    return {
      ...state,
      followingUsers: state.followingUsers ? checkOnAdd<UserDetails>(state.followingUsers, user) : [user]
    };
  }),
  on(removeUserFromFollowing, (state: MotivationState, {user}) => {
    return {
      ...state,
      followingUsers: state.followingUsers ? [...state.followingUsers.filter((oldUser) => oldUser.id !== user.id)] : []
    };
  }),
  on(addNews, (state: MotivationState, {news}) => {
    return {
      ...state,
      news: state.news ? checkOnAdd<StockNews>(state.news, news) : [news]
    };
  }),
  on(removeNews, (state, {news}) => {
    return {
      ...state,
      news: state.news ? [...state.news.filter((oldNews) => oldNews.id !== news.id)] : []
    };
  }),
  on(addCustomLink, (state: MotivationState, {link}) => {
    return {
      ...state,
      links: state.links ? checkOnAdd<CustomUrlWithUrl>(state.links, link) : [link]
    };
  }),
  on(removeCustomLink, (state: MotivationState, {link}) => {
    return {
      ...state,
      links: state.links ? [...state.links.filter((oldLink) => oldLink.url !== link.url)] : []
    };
  }),
  on(saveNewsSuccess, (state: MotivationState, {news}) => {
    return {
      ...state,
      findNews: state.findNews ? [...state.findNews, ...news] : [...news]
    };
  }),
  on(updateNewsOffset, (state: MotivationState, {offset}) => {
    return {
      ...state,
      offset
    };
  }),
  on(hideNewsSearchAction, (state: MotivationState) => {
    return {
      ...state,
      isShow: false
    };
  }),
  on(showNewsSearchAction, (state: MotivationState) => {
    return {
      ...state,
      isShow: true
    };
  }),
  on(closeMotivation, (state: MotivationState) => {
    return {
      ...initialMotivationState
    };
  }),
  on(showMotivation, (state: MotivationState) => {
    return {
      ...state,
      isShowMotivation: true
    };
  }),
  on(saveTypeForRequestFindUserSuccess, (state: MotivationState, {typeForRequest}) => {
    return {
      ...state,
      typeForRequestOnFindUser: typeForRequest
    };
  }),
  on(userLogout, state => {
    return {
      ...initialMotivationState
    };
  })
);

const checkOnAdd = <T>(users: T[], user: T): T[] => {
  return users.includes(user) ? users : [...users, user];
};

export function motivationReducer(state: MotivationState, action: Action) {
  return reducer(state, action);
}
