import {createFeatureSelector, createSelector} from '@ngrx/store';

import {CompanyDetailsState} from '../../state/company-details/company-details.state';

export const companyDetailsFeatureSelector = createFeatureSelector<CompanyDetailsState>('companyDetails');

export const companyDetailsSelector = createSelector(
  companyDetailsFeatureSelector,
  (state) => state.details
);

export const companyDetailsError = createSelector(
  companyDetailsFeatureSelector,
  (state) => state.error
);
