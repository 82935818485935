import { AlmostThereModel } from '@auth/shared/models/almost.there.model';
import { UserInfo } from '@shared/models/user/user.info.interface';
import { UserNotification } from '@shared/models/user/user.notification.interface';
import { InviteData } from '@shared/models/user/user.invite.interface';
import { UserNotificationList } from '@shared/models/user/user.notification.list.interface';
import { User } from '@shared/models/user/user.interface';
import { UserFriend } from '@shared/models/user/user.friend.interface';

export interface UserState {
  userInfo: UserInfo;
  userFriends: UserFriend[];
  inviteData: InviteData;
  userNotification: UserNotification;
  userNotificationList: UserNotificationList;
  almostThere: AlmostThereModel;
}

export const initialUserState: UserState = {
  userInfo: null,
  userFriends: [],
  inviteData: null,
  userNotification: null,
  userNotificationList: null,
  almostThere: null,
};
