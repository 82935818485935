<ul class="notification-list" *ngIf="userNotification; else registration">
  <li class="notification-item">
    <ng-container *ngIf="userNotification">
      <app-user-notifications-dropdown
        [userNotificationList]="userNotificationList"
        [userNotification]="userNotification"
      >
      </app-user-notifications-dropdown>
    </ng-container>
  </li>

<!--  <li class="notification-item">-->
<!--    <picture>-->
<!--      <img-->
<!--        class="chat"-->
<!--        [src]="getIconUrl('grey_chat@1x.svg')"-->
<!--        alt="chat-image"-->
<!--      />-->
<!--    </picture>-->

<!--    <ng-container *ngIf="userNotification">-->
<!--      <div-->
<!--        class="chat-badge-wrapper"-->
<!--        *ngIf="userNotification?.data.unreadConversationsNumber > 0"-->
<!--      >-->
<!--        <span class="badge badge-danger f-w-b f-s-12 l-h-15">{{-->
<!--          userNotification?.data.unreadConversationsNumber-->
<!--        }}</span>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--  </li>-->
</ul>

<ng-template #registration>
  <ng-content></ng-content>
</ng-template>
