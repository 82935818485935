import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberWithoutRound'
})
export class NumberWithoutRoundPipe implements PipeTransform {
  transform(value: number, symbolAfterComa: number): any {
    let symbol: number;

    switch (symbolAfterComa) {
      case 1:
        symbol = 10;
        break;
      case 2:
        symbol = 100;
        break;
      case 3:
        symbol = 1000;
        break;
      case 4:
        symbol = 10000;
        break;
      default:
        symbol = 100;
        break;
    }
    return Math.floor(+value * symbol) / symbol;
  }
}
