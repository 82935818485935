import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

import {Store} from '@ngrx/store';

import {AuthApiService} from '@auth/services/auth.api.service';
import {TokenService} from '@core/jwt/services/token.service';
import {SignInDto} from '@auth/shared/dto/sign-in.dto';
import {Token} from '@auth/shared/models/token.interface';
import {SignUpDto} from '@auth/shared/dto/sign-up.dto';
import {AlmostThereModel} from '@auth/shared/models/almost.there.model';
import {AlmostThereHelper} from '@auth/shared/helpers/almost.there.helper';
import {
  forgotPasswordRequest,
  forgotPasswordRequestError,
  forgotPasswordRequestSuccess,
  updateUserEmailSuccess
} from '@store/actions/user.actions';
import {UserService} from '@shared/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private authApiService: AuthApiService,
              private tokenService: TokenService,
              private router: Router,
              private store: Store,
              private userService: UserService) {

  }

  login(user: SignInDto, popupWrapperComponent): Observable<any> {
    return this.authApiService.login(user)
      .pipe(
        tap((response: Token) => {
          popupWrapperComponent.close();
          this.tokenService.saveToken(response.token);
          this.router.navigate(['/home']);
        }),
        switchMap(() => this.userService.getUserInfo()),
        catchError((err: any) => throwError(err.error))
      );
  }

  signUp(credentials: SignUpDto): Observable<any> {
    const almostThere: AlmostThereModel = {
      email: credentials.fos_user_registration_form.email
    };

    return this.authApiService.signUp(credentials).pipe(
      tap(() => {
        AlmostThereHelper.setEmailToLocalStorage(almostThere.email);
        this.store.dispatch(updateUserEmailSuccess({almostThere}));
      }),
      map(data => true),
      catchError(err => throwError(err))
    );
  }

  forgotPasswordRequest(email: string): Observable<{almostThere: AlmostThereModel, errors: any[]}> {
    this.store.dispatch(forgotPasswordRequest({email}));

    return this.authApiService.forgotPassword({email}).pipe(
      map(almostThere => {
        this.store.dispatch(forgotPasswordRequestSuccess({almostThere}));
        return ({errors: [], almostThere});
      }),
      catchError((err) => {
        this.store.dispatch(forgotPasswordRequestError({errors: err.error.errors}));
        return of ({errors: err.error.errors, almostThere: null});
      })
    );
  }

}
