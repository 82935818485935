export enum Ident {
  NewFriendRequest = 'NewFriendRequest',
  AcceptedFriendRequest = 'AcceptedFriendRequest',
  NewFollower = 'NewFollower',
  NewMentionWallpostText = 'NewMentionWallpostText',
  NewCommentCommunityOrder = 'NewCommentCommunityOrder',
  NewBullWallpost = 'NewBullWallpost',
  NewWallpostText = 'NewWallpostText',
  NewWallpostLink = 'NewWallpostLink',
  NewWallpostImage = 'NewWallpostImage',
  NewWallpostVideo = 'NewWallpostVideo',
  NewBullCommunityOrder = 'NewBullCommunityOrder',
  GroupPost = 'GroupPost',
  GroupInvite = 'GroupInvite',
  CommunityUserAlert = 'CommunityUserAlert',
  StockPriceAlert = 'StockPriceAlert',
  StockValueAlert = 'StockValueAlert',
  CommunityPortfolioSync = 'CommunityPortfolioSync',
  CommunityPortfolioUnSync = 'CommunityPortfolioUnSync',
  CommunityOrderCancelled = 'CommunityOrderCancelled',
  CommunityOrderPlaced = 'CommunityOrderPlaced',
  CommunityOrderExecuted = 'CommunityOrderExecuted',
}
