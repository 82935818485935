import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {TokenService} from '@core/jwt/services/token.service';
import {GuardHelper} from '@core/helpers/guard.helper';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private router: Router,
              private tokenService: TokenService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.tokenService.currentTokenValue();

    if (!GuardHelper.isToken(token) || state.url === '/auth/logout') {
      return true;
    }

    this.router.navigate(['']);

    return false;
  }
}
