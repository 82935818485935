import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '@env/environment';

import {SignInDto} from '@auth/shared/dto/sign-in.dto';
import {Token} from '@auth/shared/models/token.interface';
import {SignUpDto} from '@auth/shared/dto/sign-up.dto';
import {SignUpResponse} from '@auth/shared/models/sign-up.model';
import {ForgotPasswordDto} from "@auth/shared/dto/forgot-password.dto";
import {AlmostThereModel} from "@auth/shared/models/almost.there.model";

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private http: HttpClient) {
  }


  login(user: SignInDto): Observable<Token> {
    const headers = new HttpHeaders().append('content-type', 'application/json');

    return this.http.post(`${environment.apiUrl}/api/jwt/login_check`, user, {headers})
      .pipe(
        map((response: Token) => response),
      );
  }

  signUp(credentials: SignUpDto): Observable<SignUpResponse> {
    const headers = new HttpHeaders().append('content-type', 'application/json');

    return this.http.post(`${environment.apiUrl}/api/v3/registration.json`, credentials, {headers})
      .pipe(
        map(({data}: {data}) => data)
      );
  }

  forgotPassword(credentials: ForgotPasswordDto): Observable<AlmostThereModel> {
    const headers = new HttpHeaders().append('content-type', 'application/json');

    return this.http.post(`${environment.apiUrl}/api/v3/forgot-password.json`, credentials, {headers})
      .pipe(
        map(({data}: {data}) => data)
      );
  }

}
