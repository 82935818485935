import {Pipe, PipeTransform} from '@angular/core';
import 'moment-timezone';

import * as moment from 'moment';

@Pipe({
  name: 'timeZonePipe'
})

export class TimeZonePipe implements PipeTransform {
  transform(time: string, defaultZone: string): any {
    const format = 'HH:mm';
    const zone = moment.tz.guess();
    const currentData = moment().format('YYYY-MM-DD');
    const timeToConvert = currentData + ' ' + time;

    return moment.tz(timeToConvert, defaultZone).tz(zone).format(format);
  }
}
