import {Action, createReducer, on} from '@ngrx/store';

import {initialWatchlistState, WatchlistState} from '../../state/watchlist/watchlist.state';
import {
  loadWatchListSuccess,
  removeSongToWatchListSuccessAction,
  resetWatchListData,
  updateWatchListCurrentPageSuccess
} from '../../actions/watchlist/watchlist.actions';
import {userLogout} from '../../actions/user.actions';

const reducer = createReducer(
  initialWatchlistState,
  on(updateWatchListCurrentPageSuccess, (state, {pages}) => {
    return {
      ...state,
      pages: {
        current: pages.current,
        prev: pages.prev
      }
    };
  }),
  on(loadWatchListSuccess, (state, {watchlist}) => {
    return {
      ...state,
      watchlist: state.watchlist ? [...state.watchlist, ...watchlist] : [...watchlist]
    };
  }),
  on(removeSongToWatchListSuccessAction, (state, {songId}) => {
    return {
      ...state,
      watchlist: state.watchlist.filter(el => el.stockId !== songId)
    };
  }),
  on(userLogout, resetWatchListData, state => {
    return {
      ...initialWatchlistState
    };
  })
);

export function watchlistReducer(state: WatchlistState, action: Action) {
  return reducer(state, action);
}
