import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';

import {forkJoin, Observable, throwError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

import {getUserFriendsSuccess, getUserInviteSuccess, updateUserInfoSuccess,} from '@store/actions/user.actions';
import {UserApiService} from '@shared/services/user/api/user.api.service';
import {UserInfo} from '@shared/models/user/user.info.interface';
import {AvatarSize} from '@shared/enums/user/avatar-size.enum';
import {EnvironmentService} from '@core/services/enviroment.service';

@Injectable({providedIn: 'root'})
export class UserService {
  constructor(private store: Store,
              private userApiService: UserApiService,
              private environmentService: EnvironmentService) {
  }

  getUserInfo(): Observable<any> {
    return this.userApiService.getUserInfoFromApi().pipe(
      tap(userInfo => {
        let avatarUrl;

        if (userInfo.hasAvatar) {
          avatarUrl = this.environmentService.getAvatarUrl(userInfo.id, AvatarSize.big);
        } else {
          avatarUrl = this.environmentService.getIconUrl('header/avatar@1x.svg');
        }

        const useData: UserInfo = {
          ...userInfo,
          avatarUrl
        }
        this.store.dispatch(updateUserInfoSuccess({userInfo: useData}));
      }),
      switchMap((userInfo: UserInfo) => {
        return forkJoin([
          this.userApiService.getUserInviteLink(userInfo.id),
          this.userApiService.getUserFriends(userInfo.id),
        ]).pipe(
          map(
            ([
               userInvite,
               userFriends,
             ]) => {
              this.store.dispatch(
                getUserInviteSuccess({inviteData: userInvite})
              );
              this.store.dispatch(
                getUserFriendsSuccess({userFriends: userFriends.data})
              );
            }
          ),
          catchError((err: any) => throwError(err.error))
        );
      })
    );
  }
}
