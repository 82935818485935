import {Injectable} from '@angular/core';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';

import {deleteFeed, deleteFeedFailed, deleteFeedSuccess} from '@store/actions/feed/feed.actions';
import {FeedService} from '@shared/services/feed/feed.service';
import {uploadFeedImage, uploadFeedImageFailure, uploadFeedImageSuccess} from '@store/actions/profile/profile.actions';
import {ToastrService} from 'ngx-toastr';
import {FeedImageData} from '../../../modules/posts/posts-block/share/interfaces/feed-image-response.interface';

@Injectable()
export class FeedEffect {

  constructor(private actions$: Actions,
              private feedService: FeedService,
              private modalService: NgbModal,
              private toastr: ToastrService) {
  }

  deleteFeed$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFeed),
      exhaustMap(action =>
        this.feedService.removeFeed(action.feedId).pipe(
          map(response => {
            this.modalService.dismissAll('close delete');

            return deleteFeedSuccess({feedId: action.feedId});
          }),
          catchError(error => of(deleteFeedFailed({error})))
        )
      ),
    )
  );

  uploadFeedImage$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadFeedImage),
      exhaustMap(action =>
        this.feedService.uploadFeedImage(action.file).pipe(
          map((response: FeedImageData) => {
            this.toastr.success('Feed image is upload');
            return uploadFeedImageSuccess({feedResponse: response});
          }),
          catchError(err => {
            this.toastr.error('Feed image is failure');

            return of(uploadFeedImageFailure({err}));
          })
        )
      )
    )
  );
}
