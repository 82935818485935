import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {Store} from '@ngrx/store';

import {JwtHelperService} from '@auth0/angular-jwt';

import {userLogout} from '@store/actions/user.actions';
import {TokenHelper} from '@core/jwt/helpers/token.helper';

@Injectable()
export class TokenService {

  constructor(
    private jwtHelperService: JwtHelperService,
    private router: Router,
    private store: Store,
  ) {
  }

  public currentTokenValue(): null | string {
    return TokenHelper.getTokenFromLocalStorage();
  }

  public saveToken(value: string | null) {
    if (!!value) {
      TokenHelper.saveTokenInLocalStorage(value);
    }
  }

  public removeToken(): void {
    TokenHelper.removeTokenFromLocalStorage();
    this.store.dispatch(userLogout());
  }
}
