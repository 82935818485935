import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {
  nextPageGainersAction,
  nextPageGainersFailureAction,
  nextPageGainersSuccessAction
} from '@store/actions/gainers/gainers.actions';
import {EMPTY} from 'rxjs';

import {GainersService} from '../../../modules/discover/shares/services/gainers/gainers.service';

@Injectable({providedIn: 'root'})
export class GainersEffect {

  constructor(private actions$: Actions,
              private gainersService: GainersService,
              private store: Store) {
  }

  loadPaginationNextPage$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(nextPageGainersAction),
        switchMap(({pagination}) => {
          const page = pagination.current_page < pagination.total_pages ? pagination.current_page + 1 : pagination.current_page;
          return this.gainersService.loadGainersFromApi(page)
            .pipe(
              map(data => nextPageGainersSuccessAction()),
              catchError(err => {
                this.store.dispatch(nextPageGainersFailureAction());
                return EMPTY;
              })
            );
        })
      );
    }
  );
}
