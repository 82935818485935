import {createAction, props} from '@ngrx/store';

import {UserDetails} from '@shared/models/search/user-details.interface';
import {TagSymbols} from '@shared/enums/tag/tag-symbols.enum';
import {SearchStock} from '@shared/models/search/search-stock.interface';

enum TagActions {
  AddUserToTag = '[Tag] Add user to tag',
  AddUserToArray = '[Tag] Add user tag array',
  RemoveUserFromTag = '[Tag] Remove user from tag',
  AddTagSymbol = '[Tag] Add tag symbol',
  RemoveTagSymbol = '[Tag] Remove tag symbol',
  AddStockToArray = '[Tag] Add stock to array',
  AddStockToTag = '[Tag] Add stock tag',
  RemoveStockFromTag = '[Tag] Remove stock from tag',
  UpdateInputContent = '[Tag] Update input content',
}

export const addUserToArrayAction = createAction(
  TagActions.AddUserToArray,
  props<{ user: UserDetails }>()
);

export const addUserToTagAction = createAction(
  TagActions.AddUserToTag,
  props<{ user: UserDetails }>()
);

export const removeUserFromTagAction = createAction(
  TagActions.RemoveUserFromTag
);

export const addTagSymbolAction = createAction(
  TagActions.AddTagSymbol,
  props<{symbol: TagSymbols}>()
);

export const removeTagSymbolAction = createAction(
  TagActions.RemoveTagSymbol
);

export const addStockToArrayAction = createAction(
  TagActions.AddStockToArray,
  props<{stock: SearchStock}>()
);

export const addStockToTagAction = createAction(
  TagActions.AddStockToTag,
  props<{stock: SearchStock}>()
);

export const removeStockFromTagAction = createAction(
  TagActions.RemoveStockFromTag
);

export const updateInputContentAction = createAction(
  TagActions.UpdateInputContent,
  props<{text: string}>()
);


