import {StockChartDataComponent} from '@shared/models/chart/stock/stock-chart-data-component.interface';
import {PortfolioChartDataComponent} from '@shared/models/chart/portfolio/portfolio-chart-data-component.interface';
import {StockChartPeriods} from '@shared/components/chart/shared/enums/stock-chart-periods.enum';
import {PortfolioChartPeriods} from '@shared/components/chart/shared/enums/portfolio-chart.periods.enum';

export interface ChartState {
  portfolioChartData: PortfolioChartDataComponent;
  stockChartData: StockChartDataComponent;
}

export const initialChartState: ChartState = {
  portfolioChartData: {
    activePeriod: PortfolioChartPeriods.WEEK,
    data: null,
    chartPeriods: null,
    lineChartLabels: null,
    dataForTheEntirePeriod: null
  },
  stockChartData: null
};
