import {CompanyDetails} from '@shared/models/stock/company-details.interface';
import {CompanyDetailsError} from '@shared/models/stock/company-details-error.interface';

export interface CompanyDetailsState {
  details: CompanyDetails;
  error: CompanyDetailsError;
}

export const initialCompanyDetailsState: CompanyDetailsState = {
  details: null,
  error: null
};
