import {Injectable} from '@angular/core';

import {select, Store} from '@ngrx/store';
import {combineLatest, Observable, throwError} from 'rxjs';
import {catchError, filter, map, share, switchMap, tap} from 'rxjs/operators';

import {loadNews, loadNewsSuccess, updateLikeNews, updateNews} from '@store/actions/news/news.actions';
import {NewsApiService} from '@shared/services/news/api/news.api.service';
import {LikeNews, NewsWithLike} from '@shared/models/news/api/like.response.interface';
import {StockNews} from '@shared/models/news/stock-news.interface';
import {companyDetailsSelector} from '@store/selectors/company-details/company-details.selector';
import {saveNewsSuccess} from '@store/actions/motivation/motivation.actions';
import {offsetSelector} from '@store/selectors/motivation/motivation.selector';
import {News} from '@shared/models/news/api/news.response.interface';
import {LikeType} from '@shared/enums/like/like-type.enum';
import {updateIconLikeFeedPost, updateLikeFeedPost} from '@store/actions/feed/feed.actions';
import {LikeAction} from '@shared/models/like-action/like-action.interface';

@Injectable({providedIn: 'root'})
export class NewsService {

  constructor(private store: Store,
              private newsApiService: NewsApiService) {
  }

  getNews(): Observable<News[]> {
    return this.newsApiService.getNews()
      .pipe(
        tap(news => this.store.dispatch(loadNews())),
        tap(news => this.store.dispatch(loadNewsSuccess({news}))),
        map(news => news),
        catchError(err => throwError(err))
      );
  }

  like(likeAction: LikeAction): Observable<LikeNews> {
   if (likeAction.type === LikeType.feed) {
     this.store.dispatch(updateIconLikeFeedPost({id: likeAction.id}));
   } else {
     this.store.dispatch(updateLikeNews({id: likeAction.id}));
   }

    return this.newsApiService.likeNews(likeAction.id)
      .pipe(
        tap((likeFromApi: LikeNews) => {

          switch (likeAction.type) {
            case LikeType.feed:
              this.store.dispatch(updateLikeFeedPost({id: likeAction.id, likesCount: likeFromApi.likes_count}));
              break;
            case LikeType.news:
              const news: NewsWithLike = {
                newsId: likeAction.id,
                ...likeFromApi
              };
              this.store.dispatch(updateNews({id: likeAction.id, likesCount: likeFromApi.likes_count}));

              break;
          }

        }),
        catchError(err => {
          if (likeAction.type === LikeType.feed) {
            this.store.dispatch(updateIconLikeFeedPost({id: likeAction.id}));
          } else {
            this.store.dispatch(updateLikeNews({id: likeAction.id}));
          }

          return throwError(err);
        })
      );
  }

  getStockNews(): Observable<StockNews[]> {
    const activeStock$ = this.store.pipe(select(companyDetailsSelector));
    const offset$ = this.store.pipe(select(offsetSelector), share());

    return combineLatest([activeStock$, offset$]).pipe(
      filter(([activeStock, offset]) => Boolean(activeStock)),
      switchMap(([activeStock, offset]) => this.newsApiService.getStockNew(activeStock.ticker, offset)),
      tap(news => this.store.dispatch(saveNewsSuccess({news}))),
      map(news => news),
      catchError(err => throwError(err))
    );

  }
}
