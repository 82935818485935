import {OrderPortfolioTypes} from '@shared/consts/feed/order/order-portfolio-types';
import {OrderPortfolioTypesEnum} from '@shared/enums/feed/order-portfolio-types.enum';
import {FeedPost} from '@shared/models/feed/feed-post.interface';
import {TypesFeed} from '@shared/enums/feed/types-feed.enum';

export class FeedHelper {

  static getOrderPortfolioType(orderPortfolioType: number): string {
    return OrderPortfolioTypes[orderPortfolioType] ?? OrderPortfolioTypesEnum.IncorrectType;
  }

  static getSprinkleImage(orderPortfolioType: number): string {
    const sprinkleBitUrl = 'assets/icons/order-feed/sprinklebit.svg';
    const sprinkleBrokerageUrl = 'assets/icons/order-feed/sprinklebrokerage.svg';
    const defaultIconUrl = 'assets/icons/empty/no-logo.svg';
    const portfolioType = FeedHelper.getOrderPortfolioType(orderPortfolioType);

    switch (portfolioType) {
      case OrderPortfolioTypesEnum.SprinkleBit:
        return sprinkleBitUrl;
      case OrderPortfolioTypesEnum.SprinkleBrokerage:
        return sprinkleBrokerageUrl;
      default:
        return defaultIconUrl;
    }
  }

  static setMotivationMessagesForOrder(feeds: FeedPost[]): FeedPost[] {
    return feeds.map(post => {
      if (post.type === TypesFeed.order) {
        return {
          ...post,
          order: {
            ...post.order,
            motivation: {
              ...post.order.motivation,
              messages: [...this.getMotivationMessages(post.order.motivation.custom)]
            }
          }
        };
      }

      return post;
    });
  }

  static setMotivationMessagesForOrderSinglePost(feed: FeedPost): FeedPost {
    if (feed.type === TypesFeed.order) {
      return {
        ...feed,
        order: {
          ...feed.order,
          motivation: {
            ...feed.order.motivation,
            messages: [...this.getMotivationMessages(feed.order.motivation.custom)]
          }
        }
      };
    }

    return feed;
  }

  private static getMotivationMessages(text: string): string[] {
    const messages = [];

    if (text) {
      const textOfArray = [...text];
      const findSymbols = ['💡', '📈', '📊'];
      const findSymbolsIndex = [];

      findSymbols.forEach(symbol => {
        const findElement = textOfArray.findIndex((el) => el === symbol);

        if (findElement || findElement === 0) {
          findSymbolsIndex.push(findElement);
        }
      });

      if (findSymbolsIndex.length) {
        findSymbolsIndex.reduce((prev, curr, currIndex) => {
          let findMessage = '';

          if (findSymbolsIndex[findSymbolsIndex.length - 1] === findSymbolsIndex[currIndex]) {
            findMessage = textOfArray.slice(prev, curr).join('');
            messages.push(findMessage);

            findMessage = textOfArray.slice(curr).join('');
            messages.push(findMessage);
          } else {
            findMessage = textOfArray.slice(prev, curr).join('');
            messages.push(findMessage);
          }

          return curr;
        });
      }
    }

    return messages;
  }
}
