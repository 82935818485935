<app-popup-wrapper [bodyName]="'forgot'"
                   [addClass]="'forgot'"
                   (dismiss$)="dismissModal()"
>
  <ng-container slot="body">
    <h2 class="modal__heading">Forgot your password?</h2>

    <div class="modal__wrapper">
      <form (ngSubmit)="onSubmitHandler($event)" [formGroup]="forgotPasswordForm">
        <div class="form-field">
          <div class="input" [ngClass]="{ 'input--error': checkEmailErrors.length }">
            <div class="input__prepend input__prepend--user"></div>
            <input
              type="text"
              class="input__element"
              placeholder="User name or E-mail"
              name="email"
              formControlName="email"
            />
          </div>

          <div class="form-field__error" [ngClass]="{ 'form-field__error--show': checkEmailErrors.length }">
            <p *ngFor="let error of checkEmailErrors" class="form-field__error-text">{{error}}</p>
          </div>
        </div>

        <p class="modal__text modal__text--medium modal__text--forgot-password mb-0 text-left messg">
          Fill in your E-mail above and click the submit button below.
        </p>

        <p class="modal__text modal__text--medium modal__text--forgot-password text-left messg">
          We'll send you a link to reset your password.
        </p>

        <div class="modal__actions">
          <a  (click)="signIn()" class="btn btn--full-width btn--main-border btn--main-color btn--sign-in">
            Back to Log In
          </a>

          <button
            [disabled]="forgotPasswordForm.invalid"
            type="submit"
            class="btn btn--full-width"
            [ngClass]="{
          'btn--inactive': forgotPasswordForm.invalid,
          'btn--active': forgotPasswordForm.valid
        }"
          >
            Submit
          </button>
        </div>
      </form>
    </div>

  </ng-container>

</app-popup-wrapper>


<app-almost-there></app-almost-there>
