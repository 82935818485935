import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {LoggingService} from '@core/services/logging.service';
import {NotificationService} from '@core/services/notification.service';
import {ErrorService} from '@core/services/error.service';

@Injectable({providedIn: 'root'})
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  handleError(error: Error | HttpErrorResponse) {

    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(NotificationService);

    let message;
    let stackTrace;

    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = errorService.getOrderConfirmServerMessage(error);
      // message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);
      if (message) {
        notifier.showError(message);
      }
    } else {
      // Client Error
      message = errorService.getClientMessage(error);
      stackTrace = errorService.getClientStack(error);
    }

    // Always log errors
    // logger.logError(message, stackTrace);
  }
}
