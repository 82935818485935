import {createAction, props} from '@ngrx/store';

import {CompanyDetailsError} from '@shared/models/stock/company-details-error.interface';

export enum CompanyDetailsActions {
  LoadCompanyDetails = '[COMPANY-DETAILS] Load company details',
  LoadCompanyDetailsSuccess = '[COMPANY-DETAILS] Load company details success',
  LoadCompanyDetailsFailure = '[COMPANY-DETAILS] Load company details failure',
}

export const loadCompanyDetailsSuccess = createAction(
  CompanyDetailsActions.LoadCompanyDetailsSuccess,
  props<{ companyDetails: any }>()
);

export const loadCompanyDetailsFailure = createAction(
  CompanyDetailsActions.LoadCompanyDetailsFailure,
  props<{ error: CompanyDetailsError }>()
);

export const loadCompanyDetails = createAction(
  CompanyDetailsActions.LoadCompanyDetails,
);
