import {NgModule} from '@angular/core';

import {JwtHelperService, JwtModule} from '@auth0/angular-jwt';

import {environment} from '@env/environment';

import {TokenHelper} from '@core/jwt/helpers/token.helper';
import {TokenService} from '@core/jwt/services/token.service';

@NgModule({
  imports: [
    JwtModule.forRoot({
      config: {
        tokenGetter: TokenHelper.getTokenFromLocalStorage,
        authScheme: 'Bearer-jwt ',
        whitelistedDomains: [`${environment.apiUrl}`],
        blacklistedRoutes: [
          `${environment.apiUrl}/api/jwt/login_check`,
          `${environment.apiUrl}/api/v3/registration.json`,
        ]
      },
    }),
  ],
  providers: [
    JwtHelperService,
    TokenService
  ]
})
export class JwtAppModule {
}
