import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';

import {catchError, concatMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';

import {
  nextPageTrendingInvestorsAction,
  nextPageTrendingInvestorsFailureAction,
  nextPageTrendingInvestorsSuccessAction,
  sendFollowUpAction,
  sendFollowUpFailureAction,
  sendFollowUpSuccessAction
} from '@store/actions/trending-investors/trending-investors.actions';
import {TrendingInvestorsService} from '../../../modules/discover/shares/services/trending-investors/trending-investors.service';
import {userInfoSelector} from '@store/selectors/user.selector';

@Injectable({providedIn: 'root'})
export class TrendingInvestorsEffect {
  constructor(private actions: Actions,
              private trendingInvestorsService: TrendingInvestorsService,
              private store: Store) {
  }

  loadTrendingInvestorsNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(nextPageTrendingInvestorsAction),
      switchMap(({offset, isCanLoad}) => {
        if (!isCanLoad) {
          this.store.dispatch(nextPageTrendingInvestorsFailureAction());
          return EMPTY;
        }

        return this.trendingInvestorsService.loadInvestorsTrendingFromApi(offset + 1)
          .pipe(
            map(data => nextPageTrendingInvestorsSuccessAction()),
            catchError(err => {
              this.store.dispatch(nextPageTrendingInvestorsFailureAction());
              return EMPTY;
            })
          );
      })
    )
  );

  followRequest$ = createEffect(() =>
    this.actions.pipe(
      ofType(sendFollowUpAction),
      concatMap((action) => {
        return of(action).pipe(
          withLatestFrom(this.store.pipe(select(userInfoSelector))),
          switchMap(([{potentialUsersId}, userInfo]) => {
            return this.trendingInvestorsService.followRequest(userInfo.id, potentialUsersId)
              .pipe(
                map(data => sendFollowUpSuccessAction()),
                catchError(err => {
                  this.store.dispatch(sendFollowUpFailureAction());
                  this.trendingInvestorsService.updateStatusIsFollowedInTrendingInvestors(false, potentialUsersId);
                  return EMPTY;
                })
              );
          })
        )
      })
    )
  );

}
