import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[appEmptyValue]'
})
export class EmptyValueDirective {
  @Input() value: number | undefined;

  @HostBinding('class.empty') get isEmpty() { return !this.value; }
}
