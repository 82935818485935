import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

import {select, Store} from '@ngrx/store';

import {Observable} from 'rxjs';

import {UserDetails} from '@shared/models/search/user-details.interface';
import {avatarSizes} from '@core/config/avatar.config';
import {addUserToFollowing} from '@store/actions/motivation/motivation.actions';
import {EnvironmentService} from '@core/services/enviroment.service';
import {AddUserForType} from '@shared/enums/search/add-user-for-type.enum';
import {typeForRequestFindUserSelector} from '@store/selectors/motivation/motivation.selector';
import {addUserToArrayAction, addUserToTagAction, removeTagSymbolAction} from '@store/actions/tag/tag.actions';
import {searchOfUsersSuccess} from '@store/actions/search/search.actions';

@Component({
  selector: 'app-search-users-list',
  templateUrl: './search-users-list.component.html',
  styleUrls: ['./search-users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchUsersListComponent implements OnInit {
  @Input() findUsers: UserDetails[];

  typeForRequest$: Observable<AddUserForType | null> = this.store.pipe(select(typeForRequestFindUserSelector));

  constructor(private store: Store,
              private environmentService: EnvironmentService) {
  }

  ngOnInit(): void {
  }

  getAvatarUrl(hasAvatar: boolean, userId: number): string {
    if (hasAvatar) {
      return this.environmentService.getAvatarUrl(userId, avatarSizes.normal);
    }

    return this.environmentService.getIconUrl('user/avatar@1x.svg');
  }

  addUser(user: UserDetails, typeForRequest: AddUserForType) {
    switch (typeForRequest) {
      case AddUserForType.FOLLOWING:
        this.store.dispatch(addUserToFollowing({user}));
        break;
      case AddUserForType.TAG:
        this.store.dispatch(addUserToTagAction({user}));
        this.store.dispatch(addUserToArrayAction({user}));
        this.store.dispatch(removeTagSymbolAction());
        break;
      default:
        break;
    }

    this.store.dispatch(searchOfUsersSuccess({users: []}));
  }

  error(error) {
    error.target.src = this.getIconUrl('user_avatar.svg');
    error.target.classList.add('no-icon');
  }

  getIconUrl(name: string) {
    return this.environmentService.getIconUrl('user/' + name);
  }

}
