import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {TrendingStock, TrendingStockResponse} from '../../../interfaces/trending-stock/trending-stock.interfaces';
import {environment} from '@env/environment';

@Injectable({providedIn: 'root'})
export class TrendingStocksApiService {
  private readonly BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getStockTrending(offset: number = 0): Observable<TrendingStock[]> {
    return this.http
      .get(this.BASE_URL + `/api/v3/stock/trending.json?offset=${offset}&limit=${5}`)
      .pipe(map((response: TrendingStockResponse) => response.data.length ? response.data : []));
  }
}
