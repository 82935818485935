import { Action, createReducer, on } from '@ngrx/store';

import { initialUserState, UserState } from '../state/user.state';
import {
  updateUserEmailSuccess,
  updateUserInfoSuccess,
  getUserNotificationSuccess,
  getUserNotificationListSuccess,
  userLogout,
  getUserInviteSuccess,
  getUserFriendsSuccess, setUserNotificationRead,
} from '../actions/user.actions';
import {updateSettingsAccountHolderSuccess, uploadAvatarSuccess} from '@store/actions/profile/profile.actions';

const reducer = createReducer(
  initialUserState,
  on(updateUserInfoSuccess, (state, { userInfo }) => {
    return {
      ...state,
      userInfo: {
        ...userInfo,
      },
    };
  }),
  on(getUserInviteSuccess, (state, { inviteData }) => {
    return {
      ...state,
      inviteData: {
        ...inviteData,
      },
    };
  }),
  on(getUserFriendsSuccess, (state, { userFriends }) => {
    return {
      ...state,
      userFriends: [...userFriends],
    };
  }),
  on(getUserNotificationSuccess, (state, { userNotification }) => {
    return {
      ...state,
      userNotification: {
        ...userNotification,
      },
    };
  }),
  on(getUserNotificationListSuccess, (state, { userNotificationList }) => {
    return {
      ...state,
      userNotificationList: {
        ...userNotificationList,
      },
    };
  }),
  on(setUserNotificationRead, (state, { notificationId }) => {
    const list = state.userNotificationList.data;
    const userNotificationListData = list.map(notification => {
      return notification.id === notificationId ? {...notification, isRead: true} : notification;
    });
    return {
      ...state,
      userNotificationList: {
        data: [...userNotificationListData]
      }
    };
  }),
  on(updateUserEmailSuccess, (state, { almostThere }) => {
    return {
      ...state,
      almostThere: {
        ...almostThere,
      },
    };
  }),
  on(userLogout, (state) => {
    return {
      ...initialUserState,
    };
  }),
  on(updateSettingsAccountHolderSuccess, (state, { accountHolder }) => {
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        name: accountHolder.firstName + ' ' + accountHolder.lastName,
      },
    };
  }),
  on(uploadAvatarSuccess, (state, {avatarUrl}) => {
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        avatarUrl
      },
    };
  }),
);

export function userReducer(state: UserState, action: Action) {
  return reducer(state, action);
}
