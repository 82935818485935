import {FeedPost} from '@shared/models/feed/feed-post.interface';
import {RequestParamsFeed} from '@shared/models/feed/request/request-params-feed.interface';
import {FeedRequestForHome} from '@shared/models/feed/request/feed-request-for-home.interface';
import {FeedImageData} from '../../../modules/posts/posts-block/share/interfaces/feed-image-response.interface';

export interface FeedState {
  multipleFeedEntries: FeedPost[];
  singleFeedEntries: any[];
  requestsParams: RequestParamsFeed | FeedRequestForHome;
  error: any;
  feedImageData: FeedImageData;
}

export const initialFeedState: FeedState = {
  multipleFeedEntries: null,
  singleFeedEntries: null,
  requestsParams: null,
  error: null,
  feedImageData: null
};
