import { createFeatureSelector, createSelector } from "@ngrx/store";

import { SearchState } from "@store/state/search/search.state";

const searchFeatureSelector = createFeatureSelector<SearchState>("search");

export const searchUsersSelector = createSelector(
  searchFeatureSelector,
  (state) => state.users
);

export const searchStocksSelector = createSelector(
  searchFeatureSelector,
  (state) => state.stocks
);

export const cachedSearchStocksSelector = createSelector(
  searchFeatureSelector,
  (state) => state.cachedStocks
);

export const cachedSearchUsersSelector = createSelector(
  searchFeatureSelector,
  (state) => state.cachedUsers
);
