<div class="body" *ngIf="findStocks?.length;">
  <ul>
    <li *ngFor="let stock of findStocks" (click)="addStock(stock)">
      <div class="img-wrapper">
        <img [src]="getAvatarUrl(stock.has_logo, stock.id)"
             alt="{{stock.ticker}}"
             (error)="error($event)"
        >
      </div>

      <div class="f-s-14 l-h-17 f-c-333 f-w-b info-wrapper">
        {{stock.ticker | appReplaceSongTitle}}

        <div class="f-s-11 l-h-13 f-c-888 info-wrapper">
          {{stock.companyName}}
        </div>
      </div>

    </li>
  </ul>
</div>
