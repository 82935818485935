import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { EnvironmentService } from '@core/services/enviroment.service';
import { TokenService } from '@core/jwt/services/token.service';
import {
  userInfoSelector,
  userNotificationSelector,
  userNotificationListSelector,
  userInviteDataSelector,
} from '@store/selectors/user.selector';
import { selectRouter } from '@store/selectors/router.selector';
import { blockListUrl } from './config/block-list-url.config';
import { UserNotification } from '@shared/models/user/user.notification.interface';
import { UserInfo } from '@shared/models/user/user.info.interface';
import { UserService } from '@shared/services/user/user.service';
import { InviteData } from '@shared/models/user/user.invite.interface';
import { UserNotificationList } from '@shared/models/user/user.notification.list.interface';
import {UserApiService} from '@shared/services/user/api/user.api.service';
import {getUserNotificationListSuccess, getUserNotificationSuccess} from '@store/actions/user.actions';
import {tap} from 'rxjs/operators';
import {ChartService} from '@shared/services/chart/chart.service';
import {FeedService} from '@shared/services/feed/feed.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  userNotification$: Observable<UserNotification> = this.store.pipe(
    select(userNotificationSelector)
  );
  userNotificationList$: Observable<UserNotificationList> = this.store.pipe(
    select(userNotificationListSelector)
  );
  userInfo$: Observable<UserInfo> = this.store.pipe(select(userInfoSelector));
  inviteData$: Observable<InviteData> = this.store.pipe(
    select(userInviteDataSelector)
  );
  activeRouter$: Observable<any> = this.store.pipe(select(selectRouter));
  loadUserData$: Observable<any> = this.userService.getUserInfo();

  loadUserNotificationFromApi$: Observable<any>;
  loadUserNotificationListFromApi$: Observable<any>;

  constructor(
    private environmentService: EnvironmentService,
    private tokenService: TokenService,
    private store: Store,
    private userService: UserService,
    private router: Router,
    private userApiService: UserApiService,
    private chartService: ChartService,
    private feedService: FeedService
  ) {}

  ngOnInit() {
    this.loadUserNotificationFromApi$ = this.userApiService.getUserNotificationFromApi().pipe(
      tap(userNotification => {
        this.store.dispatch(
          getUserNotificationSuccess({ userNotification })
        );
      })
    );
    this.loadUserNotificationListFromApi$ = this.userApiService.getUserNotificationListFromApi().pipe(
      tap(userNotificationList => {
        this.store.dispatch(
          getUserNotificationListSuccess({ userNotificationList })
        );
      })
    );
  }

  getIconUrl(name: string) {
    return this.environmentService.getIconUrl('header/' + name);
  }

  logout() {
    this.tokenService.removeToken();
    this.chartService.removeChartPeriodFromLocalStorage();
    this.goToRoute('home');
  }

  goToProfile(userId: number) {
    this.router.navigateByUrl('profile/' + userId);
  }

  isDisable(url: string): boolean {
    return blockListUrl.includes(url);
  }

  stopNavigate(event) {
    event.stopPropagation();
  }

  goToRoute(url: string | UrlTree) {
    if (url === '/home') {
      this.feedService.setSingleFeedIdForHome(null);
    }
    this.router.navigateByUrl(url);
  }

  isActiveLink(url: string, activeRouter: any): boolean {
    return url === activeRouter.state.url;
  }
}
