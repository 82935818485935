export class AlmostThereHelper {

  static setEmailToLocalStorage(email: string): void {
    localStorage.setItem('email', email);
  }

  static removeEmailFromLocalStorage(): void {
    localStorage.removeItem('email');
  }
}
