import {createAction, props} from '@ngrx/store';

import {PortfolioValues} from '@shared/models/portfolio/portfolio.widget.interface';
import {PositionForComponent} from '@shared/models/positions/api/positions.interface';
import {PortfoliosList} from '@shared/models/portfolio/portfolios-list.interface';

export enum PortfolioActions {
  GetPortfolioValues = '[PORTFOLIO-WIDGET] Get portfolio values',
  GetPortfolioValuesSuccess = '[PORTFOLIO-WIDGET] Get portfolio values success',
  LoadPositionsSuccess = '[POSITION-WIDGET] Load positions success',
  UpdatePositionsSuccess = '[POSITION-WIDGET] Update positions success',
  UpdatePortfoliosListSuccess = '[POSITION-WIDGET] Update portfolios list success'
}

export const getPortfolioValuesSuccess = createAction(
  PortfolioActions.GetPortfolioValuesSuccess,
  props<{ values: PortfolioValues }>()
);

export const loadPositionDataSuccess = createAction(
  PortfolioActions.LoadPositionsSuccess,
  props<{ positions: PositionForComponent[] }>()
);

export const updatePositionDataSuccess = createAction(
  PortfolioActions.UpdatePositionsSuccess,
  props<{ positions: PositionForComponent[] }>()
);

export const updatePortfoliosListSuccess = createAction(
  PortfolioActions.UpdatePortfoliosListSuccess,
  props<{ portfoliosList: PortfoliosList }>()
);


