import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {
  nextPageLosersAction,
  nextPageLosersFailureAction,
  nextPageLosersSuccessAction
} from '@store/actions/losers/losers.actions';
import {LosersService} from '../../../modules/discover/shares/services/losers/losers.service';

@Injectable({providedIn: 'root'})
export class LosersEffect {

  constructor(private actions$: Actions,
              private losersService: LosersService,
              private store: Store) {
  }

  loadPaginationNextPage$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(nextPageLosersAction),
        switchMap(({pagination}) => {
          const page = pagination.current_page < pagination.total_pages ? pagination.current_page + 1 : pagination.current_page;
          return this.losersService.loadLosersFromApi(page)
            .pipe(
              map(data => nextPageLosersSuccessAction()),
              catchError(err => {
                this.store.dispatch(nextPageLosersFailureAction());
                return EMPTY;
              })
            );
        })
      );
    }
  );
}
