<app-popup-wrapper [bodyName]="'almost'"
                   [addClass]="'almost'"
>
  <ng-container slot="body">
    <ng-container *ngIf="almostThere$ | async as data">
      <h2 class="modal__heading">Almost there!</h2>

      <div class="modal__wrapper">
        <p class="modal__text modal__text--check-email modal__text--normal">
          We have sent you an E-mail to:<br /><span class="btn--link-color">{{
          data.email
          }}</span
        ><br />Please check your inbox</p>

        <p class="modal__text modal__text--small modal__text--check-email text-left">
          If you haven’t recieved the E-mail, please try the following:<br />
          1. Make sure the E-mail address you provided is correct;<br />
          2. Check your Spam or Junk mail folder;<br />
          3. Resend E-mail.<br />
        </p>

        <div class="modal__actions modal__actions--vertical mb-20">
          <button class="btn btn--full-width btn--active" (click)="goToHome()">
            Got It
          </button>
        </div>

        <div class="text-center">
          <a routerLink=""
             class="modal__text modal__text--link modal__text--sign-up modal__text--forgot"
             (click)="resendEmail($event, 'data.email')"
          >
            Resent E-mail
          </a>
        </div>
      </div>
    </ng-container>
  </ng-container>

</app-popup-wrapper>





