import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {GuardHelper} from '@core/helpers/guard.helper';

@Injectable()
export class AlmostThereGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (GuardHelper.hasEmail()) {
      return true;
    }

    this.router.navigate(['']);

    return false;
  }
}
