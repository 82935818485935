import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from '@env/environment';

import {watchlistConfig} from '@shared/config/watchlist/watchlistConfig';
import {WatchActivityStock, WatchlistResponse} from '@shared/models/watchlist/api/watchlist.response';
import {CompanyDetails} from '@shared/models/stock/company-details.interface';
import {ActivityStockWithUrl} from '@shared/models/watchlist/watchlist.widget.interface';
import {logout} from '@store/reducers/meta.reducer';

@Injectable({providedIn: 'root'})
export class WatchlistApiService {
  private readonly BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getWatchlist(userId: number, offset: number = 0): Observable<WatchActivityStock[]> {
    return this.http.get(this.BASE_URL +
      `/api/v3/user/${userId}/watchlist/list.json?page[offset]=${offset}&page[limit]=${watchlistConfig.page.limit}`
    ).pipe(
      map((response: WatchlistResponse) => response.data.watchlist ? response.data.watchlist : []),
      catchError(err => of(err))
    );
  }

  addToWatchList(ticker: string): Observable<any> {
    const data = {
      ticker: [ticker]
    }

    return this.http.post(this.BASE_URL + '/api/v2/user/watchlist/set', JSON.stringify(data))
  }

  removeSongFromWatchlist(song: ActivityStockWithUrl): Observable<any> {
    const data = {
      ticker: song.ticker
    }

    return this.http.post(this.BASE_URL + '/api/v2/user/watchlist/remove', JSON.stringify(data));
  }
}
