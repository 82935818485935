import {Directive, ElementRef, Input} from '@angular/core';

import Inputmask from 'inputmask';


@Directive({
  selector: '[appRestrictInput]',
})
export class RestrictInputDirective {

  private regexMap = {
    integer: '^[0-9]*$',
    float: '^[+]?([.]\\d+|\\d+([.]\\d+)?)$',
    words: '([A-z]*\\s)*',
    point25: '^\-?[0-9]*(?:\\.25|\\.50|\\.75|)$'
  };

  constructor(private el: ElementRef) {}

  @Input('app-restrict-input')
  public set defineInputType(type: string) {
    Inputmask({regex: this.regexMap[type], placeholder: ''})
      .mask(this.el.nativeElement);
  }

}
