import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'negativeNumber'
})
export class NegativeNumberPipe implements PipeTransform {

  transform(value: any, ...args): any {
    return Math.abs(value);
  }
}
