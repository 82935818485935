import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

import {Store} from '@ngrx/store';
import {EnvironmentService} from '@core/services/enviroment.service';
import {avatarSizes} from '@core/config/avatar.config';
import {addStockToArrayAction, addStockToTagAction, removeTagSymbolAction} from '@store/actions/tag/tag.actions';
import {searchOfStocksSuccess} from '@store/actions/search/search.actions';
import {SearchStock} from '@shared/models/search/search-stock.interface';

@Component({
  selector: 'app-search-stocks-list',
  templateUrl: './search-stocks-list.component.html',
  styleUrls: ['./search-stocks-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchStocksListComponent implements OnInit {
  @Input() findStocks: SearchStock[];

  constructor(private store: Store,
              private environmentService: EnvironmentService) {
  }

  ngOnInit(): void {
  }

  getAvatarUrl(hasAvatar: boolean, userId: number): string {
    if (hasAvatar) {
      return this.environmentService.getAvatarUrl(userId, avatarSizes.normal);
    }

    return this.environmentService.getIconUrl('user/avatar@1x.svg');
  }

  addStock(stock: SearchStock) {
    this.store.dispatch(addStockToTagAction({stock}));
    this.store.dispatch(addStockToArrayAction({stock}));
    this.store.dispatch(removeTagSymbolAction());
    this.store.dispatch(searchOfStocksSuccess({stocks: []}));
  }

  error(error) {
    error.target.src = 'assets/icons/empty/no-logo.svg';
    error.target.classList.add('no-icon');
  }

  getIconUrl(name: string) {
    return this.environmentService.getIconUrl('user/' + name);
  }
}
