import {ProfileNav} from '../../../modules/profile/shares/enums/profile-nav.enum';
import {ProfileForComponent} from '@shared/models/profile/profile-for-component.interface';
import {ProfilePortfolioType} from '../../../modules/profile/shares/enums/profile-portfolio-type.enum';
import {PortfolioForProfile} from '@shared/models/portfolio/portfolio-for-profile.interfce';
import {ProfileAccountHolder} from '@shared/models/profile/profile-account-holder.interface';
import {ConnectionsNav} from '../../../modules/profile/profile-body/components/enums/connections-nav.enum';

export interface ProfileState {
  activeTab: ProfileNav;
  connectionsNav: ConnectionsNav;
  profileInfo: ProfileForComponent | null;
  portfolioType: ProfilePortfolioType;
  selectedPortfolio: PortfolioForProfile | null;
  accountHolder: ProfileAccountHolder | null;
  profileIdForOtherUserByTypeBrokerage: number | null;
  userId: number | null;
}

export const initialProfileState: ProfileState = {
  activeTab: ProfileNav.Home,
  connectionsNav: ConnectionsNav.Friends,
  profileInfo: null,
  portfolioType: ProfilePortfolioType.SprinkleBit,
  selectedPortfolio: null,
  accountHolder: null,
  profileIdForOtherUserByTypeBrokerage: null,
  userId: null
};
