import {Injectable} from '@angular/core';

import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {GainersApiService} from './api/gainers-api.service';
import {Gainer, GainersResponse} from '../../interfaces/gainers/gainers.interface';

@Injectable({providedIn: 'root'})
export class GainersService {
  private gainers$ = new BehaviorSubject<GainersResponse | null>(null);

  constructor(private gainersApiService: GainersApiService) {
  }

  loadGainersFromApi(page?: number): Observable<Gainer[]> {
    return this.gainersApiService.loadGainersFromApi(page)
      .pipe(
        map(response => {
          const oldGainers = this.gainers$.getValue();

          if (Boolean(oldGainers)) {
            const newGainers: GainersResponse = {
              ...oldGainers,
              gainers: [...oldGainers.gainers, ...response],
              pagination: {
                count: response.length,
                current_page: oldGainers.pagination.current_page + 1,
                per_page: 0,
                total_pages: response.length > 0 ? response.length / 7 : 0
              }
            };

            this.setGainers(newGainers);
          } else {
            const newGainers: GainersResponse = {
              gainers: [...response],
              pagination: {
                count: response.length,
                current_page: 0,
                per_page: 0,
                total_pages: response.length > 0 ? response.length / 7 : 0
              }
            };
            this.setGainers(newGainers);
          }

          return response;
        }),
        catchError(e => {
          this.setGainers(null);

          return EMPTY;
        })
      );
  }

  getGainers(): Observable<GainersResponse> {
    return this.gainers$.asObservable();
  }

  clearGainers(): void {
    this.setGainers(null);
  }

  setGainers(data: any) {
    this.gainers$.next(data);
  }
}
