import { createAction, props } from '@ngrx/store';

import { Group } from '@shared/models/groups/group.interface';
import { Page } from '@shared/interfaces/page/page.interface';

export enum GroupsActions {
  LoadGroupsForCurrentUser = '[GROUPS-API] Load Groups For Current User',
  LoadGroupsForCurrentUserSuccess = '[GROUPS-API] Load Groups For Current User success',
  LoadDiscoveredGroups = '[GROUPS-API] Load Load Discovered Groups',
  LoadDiscoveredGroupsSuccess = '[GROUPS-API]  Load Discovered Groups success',
  LoadFeaturedGroups = '[GROUPS-API] Load Load Featured Groups',
  LoadFeaturedGroupsSuccess = '[GROUPS-API]  Load Featured Groups success',
  UpdatePageForCurrentUserGroupsSuccess = '[GROUPS-API] Update Page For Current User Groups Success',
  UpdatePageDiscoverGroupsSuccess = '[GROUPS-API] Update Page Discover Groups Success',
  ResetData = '[GROUPS] Reset data',
}

/**
 * For currents user Groups (Yours)
 */
export const loadGroupsForCurrentUser = createAction(
  GroupsActions.LoadGroupsForCurrentUser
);

export const loadGroupsForCurrentUserSuccess = createAction(
  GroupsActions.LoadGroupsForCurrentUserSuccess,
  props<{ groups: Group[] }>()
);

/**
 * Discovered Groups
 */
export const loadDiscoveredGroups = createAction(
  GroupsActions.LoadDiscoveredGroups
);

export const loadDiscoveredGroupsSuccess = createAction(
  GroupsActions.LoadDiscoveredGroupsSuccess,
  props<{ groups: Group[] }>()
);

/**
 * Featured Groups
 */
export const loadFeaturedGroups = createAction(
  GroupsActions.LoadFeaturedGroups
);

export const loadFeaturedGroupsSuccess = createAction(
  GroupsActions.LoadFeaturedGroupsSuccess,
  props<{ groups: Group[] }>()
);

/**
 * Update scrolling page data
 */
export const updatePageDiscoverGroupsSuccess = createAction(
  GroupsActions.UpdatePageDiscoverGroupsSuccess,
  props<Page>()
);

export const updatePageForCurrentUserGroupsSuccess = createAction(
  GroupsActions.UpdatePageForCurrentUserGroupsSuccess,
  props<Page>()
);

export const resetData = createAction(GroupsActions.ResetData);
