<div ngbDropdown class="notifications-list-drop-down-wrapper" [autoClose]="'outside'">
  <picture ngbDropdownToggle>
    <img
      class="notifications"
      [src]="getIconUrl('grey_notifications@1x.svg')"
      alt="notifications-image"
    />
  </picture>
  <div class="chat-badge-wrapper" *ngIf="userNotification?.data.unread > 0">
    <span class="badge badge-danger f-w-b f-s-12 l-h-15">{{
      userNotification?.data.unread
    }}</span>
  </div>

  <div
    ngbDropdownMenu
    aria-labelledby="dropdownNotifications"
    class="notifications-list-wrapper"
  >
    <div
      ngbDropdownItem
      *ngFor="let notification of userNotificationList?.data"
      class="notificaion-item"
    >
      <app-user-notification-item
        [notification]="notification"
        [dropdown] = "dropdown"
      ></app-user-notification-item>
    </div>
  </div>
</div>
