import {Inject, Injectable} from '@angular/core';

import {ASSETS_PREFIX} from '@core/core.config.module';
import {environment} from '@env/environment';

@Injectable({providedIn: 'root'})
export class EnvironmentService {
  constructor(@Inject(ASSETS_PREFIX) private assetsPrefix) {
  }

  public getAssetsPrefix() {
    return `${this.assetsPrefix}`;
  }

  getImageUrl(imageName: string) {
    return `${this.assetsPrefix}images/${imageName}`;
  }

  getIconUrl(iconName: string) {
    return `${this.assetsPrefix}icons/${iconName}`;
  }

  getImageUrlForIndexPage(imageName: string) {
    return `${this.assetsPrefix}${imageName}`;
  }

  getAvatarUrl(userId: number, sizeAvatar = 130): string {
    return environment.s3StorageUrl + `/storage/images/avatars/${sizeAvatar}/${userId}.jpg`;
  }

  getStockImage(ticker: string): { url: string } {
    const url = environment.s3StorageUrl + `/storage/images/companies/${ticker}.gif`;

    return {url};
  }

  getUserBackground(userId: number): string {
    return environment.s3StorageUrl + `/storage/images/covers_mobile/1080/${userId}.jpg`;
  }

  getFeedImageUrl(feedUrl: string): string {
    const url = feedUrl.slice(feedUrl.lastIndexOf('.com') + 4);
    return environment.s3StorageUrl + url;
  }
}
