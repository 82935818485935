export class GuardHelper {

  static isToken(token: string | null): boolean {
    return !!token && typeof token === 'string' && token !== 'null';
  }

  static hasEmail(): boolean {
    const email = localStorage.getItem('email');

    return !!email;
  }
}
