import {createAction, props} from '@ngrx/store';

enum TrendingInvestorsActions {
  NEXT_PAGE = '[TrendingInvestors] Next Page',
  NEXT_PAGE_SUCCESS = '[TrendingInvestors] Next Page Success',
  NEXT_PAGE_FAILURE = '[TrendingInvestors] Next Page Failure',
  PREV_PAGE = '[TrendingInvestors] Prev Page',
  SEND_FOLLOW_UP = '[TrendingInvestors] Send Follow Up',
  SEND_FOLLOW_UP_SUCCESS = '[TrendingInvestors] Send Follow Up Success',
  SEND_FOLLOW_UP_FAILURE = '[TrendingInvestors] Send Follow Up Failure'
}

export const nextPageTrendingInvestorsAction = createAction(
  TrendingInvestorsActions.NEXT_PAGE,
  props<{ offset: number, isCanLoad: boolean }>()
);

export const nextPageTrendingInvestorsSuccessAction = createAction(
  TrendingInvestorsActions.NEXT_PAGE_SUCCESS,
);

export const nextPageTrendingInvestorsFailureAction = createAction(
  TrendingInvestorsActions.NEXT_PAGE_FAILURE,
);

export const sendFollowUpAction = createAction(
  TrendingInvestorsActions.SEND_FOLLOW_UP,
  props<{potentialUsersId: number}>()
);

export const sendFollowUpSuccessAction = createAction(
  TrendingInvestorsActions.SEND_FOLLOW_UP_SUCCESS,
);

export const sendFollowUpFailureAction = createAction(
  TrendingInvestorsActions.SEND_FOLLOW_UP_FAILURE,
);

export const prevTrendingInvestorsAction = createAction(
  TrendingInvestorsActions.PREV_PAGE,
  props<{ offset: number, isCanLoad: boolean }>()
);
