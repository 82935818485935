<app-popup-wrapper [bodyName]="'signup'"
                   [addClass]="'signup'"
                   (dismiss$)="dismissModal()"
>
  <ng-container slot="body">
    <h2 class="modal__heading">Create Account</h2>

    <div class="modal__wrapper">
      <form (ngSubmit)="onSubmitHandler()" [formGroup]="signUpForm">
        <div class="form-field">
          <div class="input">
            <div class="input__prepend input__prepend--user"></div>

            <input
              type="text"
              class="input__element"
              placeholder="First Name"
              name="firstName"
              formControlName="firstName"
            />
          </div>
        </div>

        <div class="form-field">
          <div class="input">
            <div class="input__prepend input__prepend--user"></div>

            <input
              type="text"
              class="input__element"
              placeholder="Last Name"
              name="lastName"
              formControlName="lastName"
            />
          </div>
        </div>

        <div class="form-field">
          <div
            class="input input__userName"
            [ngClass]="{
            'input--error':
              signUpForm.get('userName').invalid &&
              (signUpForm.get('userName').dirty ||
                signUpForm.get('userName').touched)
          }"
          >
            <div class="input__prepend input__prepend--user"></div>
            <input
              type="text"
              class="input__element"
              placeholder="User name"
              name="userName"
              formControlName="userName"
            />
            <div
              *ngIf="
              signUpForm.get('userName').invalid &&
              signUpForm.get('userName').hasError('backendError') &&
              (signUpForm.get('userName').dirty ||
                signUpForm.get('userName').touched)
            "
              class="form-field__error form-field__error--show form-field__error--userName"
            >
              <p class="form-field__error-text">
                {{ signUpForm.get("userName").getError("backendError")?.message }}
              </p>
            </div>
          </div>
        </div>

        <div class="form-field">
          <div
            class="input input__email"
            [ngClass]="{
            'input--error':
              signUpForm.get('email').invalid &&
              (signUpForm.get('email').dirty || signUpForm.get('email').touched)
          }"
          >
            <div class="input__prepend input__prepend--email"></div>

            <input
              type="email"
              class="input__element"
              placeholder="E-mail"
              name="email"
              formControlName="email"
            />

            <div
              *ngIf="
              signUpForm.get('email').invalid &&
              signUpForm.get('email').hasError('backendError') &&
              (signUpForm.get('email').dirty || signUpForm.get('email').touched)
            "
              class="form-field__error form-field__error--show form-field__error--email"
            >
              <p class="form-field__error-text">
                {{ signUpForm.get("email").getError("backendError")?.message }}
              </p>
            </div>
          </div>
        </div>

        <div class="form-field">
          <div class="input">
            <div class="input__prepend input__prepend--password"></div>
            <input
              [type]="isShowPassword ? 'text' : 'password'"
              class="input__element"
              placeholder="Password"
              name="password"
              formControlName="password"
            />
            <span
              class="input__append input__append--password"
              [ngClass]="{ 'input__append--password-show': !isShowPassword }"
              (click)="togglePasswordVisibility()"
            ></span>
          </div>

          <div class="form-field__error">
            <p class="form-field__error-text">Invalid credentials</p>
          </div>
        </div>

        <button
          [disabled]="signUpForm.invalid"
          type="submit"
          class="btn btn--full-width"
          [ngClass]="{
          'btn--inactive': signUpForm.invalid,
          'btn--active': signUpForm.valid
        }"
        >
          Sign Up
        </button>
      </form>
    </div>

    <p class="modal__text modal__text--footer" (click)="openSignIn()">
      Already have an account?
      <a class="modal__text modal__text--link modal__text--sign-in">Log In</a>
    </p>
  </ng-container>

</app-popup-wrapper>

<app-almost-there></app-almost-there>
