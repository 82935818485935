export class Authentication {
  // tslint:disable-next-line:variable-name
  _username: string;
  // tslint:disable-next-line:variable-name
  _password: string;

  constructor(username, password) {
    this._username = username;
    this._password = password;
  }
}

export class TokenResponse {
  token: string;
  error: string;

  constructor(token: string, error: string) {
    this.error = '';
    this.token = token;
  }
}

