import {Injectable} from '@angular/core';

import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {TrendingInvestorsApiService} from './api/trending-investors-api.service';
import {TrendingInvestor, TrendingInvestors} from '../../interfaces/trending-investor/trending-investor.interface';

@Injectable({providedIn: 'root'})
export class TrendingInvestorsService {
  private investorsTrending$ = new BehaviorSubject<TrendingInvestors>(null);

  constructor(private trendingInvestorsApiService: TrendingInvestorsApiService) {
  }

  loadInvestorsTrendingFromApi(offset = 0): Observable<TrendingInvestor[]> {
    return this.trendingInvestorsApiService.getTrendingInvestors(offset)
      .pipe(
        map((response: TrendingInvestor[]) => {
          if (!response.length) {
            return response;
          }

          const oldInvestorsTrending = this.investorsTrending$.getValue();
          let newInvestorsTrending: TrendingInvestors;

          if (Boolean(oldInvestorsTrending)) {
            newInvestorsTrending = {
              ...oldInvestorsTrending,
              data: [...oldInvestorsTrending.data, ...response],
              offset,
              isCanLoad: !!response.length
            };
            this.setInvestorsTrending(newInvestorsTrending);
          } else {
            newInvestorsTrending = {
              data: [...response],
              offset,
              isCanLoad: !!response.length
            };
            this.setInvestorsTrending(newInvestorsTrending);
          }

          return response;
        }),
        catchError(e => {
          this.setInvestorsTrending(null);

          return EMPTY;
        })
      );
  }

  getInvestorsTrending(): Observable<TrendingInvestors> {
    return this.investorsTrending$.asObservable();
  }

  clearInvestorsTrending(): void {
    this.setInvestorsTrending(null);
  }

  followRequest(userId: number, potentialUsersId: number): Observable<any> {
    this.updateStatusIsFollowedInTrendingInvestors(true, potentialUsersId);
    return this.trendingInvestorsApiService.followRequest(userId, potentialUsersId);
  }

  updateStatusIsFollowedInTrendingInvestors(isFollowed: boolean, potentialUsersId: number): void {
    const oldInvestorsTrending = this.investorsTrending$.getValue();

    const newData: TrendingInvestors = {
      ...oldInvestorsTrending,
      data: oldInvestorsTrending.data.map(investor => {
        if (+investor.id === +potentialUsersId) {
          return {
            ...investor,
            isFollowed: isFollowed
          }
        }

        return investor;
      })
    }

    this.setInvestorsTrending(newData);
  }

  private setInvestorsTrending(data: TrendingInvestors) {
    this.investorsTrending$.next(data);
  }


}
