import {Injectable} from '@angular/core';

import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {catchError, map, switchMap, take} from 'rxjs/operators';

import {ListApiService} from './api/list-api.service';
import {Field} from '../../../components/list/field.enum';
import {ListData} from '../../interfaces/list/list.interface';

@Injectable({providedIn: 'root'})
export class ListService {
  private list$ = new BehaviorSubject<ListData | null>(null);
  private activeField$ = new BehaviorSubject({field: undefined, isDesc: false});

  constructor(private listApiService: ListApiService) {
  }

  activeField(field: Field): void {
    const oldActiveField = this.activeField$.getValue();

    if (oldActiveField.field === field) {
      oldActiveField.isDesc = !oldActiveField.isDesc;
      this.activeField$.next(oldActiveField);
    } else {
      this.activeField$.next({field, isDesc: false});
    }
  }

  loadListFromApi(page?: number): Observable<{data: any[], field: Field | string, isDesc: boolean}> {
    return this.activeField$
      .pipe(
        switchMap(activeField => this.listApiService.loadListFromApi(page, activeField.field, activeField.isDesc)
          .pipe(
            map(response => {
              const oldList = this.list$.getValue();

              if (Boolean(oldList) && response.field === oldList.field && response.isDesc === oldList.isDesc) {
                const newList: ListData = {
                  ...oldList,
                  list: [...oldList.list, ...response.data],
                  pagination: {
                    count: response.data.length,
                    current_page: oldList.pagination.current_page + 1,
                    per_page: 0,
                    total_pages: response.data.length > 0 ? response.data.length / 7 : 0
                  },
                  field: response.field,
                  isDesc: response.isDesc,
                };

                this.setList(newList);
              } else {
                const newList: ListData = {
                  list: [...response.data],
                  pagination: {
                    count: response.data.length,
                    current_page: 0,
                    per_page: 0,
                    total_pages: response.data.length > 0 ? response.data.length / 7 : 0
                  },
                  field: response.field,
                  isDesc: response.isDesc,
                };
                this.setList(null);
                this.setList(newList);
              }

              return response;
            }),
            catchError(e => {
              console.log(e);
              this.setList(null);

              return EMPTY;
            })
          )
        )
      )
  }

  getList(): Observable<ListData> {
    return this.list$.asObservable();
  }

  clearList(): void {
    this.setList(null);
  }

  setList(data: ListData) {
    this.list$.next(data);
  }
}
