<ng-template #popup let-modal>
  <div class="modal-header">
    <img [src]="'assets/icons/orders/grey_cancel.svg'" alt="cancel" (click)="modal.dismiss('dismiss click')" />
  </div>

  <div class="modal-body">
    <ng-content select="[slot=body]"></ng-content>
  </div>

  <div class="modal-footer">
    <ng-content select="[slot=footer]"></ng-content>
  </div>
</ng-template>
