import {createAction, props} from '@ngrx/store';

import {GainersPagination} from '../../../modules/discover/shares/interfaces/gainers/gainers.interface';

export const nextPageGainersAction = createAction(
  '[Gainers] Next Page',
  props<{ pagination: GainersPagination }>()
);

export const nextPageGainersSuccessAction = createAction(
  '[Gainers] Next Page success'
);

export const nextPageGainersFailureAction = createAction(
  '[Gainers] Next Page failure'
);
