<ng-template #popup let-modal>
  <div class="modal-wrapper">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('dismiss click')"
    >
      <span aria-hidden="true">
        <picture>
          <img [src]="'assets/icons/orders/grey_cancel.svg'" alt="cancel" />
        </picture>
      </span>
    </button>

    <picture>
      <img [src]="'assets/images/invite-friends.png'" alt="friends" />
    </picture>

    <div class="title">Earn 2,000 SprinkleBucks</div>

    <div class="description">
      For every user that signs up via your link you get 2,000 SprinkleBucks.
    </div>

    <input class="link" [value]="inviteLink" disabled />
    <input #inviteLinkInput class="link-invisible" [value]="inviteLink" />

    <button
      type="button"
      class="invite-button"
      (click)="copyLink(inviteLinkInput)"
    >
      Copy Link
    </button>
  </div>
</ng-template>
