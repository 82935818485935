import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';

import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {almostThereSelector} from '@store/selectors/user.selector';
import {AlmostThereModel} from '@auth/shared/models/almost.there.model';
import {AlmostThereHelper} from '@auth/shared/helpers/almost.there.helper';
import {PopupWrapperComponent} from '@shared/components/popup-wrapper/popup-wrapper/popup-wrapper.component';

@Component({
  selector: 'app-almost-there',
  templateUrl: './almost-there.component.html',
  styleUrls: ['./almost-there.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlmostThereComponent implements OnInit {
  almostThere$: Observable<AlmostThereModel> = this.store.pipe(select(almostThereSelector));
  @ViewChild(PopupWrapperComponent, {static: false})
  popupWrapperComponent: PopupWrapperComponent;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    AlmostThereHelper.removeEmailFromLocalStorage();
  }

  resendEmail($event, email) {
    console.log('Resend email is a new feature! Need implemented');
  }

  goToHome() {
    this.popupWrapperComponent.close
  }

  openModal() {
    this.popupWrapperComponent.openCentered();
  }

}
