import { Action, createReducer, on } from "@ngrx/store";

import {
  initialSearchState,
  SearchState,
} from "@store/state/search/search.state";
import {
  searchOfStocksSuccess,
  searchOfUsersSuccess,
  cacheStock,
  deleteCachedStock,
  deleteAllCachedStocks,
  cacheUser,
  deleteCachedUser,
  deleteAllCachedUsers,
} from "@store/actions/search/search.actions";
import { userLogout } from "@store/actions/user.actions";

const reducer = createReducer(
  initialSearchState,
  on(searchOfStocksSuccess, (state, { stocks }) => {
    return {
      ...state,
      stocks: [...stocks],
    };
  }),

  on(searchOfUsersSuccess, (state, { users }) => {
    return {
      ...state,
      users: [...users],
    };
  }),

  on(cacheStock, (state, { stock }) => {
    return {
      ...state,
      cachedStocks: state.cachedStocks.find(
        (cachedStock) => cachedStock.id === stock.id
      )
        ? [...state.cachedStocks]
        : [stock, ...state.cachedStocks],
    };
  }),

  on(deleteCachedStock, (state, { stock }) => {
    return {
      ...state,
      cachedStocks: state.cachedStocks.filter(
        (existingStock) => existingStock.id !== stock.id
      ),
    };
  }),

  on(deleteAllCachedStocks, (state) => {
    return {
      ...state,
      cachedStocks: [],
    };
  }),

  on(cacheUser, (state, { user }) => {
    return {
      ...state,
      cachedUsers: state.cachedUsers.find(
        (cachedUser) => cachedUser.id === user.id
      )
        ? [...state.cachedUsers]
        : [user, ...state.cachedUsers],
    };
  }),

  on(deleteCachedUser, (state, { user }) => {
    return {
      ...state,
      cachedUsers: state.cachedUsers.filter(
        (existingUser) => existingUser.id !== user.id
      ),
    };
  }),

  on(deleteAllCachedUsers, (state) => {
    return {
      ...state,
      cachedUsers: [],
    };
  }),

  on(userLogout, (state) => {
    return {
      ...initialSearchState,
    };
  })
);

export function searchReducer(state: SearchState, action: Action) {
  return reducer(state, action);
}
