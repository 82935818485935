import {Action, createReducer, on} from '@ngrx/store';

import {FeedState, initialFeedState} from '@store/state/feed/feed.state';
import {
  deleteFeedFailed,
  deleteFeedSuccess,
  loadInitialState,
  loadMultipleFeedEntriesSuccess,
  loadSingleFeedEntriesSuccess, removeFeedImage, removeFeeds, removeMultipleFeedEntriesSuccess, updateIconLikeFeedPost,
  updateLikeFeedPost,
  updateRequestsParams
} from '@store/actions/feed/feed.actions';
import {userLogout} from '@store/actions/user.actions';
import {uploadFeedImageSuccess} from '@store/actions/profile/profile.actions';

const reducer = createReducer(
  initialFeedState,
  on(loadMultipleFeedEntriesSuccess, (state: FeedState, {multipleFeedEntries}) => {
    return {
      ...state,
      multipleFeedEntries: state.multipleFeedEntries ? [...state.multipleFeedEntries, ...multipleFeedEntries] : [...multipleFeedEntries]
    };
  }),
  on(removeMultipleFeedEntriesSuccess, (state: FeedState) => {
    return {
      ...state,
      multipleFeedEntries: []
    };
  }),
  on(loadSingleFeedEntriesSuccess, (state: FeedState, {singleFeedEntries}) => {
    return {
      ...state,
      singleFeedEntries: state.singleFeedEntries ? [...state.singleFeedEntries, ...singleFeedEntries] : [...singleFeedEntries]
    };
  }),
  on(updateRequestsParams, (state: FeedState, {requestsParams}) => {
    return {
      ...state,
      requestsParams
    };
  }),
  on(updateLikeFeedPost, (state: FeedState, {id, likesCount}) => {
    return {
      ...state,
      multipleFeedEntries: state.multipleFeedEntries ? state.multipleFeedEntries.map(oldMultipleFeed => {
        if (oldMultipleFeed.id === id) {
          return {
            ...oldMultipleFeed,
            likesCount
          };
        }

        return oldMultipleFeed;
      }) : state.multipleFeedEntries
    };
  }),
  on(updateIconLikeFeedPost, (state: FeedState, {id}) => {
    return {
      ...state,
      multipleFeedEntries: state.multipleFeedEntries ? state.multipleFeedEntries.map(oldMultipleFeed => {
        if (oldMultipleFeed.id === id) {
          return {
            ...oldMultipleFeed,
            isLikedByFetcher: !oldMultipleFeed.isLikedByFetcher
          };
        }

        return oldMultipleFeed;
      }) : state.multipleFeedEntries
    };
  }),
  on(loadInitialState, (state: FeedState) => {
    return {
      ...initialFeedState
    };
  }),
  on(deleteFeedSuccess, (state: FeedState, {feedId}) => {
    return {
      ...state,
      multipleFeedEntries: state.multipleFeedEntries.filter(feed => feed.id !== feedId)
    };
  }),
  on(deleteFeedFailed, (state: FeedState, {error}) => {
    return {
      ...state,
      error
    };
  }),
  on(userLogout, state => {
    return {
      ...initialFeedState
    };
  }),
  on(removeFeeds, state => {
    return {
      ...state,
      multipleFeedEntries: null
    };
  }),
  on(uploadFeedImageSuccess, (state, {feedResponse}) => {
    return {
      ...state,
      feedImageData: feedResponse
    };
  }),
  on(removeFeedImage, (state) => {
    return {
      ...state,
      feedImageData: null
    };
  }),
);

export function feedReducer(state: FeedState, action: Action) {
  return reducer(state, action);
}


