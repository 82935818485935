import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsMenuComponent implements OnInit {
  @Input() addToWatchlistUrl: string;
  @Input() shareUrl: string;
  @Input() isShowOnlyAddToWatchList = false;

  @Output() addToWatchlist$: EventEmitter<any> = new EventEmitter<any>();
  @Output() share$: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  addToWatchlist() {
    this.addToWatchlist$.emit();
  }

  share() {
    this.share$.emit();
  }

}
