<div class="wrapper card mq-700-max-w-320" [ngClass]="parentClass">
  <div class="text-center card-title f-s-17 f-c-111 f-w-b l-h-22 l-s-0" *ngIf="title">{{title}}</div>

  <div infiniteScroll
       class="scroll-wrapper"
       [scrollWindow]="false"
       [infiniteScrollUpDistance]="infiniteScrollUpDistance"
       [infiniteScrollDistance]="infiniteScrollDistance"
       [infiniteScrollThrottle]="infiniteScrollThrottle"
       (scrolled)="isScrollPage ? onScroll(ordersPage) : null"
  >
    <ng-content></ng-content>
  </div>
</div>
