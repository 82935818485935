import {FormGroup, ValidatorFn} from '@angular/forms';

export function validateCredentials(form: FormGroup): ValidatorFn {
  Object.keys(form.controls).forEach(key => {
    if (form.controls[key].hasError('backendError')) {
      form.controls[key].setErrors(null);
    }
  });

  return null;
}
