import {createAction, props} from '@ngrx/store';

import {ActivityStockWithUrl} from '@shared/models/watchlist/watchlist.widget.interface';
import {Page} from '@shared/interfaces/page/page.interface';
import {CompanyDetails} from '@shared/models/stock/company-details.interface';

export enum WatchlistActions {
  LoadWatchList = '[WATCHLIST-WIDGET] Load watch list',
  LoadWatchListSuccess = '[WATCHLIST-WIDGET] Load watch list success',
  UpdateWatchListCurrentPageSuccess = '[WATCHLIST-WIDGET] Load watch list current page success',
  ResetData = '[WATCHLIST-WIDGET] Reset data',
  addSongToWatchList = '[Watch list api] Add song to watch list',
  addSongToWatchListSuccess = '[Watch list api] Add song to watch list success',
  addSongToWatchListFailure = '[Watch list api] Add song to watch list failure',
  removeSongFromWatchList = '[Watch list api] Remove song from watch list',
  removeSongFromWatchListSuccess = '[Watch list api] Remove song from watch list success',
  removeSongFromWatchListFailure = '[Watch list api] Remove song from watch list failure',
}

export const loadWatchList = createAction(
  WatchlistActions.LoadWatchList
);

export const loadWatchListSuccess = createAction(
  WatchlistActions.LoadWatchListSuccess,
  props<{ watchlist: ActivityStockWithUrl[] }>()
);

export const updateWatchListCurrentPageSuccess = createAction(
  WatchlistActions.UpdateWatchListCurrentPageSuccess,
  props<Page>()
);

export const resetWatchListData = createAction(
  WatchlistActions.ResetData
);

export const addSongToWatchListAction = createAction(
  WatchlistActions.addSongToWatchList,
  props<{ticker: string}>()
);

export const addSongToWatchListSuccessAction = createAction(
  WatchlistActions.addSongToWatchListSuccess
);

export const addSongToWatchListFailureAction = createAction(
  WatchlistActions.addSongToWatchListFailure
);

export const removeSongFromWatchListAction = createAction(
  WatchlistActions.removeSongFromWatchList,
  props<{song: ActivityStockWithUrl}>()
);

export const removeSongToWatchListSuccessAction = createAction(
  WatchlistActions.removeSongFromWatchListSuccess,
  props<{songId: number}>()
);

export const removeSongToWatchListFailureAction = createAction(
  WatchlistActions.removeSongFromWatchListFailure
);
