<ng-container *ngIf="userInfo; else login">
  <div ngbDropdown class="user-drop-down-wrapper">
    <div class="p-0 toggle-wrapper" id="dropdownBasic4" ngbDropdownToggle>
      <div class="user-avatar-wrapper">
        <picture>
          <img
            class="user-avatar"
            [src]="avatarUrl"
            alt="avatar"
            (error)="error($event)"
          />
        </picture>

        <div class="user-data">
          <div class="user-name">{{ userInfo.name }}</div>

          <div class="user-status premium" *ngIf="userInfo.isPremium">
            {{ "Premium" | uppercase }}
          </div>
        </div>
      </div>
    </div>

    <div ngbDropdownMenu aria-labelledby="dropdownBasic4">
      <div class="profile">
        <picture>
          <img
            class="user-avatar"
            [src]="avatarUrl"
            alt="avatar"
            (error)="error($event)"
          />
        </picture>

        <div class="d-flex flex-column">
          <div class="user-name">
            <span>{{ userInfo.name }}</span>

            <div class="medal-wrapper" *ngIf="userInfo.isVerified">
              <picture>
                <img
                  [src]="'assets/icons/profile/ico_medal.svg'"
                  alt="user-medal"
                />
              </picture>
            </div>
          </div>
          <button
            class="profile-link"
            ngbDropdownItem
            (click)="goToProfile(userInfo.id)"
          >

            View Profile
          </button>
        </div>
      </div>

      <div class="actions">
        <div ngbDropdownItem class="action" (click)="openInviteFriends()">
          <div>
            <picture>
              <img
                [src]="'assets/icons/header/portfolio/invite.svg'"
                alt="user-medal"
              />
            </picture>
            <button>Invite Friends</button>
          </div>
          <span>Get 2,000 SprinkleBucks</span>
        </div>

        <div ngbDropdownItem class="action">
          <div>
            <picture>
              <img
                [src]="'assets/icons/header/portfolio/question.svg'"
                alt="user-medal"
              />
            </picture>
            <a href="mailto:support@sprinklebit.com">Help and Feedback</a>
          </div>
        </div>

        <div ngbDropdownItem class="action" (click)="logout()">
          <div>
            <picture>
              <img
                [src]="'assets/icons/header/portfolio/logout.svg'"
                alt="user-medal"
              />
            </picture>
            <button>Log Out</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-invite-friends [inviteLink]="inviteData?.inviteLink"></app-invite-friends>
</ng-container>

<ng-template #login>
  <ng-content></ng-content>
</ng-template>
