<ng-container *ngIf="typeForRequest$ | async as typeForRequest">
  <div class="body" *ngIf="findUsers?.length;">
    <ul>
      <li *ngFor="let user of findUsers" (click)="addUser(user, typeForRequest)">
        <img [src]="getAvatarUrl(user.has_avatar, user.id)"
             alt="{{user.name}}"
             (error)="error($event)"
        >

        <div class="f-s-14 l-h-17 f-c-333 f-w-b info-wrapper">
          {{user.name}}
        </div>
      </li>
    </ul>
  </div>
</ng-container>

