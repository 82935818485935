import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header.component';
import { SearchComponent } from './components/search/search.component';
import { UserNotificationsListComponent } from './components/user-notifications-list/user-notifications-list.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { InviteFriendsComponent } from './components/invite-friends/invite-friends.component';
import { UserNotificationsDropdownComponent } from './components/user-notifications-dropdown/user-notifications-dropdown.component';
import { UserNotificationItemComponent } from './components/user-notification-item/user-notification-item.component';
import {DirectivesModule} from '@shared/directives/directives.module';
import {ShareModule} from '@shared/share.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NgbCollapseModule,
        NgbTypeaheadModule,
        NgbDropdownModule,
        ReactiveFormsModule,
        DirectivesModule,
        ShareModule,
    ],
  exports: [HeaderComponent],
  declarations: [
    HeaderComponent,
    SearchComponent,
    UserNotificationsListComponent,
    UserInfoComponent,
    InviteFriendsComponent,
    UserNotificationsDropdownComponent,
    UserNotificationItemComponent,
  ],
})
export class HeaderModule {}
