import {createAction, props} from '@ngrx/store';

import {SearchStock} from '@shared/models/search/search-stock.interface';
import {UserDetails} from '@shared/models/search/user-details.interface';

enum SearchActions {
  SearchOfStocks = "[Search] Search of stocks",
  SearchOfStocksSuccess = "[Search] Search of stocks success",
  SearchOfStocksFailure = "[Search] Search of stocks failure",
  SearchOfUsers = "[Search] Search of users",
  SearchOfUsersSuccess = "[Search] Search of users success",
  SearchOfUsersFailure = "[Search] Search of users failure",

  CacheStock = "[Search] Cache Stock",
  DeleteCachedStock = "[Search] Delete Cached Stock",
  DeleteAllCachedStocks = "[Search] Delete All Cached Stocks",
  CacheUser = "[Search] Cache User",
  DeleteCachedUser = "[Search] Delete Cached User",
  DeleteAllCachedUsers = "[Search] Delete All Cached Users",
}

export const searchOfStocks = createAction(SearchActions.SearchOfStocks);

export const searchOfStocksSuccess = createAction(
  SearchActions.SearchOfStocksSuccess,
  props<{ stocks: SearchStock[] }>()
);

export const searchOfStocksFailure = createAction(
  SearchActions.SearchOfStocksFailure
);

export const searchOfUsers = createAction(SearchActions.SearchOfUsers);

export const searchOfUsersSuccess = createAction(
  SearchActions.SearchOfUsersSuccess,
  props<{ users: UserDetails[] }>()
);

export const searchOfUsersFailure = createAction(
  SearchActions.SearchOfUsersFailure
);

export const cacheStock = createAction(
  SearchActions.CacheStock,
  props<{ stock: SearchStock }>()
);

export const deleteCachedStock = createAction(
  SearchActions.DeleteCachedStock,
  props<{ stock: SearchStock }>()
);

export const deleteAllCachedStocks = createAction(
  SearchActions.DeleteAllCachedStocks
);

export const cacheUser = createAction(
  SearchActions.CacheUser,
  props<{ user: UserDetails }>()
);

export const deleteCachedUser = createAction(
  SearchActions.DeleteCachedUser,
  props<{ user: UserDetails }>()
);

export const deleteAllCachedUsers = createAction(
  SearchActions.DeleteAllCachedUsers
);
