import {Action, createReducer, on} from '@ngrx/store';

import {initialTagState, TagState} from '@store/state/tag/tag.state';
import {
  addStockToArrayAction,
  addStockToTagAction,
  addTagSymbolAction,
  addUserToArrayAction,
  addUserToTagAction,
  removeStockFromTagAction,
  removeTagSymbolAction,
  removeUserFromTagAction,
  updateInputContentAction
} from '@store/actions/tag/tag.actions';
import {TagSymbols} from '@shared/enums/tag/tag-symbols.enum';

const reducer = createReducer(
  initialTagState,
  on(addUserToTagAction, (state: TagState, {user}) => {
    return {
      ...state,
      user
    };
  }),
  on(removeUserFromTagAction, (state: TagState) => {
    return {
      ...state,
      user: null
    };
  }),
  on(addUserToArrayAction, (state: TagState, {user}) => {
    return {
      ...state,
      users: state.users ? [...state.users, user] : [user]
    };
  }),
  on(addStockToArrayAction, (state, {stock}) => {
    return {
      ...state,
      stocks: state.stocks ? [...state.stocks, stock] : [stock]
    };
  }),
  on(addStockToTagAction, (state, {stock}) => {
    return {
      ...state,
      stock
    };
  }),
  on(removeStockFromTagAction, (state) => {
    return {
      ...state,
      stock: null
    };
  }),
  on(addTagSymbolAction, (state: TagState, {symbol}) => {
    return {
      ...state,
      symbol
    };
  }),
  on(removeTagSymbolAction, (state: TagState) => {
    return {
      ...state,
      symbol: TagSymbols.DEFAULT
    };
  }),
  on(updateInputContentAction, (state: TagState, {text}) => {
    return {
      ...state,
      inputsContent: text
    };
  })
);

export function tagReducer(state: TagState, action: Action) {
  return reducer(state, action);
}
