import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberRoundUp'
})
export class NumberRoundUpPipe implements PipeTransform {
  transform(value: number): any {
    return (Math.ceil(value * 100) / 100).toFixed(2);
  }
}
