import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '@env/environment';
import {Gainer} from '../../../interfaces/gainers/gainers.interface';
import {Field} from '../../../../components/list/field.enum';

@Injectable({providedIn: 'root'})
export class ListApiService {
  private readonly BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  loadListFromApi(offset: number = 0, field: string | undefined, isDesc: boolean): Observable<{data: any[], field: Field | string, isDesc: boolean}> {
    const descValue = isDesc ? 'DESC' : 'ASC';

    let params = new HttpParams();
    params = params.append('offset', offset.toString());
    params = params.append('limit', '10');
    if (field) {
      params = params.append('field', field.toString());
    }
    params = params.append('order', descValue.toString());

    return this.http
      .get(this.BASE_URL + `/api/v4/stock/screener.json`, {params})
      .pipe(
        map((response: any) => {
          return {
            data: response.data.length ? [...response.data.map(el => ({...el, dividendYields: el.dividendYields * 100}))] : [],
            field: field ?? '',
            isDesc
          }
        })
      );
  }
}
