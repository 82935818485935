import {OrderPage} from '@shared/models/orders/orders.widget.interface';
import {OrderForWidget} from '@shared/models/orders/api/orders.response.interface';
import {OrderBrokerage} from '../../../modules/brokerage/shares/interfaces/orders/order-brokerage.interface';

export interface OrdersState {
  orders: OrderForWidget[];
  ordersBrokerage: OrderBrokerage[];
  pages: OrderPage;
}

export const initialOrdersState: OrdersState = {
  orders: null,
  ordersBrokerage: null,
  pages: {
    current: 0,
  },
};
