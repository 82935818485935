import {Injectable} from '@angular/core';

import {BehaviorSubject, EMPTY, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {TrendingStocks} from '../../interfaces/trending-stock/trending-stock.interfaces';
import {TrendingStocksApiService} from './api/trending-stocks-api.service';

@Injectable({providedIn: 'root'})
export class TrendingStocksService {
  private stocksTrending$ = new BehaviorSubject<TrendingStocks>(null);

  constructor(private trendingStocksApiService: TrendingStocksApiService) {
  }

  loadStockTrendingFromApi(offset = 0): Observable<TrendingStocks> {
    return this.trendingStocksApiService.getStockTrending(offset)
      .pipe(
        map(response => {
          const oldStocksTrending = this.stocksTrending$.getValue();
          let newStocksTrending: TrendingStocks;

          if (Boolean(oldStocksTrending)) {
            newStocksTrending = {
              ...oldStocksTrending,
              data: [...oldStocksTrending.data, ...response],
              offset,
              isCanLoad: !!response.length
            };
            this.setStocksTrending(newStocksTrending);
          } else {
            newStocksTrending = {
              data: [...response],
              offset,
              isCanLoad: !!response.length
            };
            this.setStocksTrending(newStocksTrending);
          }

          return newStocksTrending;
        }),
        catchError(e => {
          this.setStocksTrending(null);

          return EMPTY;
        })
      );
  }

  getStockTrending(): Observable<TrendingStocks> {
    return this.stocksTrending$.asObservable();
  }

  clearStocksTrending(): void {
    this.setStocksTrending(null);
  }

  private setStocksTrending(data: TrendingStocks) {
    this.stocksTrending$.next(data);
  }


}
