import {Action, createReducer, on} from '@ngrx/store';

import {CountriesState, initialCountriesState} from '@store/state/countries/countries.state';
import {getCountriesSuccess} from '@store/actions/countries/countries.actions';

const reducer = createReducer(
  initialCountriesState,
  on(getCountriesSuccess, (state, {countries}) => {
    return {
      ...state,
      countries: [...countries]
    };
  })
);

export function countriesReducer(state: CountriesState, action: Action) {
  return reducer(state, action);
}
