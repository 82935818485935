import {Label} from 'ng2-charts';
import * as moment from 'moment';


import {PortfolioChartPeriods} from '@shared/components/chart/shared/enums/portfolio-chart.periods.enum';
import {ChartValueAndUnixTimeFromApi} from '@shared/models/chart/chart.data.response.interface';
import {ChartConvertData, ChartDataInterface} from '@shared/models/chart/chart.interface';

export class ChartHelper {
  // Months array
  static monthsArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  static convertDate(unixTimestamp: number): string {
    // Convert timestamp to milliseconds
    const date = new Date(unixTimestamp * 1000);

    // Year
    const year = date.getFullYear();

    // Month
    const month = ChartHelper.monthsArr[date.getMonth()];

    // Day
    const day = date.getDate();

    // Hours
    const hours = date.getHours();

    // Minutes
    const minutes = '0' + date.getMinutes();

    // Seconds
    const seconds = '0' + date.getSeconds();

    // Display date time in MM-dd-yyyy h:m:s format
    const converterDataTime = month + '-' + day + '-' + year + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    return converterDataTime;
  }

  static getChartData(chartData: ChartValueAndUnixTimeFromApi[], activeLoadedPeriod: string): ChartConvertData {
    const lineChartLabels: Label[] = [];
    const data: number[] = [];

    chartData.forEach(el => {
      const lineChartLabel = ChartHelper.convertDate(el.u);

      lineChartLabels.push(lineChartLabel);
      data.push(el.v);
    });

    return {
      lineChartLabels,
      data,
      activePeriod: activeLoadedPeriod
    };
  }

  static getChartPeriods(): ChartDataInterface[] {
    const chartPeriods: ChartDataInterface[] = [
      {label: PortfolioChartPeriods.DAY, name: 'D'},
      {label: PortfolioChartPeriods.WEEK, name: 'W'},
      {label: PortfolioChartPeriods.MONTH, name: 'M'},
      {label: PortfolioChartPeriods.THREE_MONTH, name: '3M'},
      {label: PortfolioChartPeriods.YEAR, name: 'Y'},
      {label: PortfolioChartPeriods.ALL, name: 'ALL'}
    ];

    return chartPeriods;
  }

  static getMothAndYear(date: string): string {
    const tempMonth = date.slice(0, 3);
    const tempYear = date.slice(date.length - 5, date.length);

    return tempMonth + tempYear;
  }

  static getDaysOfWeekAgoFromNow(): Array<string> {
    const days: string[] = [];

    for (let i = 0; i < 6; i++) {
      days.push(ChartHelper.convertDate(moment().subtract(i, 'day').unix()).slice(0, 11));
    }

    return days;
  }

  static isDateAreEqual(desiredDate: string, chartElement: string): boolean {
    const convertedDesiredDate = ChartHelper.getMothAndYear(desiredDate);
    const convertedChartElement = ChartHelper.getMothAndYear(chartElement.slice(0, 11));

    return convertedChartElement === convertedDesiredDate;
  }

}
