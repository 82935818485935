import {createAction, props} from '@ngrx/store';

import {FeedPost} from '@shared/models/feed/feed-post.interface';
import {RequestParamsFeed} from '@shared/models/feed/request/request-params-feed.interface';
import {FeedRequestForHome} from '@shared/models/feed/request/feed-request-for-home.interface';
import {FeedRequestForProfile} from '@shared/models/feed/request/feed-request-for-profile.interface';

enum FeedActions {
  LoadMultipleFeedEntries = '[Feed Api] Load multiple feed entries',
  LoadMultipleFeedEntriesSuccess = '[Feed Api] Load multiple feed entries success',
  LoadSingleFeedEntries = '[Feed Api] Load single feed entries',
  LoadSingleFeedEntriesSuccess = '[Feed Api] Load single feed entries success',
  UpdateRequestsParams = '[Feed] Update requests params',
  UpdateLikeFeedPost = '[Feed] Update like feed post',
  UpdateIconLikeFeedPost = '[Feed] Update icon like feed post',
  LoadInitialState = '[Feed] Load initial state',
  DeleteFeed = '[Feed API] Delete feed',
  DeleteFeedSuccess = '[Feed API] Delete feed Success',
  DeleteFeedFailed = '[Feed API] Delete feed FAILED',
  RemoveFeeds = '[Feeds] Remove feed from store'
}

export const loadMultipleFeedEntries = createAction(FeedActions.LoadMultipleFeedEntries);
export const loadSingleFeedEntries = createAction(FeedActions.LoadSingleFeedEntries);

export const loadMultipleFeedEntriesSuccess = createAction(
  FeedActions.LoadMultipleFeedEntriesSuccess,
  props<{ multipleFeedEntries: FeedPost[] }>()
);

export const removeMultipleFeedEntriesSuccess = createAction('Remove Multiple Feed Entries Success');

export const loadSingleFeedEntriesSuccess = createAction(
  FeedActions.LoadSingleFeedEntriesSuccess,
  props<{ singleFeedEntries: any[] }>()
);

export const updateRequestsParams = createAction(
  FeedActions.UpdateRequestsParams,
  props<{ requestsParams: RequestParamsFeed | FeedRequestForHome | FeedRequestForProfile }>()
);

export const updateLikeFeedPost = createAction(
  FeedActions.UpdateLikeFeedPost,
  props<{ id: number, likesCount: number }>()
);

export const updateIconLikeFeedPost = createAction(
  FeedActions.UpdateIconLikeFeedPost,
  props<{ id: number}>()
);

export const loadInitialState = createAction(
  FeedActions.LoadInitialState
);

export const deleteFeed = createAction(
  FeedActions.DeleteFeed,
  props<{feedId: number}>()
);

export const deleteFeedSuccess = createAction(
  FeedActions.DeleteFeedSuccess,
  props<{feedId: number}>()
);

export const removeFeeds = createAction(
  FeedActions.RemoveFeeds
);

export const deleteFeedFailed = createAction(
  FeedActions.DeleteFeedFailed,
  props<{error: any}>()
);

export const removeFeedImage = createAction(
  'Remove Feed Image'
);
