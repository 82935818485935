import {Action, createReducer, on} from '@ngrx/store';

import {initialTickerPositionState, TickerPositionState} from '../../state/ticker-position/ticker-position.state';
import {loadTickerPositionSuccess} from '../../actions/ticker-position/ticker-position.actions';
import {userLogout} from '@store/actions/user.actions';

const reducer = createReducer(
  initialTickerPositionState,
  on(loadTickerPositionSuccess, (state, {position}) => {
    return {
      ...state,
      position
    };
  }),
  on(userLogout, state => {
    return {
      ...initialTickerPositionState
    };
  })
);

export function tickerPositionReducer(state: TickerPositionState, action: Action) {
  return reducer(state, action);
}
