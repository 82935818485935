import {PortfolioWidget} from '@shared/models/portfolio/portfolio.widget.interface';
import {PositionWidget} from '@shared/models/positions/position.widget.interface';
import {NewsWidget} from '@shared/models/news/news.widget.interface';
import {PortfoliosList} from '@shared/models/portfolio/portfolios-list.interface';

export interface PortfolioState {
  portfolioWidget: PortfolioWidget | null;
  positionsWidget: PositionWidget | null;
  relatedNewsWidget: NewsWidget | null;
  portfoliosList: PortfoliosList | null;
}

export const initialPortfolioState: PortfolioState = {
  portfolioWidget: null,
  positionsWidget: null,
  relatedNewsWidget: null,
  portfoliosList: null
};
