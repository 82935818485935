import {createAction, props} from '@ngrx/store';

import {OrderForWidget} from '@shared/models/orders/api/orders.response.interface';
import {OrderBrokerage} from '../../../modules/brokerage/shares/interfaces/orders/order-brokerage.interface';
import {Order} from '../../../modules/brokerage/shares/interfaces/orders/order.interface';

enum OrdersActions {
  LoadOrders = '[ORDERS-WIDGET] Load orders',
  LoadOrdersFailure = '[ORDERS-WIDGET] Load Orders failure',
  UpdateOrdersCurrentPage = '[ORDERS-WIDGET] Update orders current page',
  CancelOrder = '[ORDERS-WIDGET] Cancel order',
  CancelOrderSuccess = '[ORDERS-WIDGET] Cancel order success',
  CancelOrderBrokerageSuccess = '[ORDERS-WIDGET] Cancel order brokerage success',
  CancelOrderBrokerageUpdateStatus = '[ORDERS-WIDGET] Cancel order Update Status',
  CancelOrderBrokerageFailure = '[ORDERS-WIDGET] Cancel order Brokerage failure',
  CancelOrderFailure = '[ORDERS-WIDGET] Cancel order failure',
  Destroy = '[ORDERS-WIDGET] Destroy',
  LoadOrdersSuccessful = '[ORDERS-WIDGET] Load orders successful',
  ResetOrderData = '[ORDERS-WIDGET] Reset order data',
}

export const loadOrders = createAction(
  OrdersActions.LoadOrders
);

export const loadOrdersSuccess = createAction(
  OrdersActions.LoadOrdersSuccessful,
  props<{ orders: OrderForWidget[] }>()
);

export const loadOrdersBrokerageSuccess = createAction(
  OrdersActions.LoadOrdersSuccessful,
  props<{ orders: OrderBrokerage[] }>()
);

export const loadOrderFailure = createAction(
  OrdersActions.LoadOrdersFailure
);

export const updateOrdersCurrentPage = createAction(
  OrdersActions.UpdateOrdersCurrentPage,
  props<{ current: number }>()
);

export const cancelOrder = createAction(
  OrdersActions.CancelOrder
);

export const cancelOrderSuccess = createAction(
  OrdersActions.CancelOrderSuccess,
  props<{order: OrderForWidget}>()
);
export const cancelOrderBrokerageSuccess = createAction(
  OrdersActions.CancelOrderBrokerageSuccess,
  props<{order: Order}>()
);

export const cancelOrderBrokerageUpdateStatus = createAction(
  OrdersActions.CancelOrderBrokerageUpdateStatus,
  props<{history: OrderBrokerage}>()
);

export const CancelOrderFailure = createAction(
  OrdersActions.CancelOrder
);

export const CancelOrderBrokerageFailure = createAction(
  OrdersActions.CancelOrderBrokerageFailure
);

export const destroyOrders = createAction(
  OrdersActions.Destroy
);

export const resetOrdersData = createAction(
  OrdersActions.ResetOrderData
);
