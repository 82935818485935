import {OrderDialogueSuccess} from '@shared/models/order-dialogue/order-dialogue-success.interface';
import {OrderTab} from '../../../modules/symbol/components/order-dialog/shared/enums/order.tab.enum';
import {OrdersDialogueType} from '../../../modules/symbol/components/order-dialog/shared/enums/orders-dialogue.type.enum';
import {OrderDialogueForm} from '../../../modules/symbol/components/order-dialog/shared/interfaces/order-dialogue-buy-form.interface';
import {OrderDialogueStep} from '../../../modules/symbol/components/order-dialog/shared/enums/order-dialogue-buy.step.enum';
import {OrderDialogResponse} from '@shared/interfaces/order-dialog/order-dialog-response.interface';

export interface OrderDialogueState {
  activeTab: OrderTab;
  orderType: OrdersDialogueType;
  availableBuyingPower: any;
  buyForm: OrderDialogueForm;
  step: OrderDialogueStep;
  confirmOrder: OrderDialogResponse;
}

export const initialOrderDialogueState: OrderDialogueState = {
  activeTab: OrderTab.BUY,
  orderType: OrdersDialogueType.MARKET_ORDER,
  availableBuyingPower: null,
  step: OrderDialogueStep.INITIAL,
  buyForm: null,
  confirmOrder: null
};
