import { createAction, props } from '@ngrx/store';

import { AlmostThereModel } from '@auth/shared/models/almost.there.model';
import { User } from '@shared/models/user/user.interface';
import { UserInfo } from '@shared/models/user/user.info.interface';
import { UserNotification } from '@shared/models/user/user.notification.interface';
import { InviteData } from '@shared/models/user/user.invite.interface';
import { UserNotificationList } from '@shared/models/user/user.notification.list.interface';
import { UserFriend } from '@shared/models/user/user.friend.interface';
import {ForgotPasswordDto} from "@auth/shared/dto/forgot-password.dto";

export enum UserActions {
  GetUser = '[USER] Get User',
  GetUserSuccess = '[USER] Get User Success',
  GetUserInviteSuccess = '[USER] Get User Invite Data Success',
  GetUserFriendsSuccess = '[USER] Get User Friends Success',

  updateUserInfoSuccess = '[USER] Update User Info Success',
  updateUserEmailSuccess = '[USER] Update User Email Success',

  forgotPasswordRequest = '[USER] Forgot password request',
  forgotPasswordRequestSuccess = '[USER] Forgot password request Success',
  forgotPasswordRequestError = '[USER] Forgot password request Error',

  getUserNotificationSuccess = '[USER] Get User Notification Success',
  getUserNotificationListSuccess = '[USER] Update User Notification List Success',

  setUserNotificationRead = '[USER] Set User Notification Read',
  setUserNotificationReadSuccess = '[USER] Set User Notification Read Success',
  setUserNotificationReadError = '[USER] Set User Notification Read Error',

  userLogout = '[USER] logout',
}

export const getUser = createAction(UserActions.GetUser);

export const getUserSuccess = createAction(
  UserActions.GetUserSuccess,
  props<{ user: User }>()
);

export const getUserInviteSuccess = createAction(
  UserActions.GetUserInviteSuccess,
  props<{ inviteData: InviteData }>()
);

export const getUserFriendsSuccess = createAction(
  UserActions.GetUserFriendsSuccess,
  props<{ userFriends: UserFriend[] }>()
);

export const updateUserInfoSuccess = createAction(
  UserActions.updateUserInfoSuccess,
  props<{ userInfo: UserInfo }>()
);

export const getUserNotificationSuccess = createAction(
  UserActions.getUserNotificationSuccess,
  props<{ userNotification: UserNotification }>()
);

export const getUserNotificationListSuccess = createAction(
  UserActions.getUserNotificationListSuccess,
  props<{ userNotificationList: UserNotificationList }>()
);

export const setUserNotificationRead = createAction(
  UserActions.setUserNotificationRead,
  props<{ notificationId: number }>()
);

export const setUserNotificationReadSuccess = createAction(
  UserActions.setUserNotificationReadSuccess,
  props<{ notificationId: number }>()
);

export const setUserNotificationReadError = createAction(
  UserActions.setUserNotificationReadError,
  props<{ err: any }>()
);

export const updateUserEmailSuccess = createAction(
  UserActions.updateUserEmailSuccess,
  props<{ almostThere: AlmostThereModel }>()
);

export const forgotPasswordRequest = createAction(
  UserActions.forgotPasswordRequest,
  props<ForgotPasswordDto>()
);

export const forgotPasswordRequestError = createAction(
  UserActions.forgotPasswordRequestError,
  props<{ errors: any[] }>()
);

export const forgotPasswordRequestSuccess = createAction(
  UserActions.forgotPasswordRequestSuccess,
  props<{ almostThere: AlmostThereModel }>()
);

export const userLogout = createAction(UserActions.userLogout);
