import {createAction, props} from '@ngrx/store';
import {LosersPagination} from '../../../modules/discover/shares/interfaces/losers/losers.interface';


export const nextPageLosersAction = createAction(
  '[Losers] Next Page',
  props<{ pagination: LosersPagination }>()
);

export const nextPageLosersSuccessAction = createAction(
  '[Losers] Next Page success'
);

export const nextPageLosersFailureAction = createAction(
  '[Losers] Next Page failure'
);
