import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, share, shareReplay} from 'rxjs/operators';

import * as moment from 'moment';

import {PortfolioChartPeriods} from '@shared/components/chart/shared/enums/portfolio-chart.periods.enum';
import {ChartDataWithPeriod} from '@shared/models/chart/chart.interface';
import {PortfolioChartDataResponse} from '@shared/models/chart/chart.data.response.interface';
import {StockChartDataApiResponse} from '@shared/models/chart/stock/stock-chart-data-api-response.interface';
import {StockChartDataApi} from '@shared/models/chart/stock/stock-chart-data-api.interface';

@Injectable({providedIn: 'root'})
export class ChartApiService {
  private readonly BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getPortfolioChartData(portfolioId: number, period: PortfolioChartPeriods = PortfolioChartPeriods.ALL): Observable<ChartDataWithPeriod> {
    return this.http.get(this.BASE_URL + `/api/v3/user/portfolio/graph.json?portfolioId=${portfolioId}&period=${period}`)
      .pipe(
        map((response: PortfolioChartDataResponse) => {
          return {
            ...response,
            activePeriod: period
          };
        })
      );
  }

  getStockChartData(stockName: string, startPeriod: number = 0): Observable<StockChartDataApi[]> {
    const timeStampNow = moment().unix();

    return this.http.get(this.BASE_URL + `/api/v3/stock/graph/${startPeriod}/${timeStampNow}/${stockName}.json`)
      .pipe(
        map((response: StockChartDataApiResponse) => response.data),

      );
  }

}
