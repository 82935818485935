import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ProfileState} from '@store/state/profile/profile.state';
import {userInfoSelector} from '@store/selectors/user.selector';

const profileFeatureSelector = createFeatureSelector<ProfileState>('profile');

export const profileInfoSelector = createSelector(
  profileFeatureSelector,
  state => state.profileInfo
);

export const profileActiveTabSelector = createSelector(
  profileFeatureSelector,
  state => state.activeTab
);

export const connectionsActiveTabSelector = createSelector(
  profileFeatureSelector,
  state => state.connectionsNav
);

export const portfolioTypeSelector = createSelector(
  profileFeatureSelector,
  state => state.portfolioType
);

export const selectedPortfolioSelector = createSelector(
  profileFeatureSelector,
  state => state.selectedPortfolio
);

export const accountHolderSelector = createSelector(
  profileFeatureSelector,
  state => state.accountHolder
);

export const profileIdForOtherUserByTypeBrokerageSelector = createSelector(
  profileFeatureSelector,
  state => state.profileIdForOtherUserByTypeBrokerage
)

export const combineSelectorTest = createSelector(
  profileIdForOtherUserByTypeBrokerageSelector,
  profileInfoSelector,
  userInfoSelector,
  (foundProfileId, profileInfo, userInfo) =>
    ({foundProfileId, profileInfo, userInfo})
)

export const selectUserIdSelector = createSelector(
  profileFeatureSelector,
  state => state.userId
)
