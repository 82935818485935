import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';

import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Store } from '@ngrx/store';

import { PopupWrapperComponent } from '@shared/components/popup-wrapper/popup-wrapper/popup-wrapper.component';
import { ProfileForComponent } from '@shared/models/profile/profile-for-component.interface';

import { UserInfo } from '@shared/models/user/user.info.interface';
import { ProfileService } from '@shared/services/profile/profile.service';
import { Country } from '@shared/models/countries/country.interface';
import { ProfileAccountHolder } from '@shared/models/profile/profile-account-holder.interface';
import { updateSettingsAccountHolder } from '@store/actions/profile/profile.actions';
import { AccountHolderInfoForServer } from '@shared/models/profile/account-holder-info-for-server.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-invite-friends',
  templateUrl: './invite-friends.component.html',
  styleUrls: ['./invite-friends.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InviteFriendsComponent implements OnInit {
  @ViewChild('popup', { static: false })
  popup: ElementRef;

  @Input() inviteLink: string;

  @Output() dismiss$ = new EventEmitter();

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  copyLink(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  openCentered() {
    const modalRef = this.modalService.open(this.popup, {
      centered: true,
      backdropClass: 'popup-background',
    });

    modalRef.result.then(
      (close) => {
        // console.log('close => ', close);
        this.dismiss$.emit();
      },
      (dismiss) => {
        // console.log('dismiss => ', dismiss);
        this.dismiss$.emit();
      }
    );
  }

  dismiss() {
    this.modalService.dismissAll('dismiss');
  }

  close() {
    this.modalService.dismissAll('close');
  }
}
