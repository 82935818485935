import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';
import {UserInfo, UserInfoResponse} from '@shared/models/user/user.info.interface';
import { UserNotification } from '@shared/models/user/user.notification.interface';
import { InviteData } from '@shared/models/user/user.invite.interface';
import { UserNotificationList } from '@shared/models/user/user.notification.list.interface';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(private http: HttpClient) {}

  getUserInfoFromApi(): Observable<UserInfo> {
    return this.http
      .get(`${environment.apiUrl}/api/v3/user/info.json`)
      .pipe(map((response: UserInfoResponse) => response.data));
  }

  getUserNotificationFromApi(): Observable<UserNotification> {
    return this.http
      .get(`${environment.apiUrl}/api/v3/user/notification.json`)
      .pipe(map((response: UserNotification) => response));
  }

  getUserNotificationListFromApi(): Observable<UserNotificationList> {
    return this.http
      .get(`${environment.apiUrl}/api/v3/user/notification/list.json`)
      .pipe(map((response: UserNotificationList) => response));
  }

  /**
   * Send request to friend
   * @param userId - id of the friend Request
   */
  sendRequestToFriend(userId: number): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/v2/user/friend-request/send`, { userId })
      .pipe(map((data) => data));
  }

  /**
   * Accept request to friend
   * @param userId - id of the friend Request
   */
  acceptRequestToFriend(friendRequestId: number): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/v2/user/friend-request/accept`, {
        friendRequestId,
      })
      .pipe(map((data) => data));
  }

  /**
   * Reject request to friend
   * @param userId - id of the friend Request
   */
  rejectRequestToFriend(friendRequestId: number): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/v2/user/friend-request/reject`, {
        friendRequestId,
      })
      .pipe(map((data) => data));
  }

  sendRequestToUnfriend(friendRequestId: number): Observable<any> {
    return this.http
      .delete(
        `${environment.apiUrl}/api/v3/friends.json?userIds=${friendRequestId}`
      )
      .pipe(map((data) => data));
  }

  getUserFriends(userId: number): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/v3/user/${userId}/friends.json`)
      .pipe(map((data) => data));
  }

  sendRequestToFollow(userId: number): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/v2/user/follow-request/send`, { userId })
      .pipe(map((data) => data));
  }

  deleteRequestToUnfriend(userId: number): Observable<any> {
    return this.http
      .delete(`${environment.apiUrl}/api/v3/following.json?id=${userId}`)
      .pipe(map((data) => data));
  }

  getUserInviteLink(userId: number): Observable<InviteData> {
    return this.http
      .post(`${environment.apiUrl}/api/v2/user/invite/stats`, { userId })
      .pipe(map((response: any) => response.body));
  }

  setUserNotificationRead(notificationId: number): Observable<InviteData> {
    return this.http
      .post(`${environment.apiUrl}/api/v3/user/notification/read.json`, { notificationId })
      .pipe(map((response: any) => response.body));
  }
}
