<div class="row">
  <div class="col text-right">
    <div ngbDropdown class="d-inline-block" [placement]="['bottom-right', 'top-right']">
      <div class="dropdown" id="dropdownBasic3" ngbDropdownToggle>
        <div class="point-wrapper">
          <div class="point"></div>
          <div class="point"></div>
          <div class="point"></div>
        </div>
      </div>

      <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
        <button ngbDropdownItem (click)="addToWatchlist()">
          <img
            class="mr-2 trade"
            [src]="addToWatchlistUrl"
            alt="trade"
          />

          <span class="f-s-12 l-h-16 f-c-111">Add to Watchlist</span>
        </button>

        <button ngbDropdownItem (click)="share()" appTextCopy *ngIf="!isShowOnlyAddToWatchList">
          <img
            class="mr-2 share"
            [src]="shareUrl"
            alt="share"
          />

          <span class="f-s-12 l-h-16 f-c-111">Share</span>
        </button>

      </div>
    </div>
  </div>
</div>
