import {Injectable} from '@angular/core';

import {combineLatest, from, Observable, throwError} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, share, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {select, Store} from '@ngrx/store';

import {portfolioIdWidgetSelector} from '@store/selectors/portfolio/widgest/portfolio.selector';
import {ChartApiService} from '@shared/services/chart/api/chart.api.service';
import {ChartHelper} from '@shared/components/chart/shared/helpers/chart.helper';
import {ChartConvertData, ChartDataInterface, ChartDataWithPeriod} from '@shared/models/chart/chart.interface';
import {
  getPortfolioChartDataSuccess,
  getStockChartDataSuccess,
  setPortfolioChartAvailablePeriodsSuccess
} from '@store/actions/chart/chart.actions';
import {StockChartDataComponent} from '@shared/models/chart/stock/stock-chart-data-component.interface';
import {StockChartHelper} from '@shared/components/chart/shared/helpers/stock.chart.helper';
import {StockChartPeriods} from '@shared/components/chart/shared/enums/stock-chart-periods.enum';
import {portfolioActiveChartPeriodSelector, stockActiveChartSelector} from '@store/selectors/chart/chart.selector';
import {PortfolioChartPeriods} from '@shared/components/chart/shared/enums/portfolio-chart.periods.enum';
import {PortfolioValues} from '@shared/models/portfolio/portfolio.widget.interface';
import {selectRouter} from '@store/selectors/router.selector';

@Injectable({providedIn: 'root'})
export class ChartService {
  portfolioValues$: Observable<PortfolioValues> = this.store.pipe(select(portfolioIdWidgetSelector));

  constructor(private store: Store,
              private chartApiService: ChartApiService) {
  }

  getPortfolioChartValues(portfolioId?: number): Observable<ChartDataWithPeriod> {
    return combineLatest([this.portfolioValues$,
      this.store.pipe(select(portfolioActiveChartPeriodSelector))
    ]).pipe(
      filter(([values, activePeriod]) => Boolean(values) || Boolean(portfolioId)),
      switchMap(([values, activePeriod]) => {
        const period: PortfolioChartPeriods = activePeriod ? activePeriod as PortfolioChartPeriods : PortfolioChartPeriods.ALL;
        const id = portfolioId ? portfolioId : values.id;

        return this.chartApiService.getPortfolioChartData(id, period);
      }),
      tap((chartData: ChartDataWithPeriod) => {
          const {data, activePeriod} = chartData;
          const chartDataForComponent: ChartConvertData = ChartHelper.getChartData(data, activePeriod);
          const chartPeriods: ChartDataInterface[] = ChartHelper.getChartPeriods();

          this.store.dispatch(getPortfolioChartDataSuccess({chartData: chartDataForComponent}));
          this.store.dispatch(setPortfolioChartAvailablePeriodsSuccess({chartPeriods}));
        }
      ),
      map((chartData: ChartDataWithPeriod) => chartData),
      catchError(err => throwError(err))
    );
  }

  getStockChartData(ticker: string): Observable<StockChartDataComponent> {
    const activeChart = localStorage.getItem('chartPeriod');
    const period: StockChartPeriods = activeChart as StockChartPeriods ?? StockChartPeriods.WEEK;

    return this.chartApiService.getStockChartData(ticker, StockChartHelper.getTimestamp(period))
      .pipe(
        map((chartData) => {
          const chartDataComponent: StockChartDataComponent = StockChartHelper.getChartData(chartData, period);
          this.store.dispatch(getStockChartDataSuccess({stockChartData: chartDataComponent}));

          return chartDataComponent;
        }),
        catchError(err => throwError(err))
      );
  }

  saveChartPeriodToLocalStorage(period: StockChartPeriods): void {
    localStorage.setItem('chartPeriod', period);
  }

  getChartPeriodFromLocalStorage(): StockChartPeriods {
    const period = localStorage.getItem('chartPeriod');
    return period ? period as StockChartPeriods : StockChartPeriods.WEEK;
  }

  removeChartPeriodFromLocalStorage(): void {
    localStorage.removeItem('chartPeriod');
  }

}
