import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {untilDestroyed} from 'ngx-take-until-destroy';

import {AuthenticationService} from '@auth/services/authentication.service';
import {PopupWrapperComponent} from '@shared/components/popup-wrapper/popup-wrapper/popup-wrapper.component';
import {AlmostThereModel} from "@auth/shared/models/almost.there.model";
import {AlmostThereComponent} from "@auth/components/almost-there/almost-there.component";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  forgotPasswordForm: FormGroup;
  checkEmailErrors: string[] = [];
  @ViewChild(PopupWrapperComponent, {static: false})
  popupWrapperComponent: PopupWrapperComponent;
  @Output() openLogin$ = new EventEmitter();

  @ViewChild(AlmostThereComponent, {static: false})
  almostThereComponent: AlmostThereComponent;

  constructor(private fb: FormBuilder,
              private changeDetector: ChangeDetectorRef,
              private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    this._initForgotPasswordForm();
  }

  dismissModal() {
    this.forgotPasswordForm.reset();
  }

  openModal() {
    this.popupWrapperComponent.openCentered();
  }

  signIn() {
    this.close();
    this.openLogin$.emit();
  }

  close() {
    this.popupWrapperComponent.close();
  }

  ngOnDestroy(): void {
  }

  onSubmitHandler(event) {
    const {email} = this.forgotPasswordForm.value;
    this.forgotPasswordRequest(email);
  }

  private _initForgotPasswordForm() {
    this.forgotPasswordForm = this.fb.group({
      email: new FormControl('', {
        validators: [
          Validators.required,
          Validators.email,
          Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)
        ],
        updateOn: 'change'
      })
    });
    this.forgotPasswordForm.statusChanges.subscribe(data => this.checkEmailErrors = [])
  }

  private forgotPasswordRequest(email: string) {
    this.authService.forgotPasswordRequest(email)
      .pipe(untilDestroyed(this))
      .subscribe(({almostThere, errors}) => {
        if (errors.length) this.checkEmailErrors = errors.map(error => error?.message);

        if (almostThere) {
          this.close();
          this.almostThereComponent.openModal();
        }

        this.changeDetector.markForCheck()
      });
  }

}
