import {createAction, props} from '@ngrx/store';

import {Country} from '@shared/models/countries/country.interface';

enum CountriesActions {
  GetCountries = '[Countries-API] Get countries',
  GetCountriesSuccess = '[Countries-API] Get countries success',
  GetCountriesFailure = '[Countries-API] Get countries failure'
}

export const getCountries = createAction(
  CountriesActions.GetCountries
);

export const getCountriesSuccess = createAction(
  CountriesActions.GetCountriesSuccess,
  props<{countries: Country[]}>()
);

export const getCountriesFailure = createAction(
  CountriesActions.GetCountriesFailure,
  props<{err: any}>()
);
