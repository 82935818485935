import {Action, createReducer, on} from '@ngrx/store';

import {initialOrdersState, OrdersState} from '../../state/orders/orders.state';
import {
  cancelOrderBrokerageSuccess, cancelOrderBrokerageUpdateStatus,
  cancelOrderSuccess,
  destroyOrders, loadOrdersBrokerageSuccess,
  loadOrdersSuccess, resetOrdersData,
  updateOrdersCurrentPage
} from '../../actions/orders/orders.actions';
import {OrderStatus} from '@shared/enums/orders/oder.status.enum';
import {userLogout} from '@store/actions/user.actions';

const reducer = createReducer(
  initialOrdersState,
  on(loadOrdersSuccess, (state, {orders}) => {
    return {
      ...state,
      orders: state.orders ? [...state.orders, ...orders] : [...orders]
    };
  }),
  on(loadOrdersBrokerageSuccess, (state, {orders}) => {
    return {
      ...state,
      ordersBrokerage: state.ordersBrokerage ? [...state.ordersBrokerage, ...orders] : [...orders]
    };
  }),
  on(updateOrdersCurrentPage, (state, {current}) => {
    return {
      ...state,
      pages: {
        current
      }
    };
  }),
  on(cancelOrderSuccess, (state, {order}) => {
    return {
      ...state,
      orders: state.orders.map(oldOrder => {
        if (order.id === oldOrder.id) {
          return {
            ...order,
            status: OrderStatus.CANCELED
          };
        }

        return oldOrder;
      })
    };
  }),
  on(cancelOrderBrokerageSuccess, (state, {order}) => {
    return {
      ...state,
      ordersBrokerage: state.ordersBrokerage.map(oldHistory => {
        if (oldHistory.order.id === order.id) {
          return {
            ...oldHistory,
            order: {
              ...oldHistory.order,
              ...order
            }
          };
        }

        return oldHistory;
      })
    };
  }),
  on(cancelOrderBrokerageUpdateStatus, (state, {history}) => {
    return {
      ...state,
      ordersBrokerage: state.ordersBrokerage.map(oldHistory => {
        if (oldHistory.order.id === history.order.id) {
          return {
            ...oldHistory,
            order: {
              ...oldHistory.order,
              status: OrderStatus.CANCELED
            }
          };
        }

        return oldHistory;
      })
    };
  }),
  on(destroyOrders, (state) => {
    return {
      orders: null,
      ordersBrokerage: null,
      pages: {
        current: 0
      }
    };
  }),
  on(resetOrdersData, (state) => {
    return {
      ...initialOrdersState
    };
  }),
  on(userLogout, state => {
    return {
      ...initialOrdersState
    };
  })
);

export function ordersReducer(state: OrdersState, action: Action) {
  return reducer(state, action);
}
