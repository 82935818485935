import { Injectable } from "@angular/core";

import { Store } from "@ngrx/store";

import {BehaviorSubject, Observable, of} from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { saveTypeForRequestFindUserSuccess } from "@store/actions/motivation/motivation.actions";
import { AddUserForType } from "@shared/enums/search/add-user-for-type.enum";
import { TagSymbols } from "@shared/enums/tag/tag-symbols.enum";
import { SearchStock } from "@shared/models/search/search-stock.interface";
import { SearchApiService } from "@shared/services/search/api/search.api.service";
import {
  searchOfStocks,
  searchOfStocksFailure,
  searchOfStocksSuccess,
  searchOfUsers,
  searchOfUsersFailure,
  searchOfUsersSuccess,
} from "@store/actions/search/search.actions";
import { UserDetails } from "@shared/models/search/user-details.interface";
import { removeTagSymbolAction } from "@store/actions/tag/tag.actions";

@Injectable({ providedIn: "root" })
export class SearchService {
  private expandedStatus = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store,
    private searchApiService: SearchApiService
  ) {}

  findUsers(query = '', typeForRequest: AddUserForType, offset = 5, limit?: number): Observable<UserDetails[]> {
    const newQuery = query === TagSymbols.USER ? 'a' : query;

    this.store.dispatch(searchOfUsers());

    return this.searchApiService.searchUsers(newQuery, offset, limit).pipe(
      map((users) => {
        const limitDisplayUsers = users.slice(0, 5);

        this.store.dispatch(searchOfUsersSuccess({ users: limitDisplayUsers }));
        this.store.dispatch(saveTypeForRequestFindUserSuccess({ typeForRequest }));

        return users;
      }),
      catchError((err) => {
        this.store.dispatch(searchOfUsersFailure());
        this.store.dispatch(removeTagSymbolAction());

        return of(err);
      })
    );
  }

  searchStock(query = '', offset = 5, limit?: number): Observable<SearchStock[]> {
    const newQuery = query === TagSymbols.STOCK ? 'A' : query;

    this.store.dispatch(searchOfStocks());

    return this.searchApiService.searchStock(newQuery, offset, limit).pipe(
      map((stocks) => {
        const limitDisplayStocks = stocks.slice(0, 5);

        this.store.dispatch(searchOfStocksSuccess({ stocks: limitDisplayStocks }));

        return stocks;
      }),
      catchError((err) => {
        this.store.dispatch(searchOfStocksFailure());
        this.store.dispatch(removeTagSymbolAction());

        return of(err);
      })
    );
  }

  openSearch() {
    this.expandedStatus.next(true);
  }

  getExpandedStatus(): Observable<boolean> {
    return this.expandedStatus.asObservable();
  }
}
