import {NgModule} from '@angular/core';

import {EmptyValueDirective} from '@shared/directives/empty-value.directive';
import {ContenteditableDirective} from '@shared/directives/contentedit.directive';
import {DebounceClickDirective} from '@shared/directives/debounce-click.directive';
import {OnlyNumberDirective} from '@shared/directives/only-number.directive';
import {HighlightDirective} from '@shared/directives/outside.directive';
import {RestrictInputDirective} from '@shared/directives/restrict.directive';
import {TextCopyDirective} from '@shared/directives/copy-text.directive';

@NgModule({
  declarations: [
    EmptyValueDirective,
    ContenteditableDirective,
    DebounceClickDirective,
    OnlyNumberDirective,
    HighlightDirective,
    RestrictInputDirective,
    TextCopyDirective
  ],
  exports: [
    EmptyValueDirective,
    ContenteditableDirective,
    DebounceClickDirective,
    OnlyNumberDirective,
    HighlightDirective,
    RestrictInputDirective,
    TextCopyDirective
  ]
})
export class DirectivesModule { }
