import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-drop-menu',
  templateUrl: './drop-menu.component.html',
  styleUrls: ['./drop-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropMenuComponent {
  @Input() dropDownId: string;
  @Input() isShowPointers = true;
  @Input() isShowText = false;
  @Input() text: string;
  @Input() stylesForText: JSON;
}
