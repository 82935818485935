import {createFeatureSelector, createSelector} from '@ngrx/store';

import {MotivationState} from '../../state/motivation/motivation.state';

const featureSelector = createFeatureSelector<MotivationState>('motivationPopup');

export const findUsersSelector = createSelector(
  featureSelector,
  (state) => state.findUsers
);

export const typeForRequestFindUserSelector = createSelector(
  featureSelector,
  (state) => state.typeForRequestOnFindUser
);

export const followingUsersSelector = createSelector(
  featureSelector,
  (state) => state.followingUsers
);

export const findNewsSelector = createSelector(
  featureSelector,
  (state) => state.findNews
);

export const addNewsSelector = createSelector(
  featureSelector,
  (state) => state.news
);

export const offsetSelector = createSelector(
  featureSelector,
  (state) => state.offset
);

export const statusShowSearchNewsSelector = createSelector(
  featureSelector,
  (state) => state.isShow
);

export const customLinksSelector = createSelector(
  featureSelector,
  (state) => state.links
);

export const isShowMotivationPopupSelector = createSelector(
  featureSelector,
  (state) => state.isShowMotivation
);

