import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'appReplaceSongTitle'})
export class ReplaceSongTitlePipe implements PipeTransform {

  transform(value = '', ...args): string {
    // @ts-ignore
    return value.replaceAll('_', ' ');
  }
}
