import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-wrapper',
  templateUrl: './popup-wrapper.component.html',
  styleUrls: ['./popup-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PopupWrapperComponent {
  @ViewChild('popup', {static: false})
  popup: ElementRef;

  @Input() bodyName: string;
  @Input() footerName: string;
  @Input() addClass: string;

  @Output() dismiss$ = new EventEmitter();

  constructor(private modalService: NgbModal) {
  }

  openCentered() {
    const modalRef = this.modalService.open(this.popup, {
      centered: true,
      backdropClass: 'popup-background',
      windowClass: this.addClass
    });

    modalRef.result.then(close => {
      // console.log('close => ', close);
      this.dismiss$.emit();
    }, dismiss => {
      // console.log('dismiss => ', dismiss);
      this.dismiss$.emit(dismiss);
    });
  }

  dismiss() {
    this.modalService.dismissAll('dismiss');
  }

  close() {
    this.modalService.dismissAll('close');
  }

}
