import {FeedEffect} from '@store/effects/feed/feed.effect';
import {ProfileEffects} from '@store/effects/profile/profile.effects';
import {ChartEffects} from '@store/effects/chart/chart.effects';
import {WatchlistEffects} from '@store/effects/watchlist/watchlist.effects';
import {TrendingStocksEffect} from '@store/effects/trending-stocks/trending-stocks.effect';
import {TrendingInvestorsEffect} from '@store/effects/trending-investors/tranding-investors.effect';
import {GainersEffect} from '@store/effects/gainers/gainers.effect';
import {LosersEffect} from '@store/effects/losers/losers.effect';
import {ListEffect} from '@store/effects/list/list.effect';

export const appEffects: Array<any> = [
  FeedEffect,
  ProfileEffects,
  ChartEffects,
  WatchlistEffects,
  TrendingStocksEffect,
  TrendingInvestorsEffect,
  GainersEffect,
  LosersEffect,
  ListEffect
];
