import {Action, createReducer, on} from '@ngrx/store';

import {CompanyDetailsState, initialCompanyDetailsState} from '../../state/company-details/company-details.state';
import {loadCompanyDetailsFailure, loadCompanyDetailsSuccess} from '../../actions/company-details/company-details.actions';
import {userLogout} from '@store/actions/user.actions';

const reducer = createReducer(
  initialCompanyDetailsState,
  on(loadCompanyDetailsSuccess, (state, {companyDetails}) => {
    return {
      ...state,
      details: {
        ...companyDetails
      },
      error: null
    };
  }),
  on(loadCompanyDetailsFailure, (state, {error}) => {
    return {
      ...state,
      details: null,
      error: {
        ...error
      }
    };
  }),
  on(userLogout, state => {
    return {
      ...initialCompanyDetailsState
    };
  })
);

export function companyDetailsReducer(state: CompanyDetailsState, action: Action) {
  return reducer(state, action);
}
