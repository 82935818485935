<ng-container *ngIf="like$ | async"></ng-container>
<ng-container *ngIf="loadNews$ | async"></ng-container>

<ng-container *ngIf="news$ | async as newsWidget">
  <ng-container *ngIf="newsWidget.news.length; else noData">
    <div class="card parent mb-3">
      <h5 class="card-title">Portfolio Related News</h5>
    </div>

    <ng-container *ngFor="let news of newsWidget.news | slice : 0 : lastElement; index as i; trackBy: trackByFn">
      <div class="card parent mb-3" [ngClass]="{ 'first': i === 0}" *ngIf="news.type === 'news'">
        <div class="card-body">
          <div class="news-title">
            <picture class="newspaper-wrapper">
              <img [src]="getAvatarUrl(true, news.author.id)"
                   class="newspaper-logo"
                   alt="newspaper"
                   (error)="error($event)"
              >
            </picture>

            <div class="text-wrapper">
              <div class="title">{{news.author.fullname}}</div>
              <div class="sub-title font-weight-normal">{{news.created | timeAgo}}</div>
            </div>
          </div>

          <div class="data-wrapper" *ngIf="news.news">
            <picture class="position-logo-wrapper"
                     *ngIf="news.news.link.imageParams?.url"
                     (click)="openNews(news.news.link.url)"
            >
              <img [src]="news.news.link.imageParams?.url"
                   class="position-logo"
                   alt="{{news.news.link.title}}"
                   (error)="errorIcon($event)"
              >
            </picture>

            <div class="info-wrapper">
              <div class="ticker-wrapper d-flex">
                <div class="ticker pr-1">{{news.news.stock.ticker | appReplaceSongTitle}}</div>
                <div class="position-percent green" [ngClass]="{'red': news.news.stock.dailyValueChange < 0}">
                  {{news.news.stock.dailyValueChange > 0 ? '+' : '-'}}{{news.news.stock.dailyPercentChange | number | negativeNumber}}%
                </div>
              </div>

              <div class="link-title" (click)="openNews(news.news.link.url)">
                {{news.news.link.title}}
              </div>

              <div class="link-description" (click)="openNews(news.news.link.url)">
                {{news.news.link.description}}
              </div>

              <div class="footer d-flex justify-content-between">
                <div class="like-wrapper d-flex" (click)="like(news, i)">
                  <img [src]="news.isLikedByFetcher ? 'assets/icons/related-news-widget/like_active.svg' : 'assets/icons/related-news-widget/like.svg'"
                       class="like"
                       alt="like"
                       (error)="error($event)"
                  >

                  <div class="likes-count">{{news.likesCount > 0 ? news.likesCount : 'Like'}}</div>
                </div>

                <div class="comments-wrapper d-flex">
                  <img [src]="'assets/icons/related-news-widget/comment.svg'"
                       class="comment"
                       alt="comment"
                       (error)="error($event)"
                  >

                  <div class="count-comments">{{news.commentCount > 0 ? news.commentCount : 'Comment'}}</div>
                </div>

                <div class="forward-wrapper d-flex">
                  <img [src]="'assets/icons/related-news-widget/share.svg'"
                       class="share"
                       alt="share"
                       (error)="error($event)"
                  >

                  <div class="count-comments">Share</div>

                </div>
              </div>
            </div>

            <div class="show-more-wrapper" *ngIf="i === lastElement - 1">
              <app-button (submit$)="showMore()"
                          [isFullWidth]=true
                          [styles]="{
                          'font-weight': 'bold',
                          'font-size': '14px',
                          'line-height': '17px',
                          'letter-spacing': '0'
                        }"
                          [isDisabled]="true"
              >
                Show More
              </app-button>
            </div>

          </div>
        </div>
      </div>
    </ng-container>

  </ng-container>
</ng-container>

<ng-template #noData>
  <div class="card parent mb-3">
    <div class="card-body no-data">

      <h5 class="card-title no-data">Portfolio Related News</h5>

      <div class="content-wrapper no-data">
        <picture>
          <img [src]="getIconUrl('/News_icon@1x.svg')" alt="no-news"/>
        </picture>

        <div class="text pt-3 pb-0 f-s-16 l-h-21 f-c-333">
          All News related to your Positions
        </div>

        <div class="text text-center pt-0 f-s-16 l-h-21 f-c-333">and Watchlist will show up here</div>

      </div>

    </div>
  </div>
</ng-template>
