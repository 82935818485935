import {UserDetails} from '@shared/models/search/user-details.interface';
import {StockNews} from '@shared/models/news/stock-news.interface';
import {AddUserForType} from '@shared/enums/search/add-user-for-type.enum';
import {CustomUrlWithUrl} from '../../../modules/symbol/components/order-dialog/shared/interfaces/custom-url-with-url.interface';

export interface MotivationState {
  followingUsers: UserDetails[];
  news: StockNews[];
  findNews: StockNews[];
  findUsers: UserDetails[];
  typeForRequestOnFindUser: AddUserForType;
  offset: number;
  isShow: boolean;
  links: CustomUrlWithUrl[];
  isShowMotivation: boolean;
}

export const initialMotivationState: MotivationState = {
  followingUsers: null,
  news: null,
  findUsers: null,
  findNews: null,
  offset: 0,
  isShow: false,
  links: null,
  isShowMotivation: null,
  typeForRequestOnFindUser: null
};
