// @ts-ignore

import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';

import {Observable} from 'rxjs';
import {exhaustMap, map, switchMap} from 'rxjs/operators';

import {Store} from '@ngrx/store';

import {getStockChartDataSuccess, updateSelectStockChartPeriodSuccess} from '@store/actions/chart/chart.actions';
import {ChartApiService} from '@shared/services/chart/api/chart.api.service';
import {StockChartPeriods} from '@shared/components/chart/shared/enums/stock-chart-periods.enum';
import {StockChartHelper} from '@shared/components/chart/shared/helpers/stock.chart.helper';
import {StockChartDataComponent} from '@shared/models/chart/stock/stock-chart-data-component.interface';

@Injectable()
export class ChartEffects {

  constructor(private actions$: Actions,
              private chartApiService: ChartApiService,
              private store: Store) {
  }

  chart$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSelectStockChartPeriodSuccess),
      switchMap((action) => {
          const period: StockChartPeriods = action.activePeriod ? action.activePeriod as StockChartPeriods : StockChartPeriods.MAX;

          return this.chartApiService.getStockChartData(action.ticker, StockChartHelper.getTimestamp(period))
            .pipe(
              map((chartData) => {
                const chartDataComponent: StockChartDataComponent = StockChartHelper.getChartData(chartData, period);

                return getStockChartDataSuccess({stockChartData: chartDataComponent});
              })
            )
        }
      )
    )
  );
}
