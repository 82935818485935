import {Group} from '@shared/models/groups/group.interface';
import {Page} from '@shared/interfaces/page/page.interface';

export interface GroupsState {
  yourGroups: Group[] | null;
  discoverGroups: Group[] | null;
  featuredGroups: Group[] | null;
  yourGroupsPage: Page;
  discoverGroupsPage: Page;
}

export const initialGroupsState: GroupsState = {
  yourGroups: null,
  discoverGroups: null,
  featuredGroups: null,
  yourGroupsPage: {
    pages: {
      current: 0,
      prev: null
    }
  },
  discoverGroupsPage: {
    pages: {
      current: 0,
      prev: null
    }
  }
};
