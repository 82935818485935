import {ActionReducerMap} from '@ngrx/store';
import {routerReducer} from '@ngrx/router-store';

import {AppState} from '../state/app.state';
import {tickerPositionReducer} from './ticker-position/ticker-position.reducer';
import {userReducer} from './user.reducer';
import {portfolioReducer} from './portfolio/portfolio.reducer';
import {chartReducer} from './chart/chart.reducer';
import {ordersReducer} from './orders/orders.reducer';
import {watchlistReducer} from './watchlist/watchlist.reducer';
import {companyDetailsReducer} from './company-details/company-details.reducer';
import {orderDialogueReducer} from './order-dialogue/order-dialogue.reducer';
import {motivationReducer} from './morivation/motivation.reducer';
import {feedReducer} from '@store/reducers/feed/feed.reducer';
import {tagReducer} from '@store/reducers/tag/tag.reducer';
import {searchReducer} from '@store/reducers/search/search.reducer';
import {profileReducer} from '@store/reducers/profile/profile.reducer';
import {countriesReducer} from '@store/reducers/countries/countries.reducer';
import {groupsReducer} from '@store/reducers/groups/groups.reducer';

export const appReducers: ActionReducerMap<AppState, any> = {
  userStore: userReducer,
  router: routerReducer,
  portfolio: portfolioReducer,
  tickerPosition: tickerPositionReducer,
  chart: chartReducer,
  orders: ordersReducer,
  watchlist: watchlistReducer,
  companyDetails: companyDetailsReducer,
  orderDialogue: orderDialogueReducer,
  motivationPopup: motivationReducer,
  feed: feedReducer,
  tag: tagReducer,
  search: searchReducer,
  profile: profileReducer,
  countries: countriesReducer,
  groups: groupsReducer
};
