import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {untilDestroyed} from 'ngx-take-until-destroy';

import {AuthenticationService} from '@auth/services/authentication.service';
import {SignUpBackendErrorItem} from '@auth/shared/models/sign-up.model';
import {PopupWrapperComponent} from '@shared/components/popup-wrapper/popup-wrapper/popup-wrapper.component';
import {AlmostThereComponent} from '@auth/components/almost-there/almost-there.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationComponent implements OnInit, OnDestroy {
  signUpForm: FormGroup;
  isShowPassword = false;
  @ViewChild(PopupWrapperComponent, {static: false})
  popupWrapperComponent: PopupWrapperComponent;
  @ViewChild(AlmostThereComponent, {static: false})
  almostThereComponent: AlmostThereComponent;
  @Output() openSignIn$ = new EventEmitter();

  constructor(private fb: FormBuilder,
              private authService: AuthenticationService,
              private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    this._initSignUpForm();
  }

  ngOnDestroy(): void {
  }

  dismissModal() {
    this.signUpForm.reset();
  }

  openModal() {
    this.popupWrapperComponent.openCentered();
  }

  close() {
    this.popupWrapperComponent.close();
  }

  openSignIn() {
    this.close();
    this.openSignIn$.emit();
  }

  private _initSignUpForm() {
    this.signUpForm = this.fb.group({
      firstName: new FormControl('', {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      lastName: new FormControl('', {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      userName: new FormControl('', {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      email: new FormControl('', {
        validators: [
          Validators.required,
          Validators.email,
          Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)
        ],
        updateOn: 'change'
      }),
      password: new FormControl('', {
        validators: [Validators.required, Validators.minLength(6)],
        updateOn: 'change'
      })
    });
  }

  togglePasswordVisibility() {
    this.isShowPassword = !this.isShowPassword;
  }

  onSubmitHandler() {
    const {
      firstName,
      lastName,
      userName,
      email,
      password
    } = this.signUpForm.value;

    const credentials = {
      fos_user_registration_form: {
        username: userName,
        plainPassword: {
          first: password,
          second: password
        },
        email,
        name: firstName,
        surname: lastName
      },
      referralCode: ''
    };

    this.authService.signUp(credentials)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
          // this.openAlmostThereModal();
          this.close();
          this.almostThereComponent.openModal();
          // go to nav almost-there
        },
        (error: any) => {
          if (error && error.hasOwnProperty('error') && error.error?.hasOwnProperty('errors')) {
            error.error.errors.forEach(item => {
              this.setErrors(item);
            });
          }
        }
      );
  }

  setErrors(error: SignUpBackendErrorItem) {
    const {message} = error;
    const userNameControl = this.signUpForm.get('userName');
    const emailControl = this.signUpForm.get('email');

    switch (message) {
      case 'This username is already used':
        userNameControl.setErrors({
          backendError: {
            message: 'This username is already used'
          }
        });

        break;

      case 'This email is already used':
        emailControl.setErrors({
          backendError: {
            message: 'This email is already used'
          }
        });

        break;

      default:
        userNameControl.setErrors({
          backendError: {
            message: 'Invalid credentials'
          }
        });

        emailControl.setErrors({
          backendError: {
            message: 'Invalid credentials'
          }
        });

        break;
    }

    this.changeDetector.detectChanges();

  }

}
